import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table, Container, Label, FormGroup, Input, Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Tabs from 'react-responsive-tabs';
import {
    getCenterListSuggestionAPI, getTreatmentDoneStatsAPI, getPatientStatusStatsAPI, getPatientRegistrationStatsAPI,
    getTreatmentDoneStatsDetailAPI, getPatientStatusStatsDetailAPI, getPatientRegistrationStatsDetailAPI,
    getDiagnosisReportAPI

} from './../../api/network/Api';

import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import Autosuggest from 'react-autosuggest';
import VisitProgressReport from './VisitProgressReport';
const SuperAdminReporting = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientList, setPatientList] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCenter, setSelectedCenter] = useState(userUtil?.userData?.center_id);
    const [startDateError, setStartDateError] = useState("");
    const [endDateError, setEndDateError] = useState("");
    const [timeFrame, setTimeFrame] = useState("Today");
    const [patientStatus, setPatientStatus] = useState("Active");
    const [patientStatusAfter, setPatientStatusAfter] = useState("Active");
    const [treatmentDoneStats, setTreatmentDoneStats] = useState(null);
    const [patientStatusStats, setPatientStatusStats] = useState(null);
    const [patientRegistrationStats, setPatientRegistrationStats] = useState(null);
    const [detailData, setDetailData] = useState(null);
    const [detailTitle, setDetailTitle] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [diagnosisReportData, setDiagnosisReportData] = useState([]);
    const tabsContent = [
        {
            title: 'Patient Status',
            content: 'patientStatus'
        },
        {
            title: 'Diagnosis',
            content: 'Diagnosis'
        },
        {
            title: 'Visit Progress Report',
            content: 'visitProgressReport'
        },
    ];
    const spinalDiagnososBind = [
        { SPONDYLOSIS: { key: 'SPONDYLOSIS', value: 1, name: 'Spondylosis' } },
        { SPONDYLOSIS: { key: 'DDD', value: 2, name: 'DDD' } },
        { DISC_BULGE: { key: 'DISC_BULGE', value: 3, name: 'Disc Bulge' } },
        { DISC_PROTRUSION: { key: 'DISC_PROTRUSION', value: 4, name: 'Disc Protrusion' } },
        { SPONDYLOLISTHESIS: { key: 'SPONDYLOLISTHESIS', value: 5, name: 'Spondylolisthesis' } },
        { SCOLIOSIS: { key: 'SCOLIOSIS', value: 6, name: 'Scoliosis' } },
        { DEGENERATIVE_SCOLIOSIS: { key: 'DEGENERATIVE_SCOLIOSIS', value: 7, name: 'Degenerative Scoliosis' } },
        { OSTEOPOROSIS: { key: 'OSTEOPOROSIS', value: 8, name: 'Osteoporosis' } },
        { OCHRONOSIS: { key: 'OCHRONOSIS', value: 9, name: 'Ochronosis' } },
        { COCCYDYNIA: { key: 'COCCYDYNIA', value: 10, name: 'Coccydynia' } },
        { ANKYLOSIS: { key: 'ANKYLOSIS', value: 11, name: 'Ankylosis' } },
        { SPONDYLITIS: { key: 'SPONDYLITIS', value: 12, name: 'Spondylitis' } },
        { SPINAL_STENOSIS: { key: 'SPINAL_STENOSIS', value: 13, name: 'Spinal Stenosis' } },
        { RADICULITIS: { key: 'RADICULITIS', value: 14, name: 'Radiculitis' } },
        { RADICULOPATHY: { key: 'RADICULOPATHY', value: 15, name: 'Radiculopathy' } },
        { MYELOPATHY: { key: 'MYELOPATHY', value: 16, name: 'Myelopathy' } },
        { SPINOCEREBELLAR_DISEASE: { key: 'SPINOCEREBELLAR_DISEASE', value: 17, name: 'Spinocerebellar Disease' } },
        { MOTOR_NEURON_DISEASE: { key: 'MOTOR_NEURON_DISEASE', value: 18, name: 'Motor Neuron Disease' } },
        { SYRINGOMYELIA: { key: 'SYRINGOMYELIA', value: 19, name: 'Syringomyelia' } },
        { CERVICAL_DYSTONIA: { key: 'CERVICAL_DYSTONIA', value: 20, name: 'Cervical Dystonia' } },
        { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 21, name: 'Not Applicable' } },
        { OTHER: { key: 'OTHER', value: 22, name: 'Other' } },
    ]
    const rheumatoid_arthritis = [
        { JUVINILE: { key: 'JUVINILE', value: 1, name: 'Juvinile' } },
        { PAUCI_ARTICULAR: { key: 'PAUCI_ARTICULAR', value: 2, name: 'Pauci Articular' } },
        { POLY_ARTICUALAR: { key: 'POLY_ARTICUALAR', value: 3, name: 'Poly Articular' } },
        { RA_POSITIVE: { key: 'RA_POSITIVE', value: 4, name: '*RA +ve' } },
        { ANTI_CPP_POSITIVE: { key: 'ANTI_CPP_POSITIVE', value: 5, name: 'Anti-CCP +ve' } },
        { RA_NEGATIVE: { key: 'RA_NEGATIVE', value: 6, name: '*RA-ve' } },
        { ANTI_CPP_NEGATIVE: { key: 'ANTI_CPP_NEGATIVE', value: 7, name: 'Anti-CCP -ve' } },
        { NOT_APPLICABLE: { key: 'NOT_APPLICABLE', value: 8, name: 'Not Applicable' } },
    ]
    useEffect(() => {
        const elements = document.querySelectorAll('.react-autosuggest__suggestions-container');
        if (suggestions.length == 0) {
            elements.forEach(element => {
                element.style.display = 'none';
            });
        } else {
            elements.forEach(element => {
                element.style.display = 'block';
            });
        }
    }, [suggestions]);


    function createQueryString(filterType, centerId, startDate, endDate) {
        let queryString = '';

        if (filterType !== null) {
            queryString += `filter_type=${encodeURIComponent(filterType)}&`;
        }

        if (selectedCenter !== null && selectedCenter !== "") {
            queryString += `center_id=${encodeURIComponent(centerId)}&`;
        }

        if (startDate !== null) {
            queryString += `from_date=${encodeURIComponent(startDate)}&`;
        }

        if (endDate !== null) {
            queryString += `to_date=${encodeURIComponent(endDate)}&`;
        }

        // Remove the trailing '&' if the query string is not empty
        if (queryString.length > 0) {
            queryString = queryString.slice(0, -1);
        }

        return queryString;
    }
    const handleSearch = (e) => {
        setDetailData(null)
        const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
        if (selectedTab == 0) {
            getTreatmentDoneStats(queryString)
            getPatientStatusStats(queryString)
            getPatientRegistrationStats(queryString)
        } else {
            getDiagnosisReport(queryString)
        }
    }
    const getTreatmentDoneStats = async (queryString) => {
        setIsLoader(true)
        const response = await getTreatmentDoneStatsAPI(queryString, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setTreatmentDoneStats(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientStatusStats = async (queryString) => {
        setIsLoader(true)
        const response = await getPatientStatusStatsAPI(queryString + `&status=${patientStatus}`, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientStatusStats(response?.data)
            setPatientStatusAfter(patientStatus)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientRegistrationStats = async (queryString) => {
        setIsLoader(true)
        const response = await getPatientRegistrationStatsAPI(queryString, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientRegistrationStats(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getTreatmentDoneStatsDetail = async (queryString) => {
        setDetailTitle('Total Treatment Done')
        setIsLoader(true)
        const response = await getTreatmentDoneStatsDetailAPI(queryString + '&list=true&offset=0&limit=5000', cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDetailData(response?.data?.count?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientStatusStatsDetail = async (queryString) => {
        setDetailTitle(`Patient Status (${patientStatus})`)
        setIsLoader(true)
        const response = await getPatientStatusStatsDetailAPI(queryString + `&list=true&status=${patientStatus}&offset=0&limit=5000`, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDetailData(response?.data?.count?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientRegistrationStatsDetail = async (queryString) => {
        setIsLoader(true)
        setDetailTitle(`Total Patient Registered`)
        const response = await getPatientRegistrationStatsDetailAPI(queryString + '&list=true&offset=0&limit=5000', cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDetailData(response?.data?.count?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getDiagnosisReport = async (queryString) => {
        setIsLoader(true)
        const response = await getDiagnosisReportAPI(queryString + '&offset=0&limit=5000', cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDiagnosisReportData(response?.data?.report?.rows)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = (key) => {
        if (key == 'treatmentDoneStats') {
            const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
            getTreatmentDoneStatsDetail(queryString)
        }
        if (key == 'patientStatusStats') {
            const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
            getPatientStatusStatsDetail(queryString)
        }
        if (key == 'patientRegistrationStats') {
            const queryString = createQueryString(timeFrame, selectedCenter, startDate, endDate);
            getPatientRegistrationStatsDetail(queryString)
        }
    }
    const handleClickTab = (e) => {
        setSelectedTab(e)
        setTimeFrame('Today')
        setStartDate(null)
        setEndDate(null)
        setPatientStatus("Active");
        // if (e == 0) {
        //     getCenterList(0, 10)
        // } else {
        //     getArchivedCenterList(0, 10)
        // }
    }
    const getTabs = () => {
        return tabsContent.map((tab, index) => ({
            title: tab.title,
            key: index,
        }));
    }
    const getTotalCdiScore = (item) => {
        if (item) {
            return calculateSubScore('c2_c3', item) + calculateSubScore('c3_c4', item) + calculateSubScore('c4_c5', item) + calculateSubScore('c5_c6', item) + calculateSubScore('c6_c7', item)
        } else {
            return '0';
        }
    }
    function calculateSubScore(level, data) {
        console.log(data, 'data')
        console.log(data[`${level}_olisthesis_lateral`], 'level')
        const lateral = Number(data[`${level}_olisthesis_lateral`]);
        const flexion = Number(data[`${level}_olisthesis_flexion`]);
        const extension = Number(data[`${level}_olisthesis_extension`]);
        const anterior = Number(data[`${level}_olisthesis_anterior`]);
        const posterior = Number(data[`${level}_olisthesis_posterior`]);
        const discSpaceNarrowing = Number(data[`${level}_disc_space_narrowing`]);

        const maxFlexionExtension = Math.max(flexion, extension);
        const maxAnteriorPosterior = Math.max(anterior, posterior);

        return lateral + maxFlexionExtension + maxAnteriorPosterior + discSpaceNarrowing;
    }
    const getNameOfEcdi = (nameId) => {
        if (nameId == '0') {
            return 'Normal'
        }
        if (nameId == '1') {
            return 'Instability'
        }
        if (nameId == '2') {
            return 'Mild'
        }
        if (nameId == '3') {
            return 'Moderate'
        }
        if (nameId == '4') {
            return 'Severe'
        }
        if (nameId == '5') {
            return 'Very Severe'
        }
    }
    const getDeformityGradeOfEcdi = (nameId) => {
        if (nameId == '0') {
            return '0 - Normal'
        }
        if (nameId == '1') {
            return 'I - Olisthesis'
        }
        if (nameId == '2') {
            return 'I - A (Dynamic)'
        }
        if (nameId == '3') {
            return 'I - B (Dynamic)'
        }
        if (nameId == '4') {
            return 'I - C (Dynamic)'
        }
        if (nameId == '5') {
            return 'II - A (Static)'
        }
        if (nameId == '6') {
            return 'II - B (Static)'
        }
        if (nameId == '7') {
            return 'II - C (Static)'
        }
        if (nameId == '8') {
            return 'III - Rigid'
        }
    }

    function convertToCSV(table) {
        var csv = [];

        // Loop through each row in the table
        for (var i = 0; i < table.rows.length; i++) {
            var row = [];
            // Loop through each cell in the row
            for (var j = 0; j < table.rows[i].cells.length; j++) {
                // Get the text content of the cell
                var cellText = table.rows[i].cells[j].textContent;
                // If the cell text contains a comma, enclose it in double quotes
                if (cellText.includes(',')) {
                    cellText = '"' + cellText + '"';
                }
                // Add the cell text to the row array
                row.push(cellText);
            }
            // Join the row array into a CSV string and push it to the CSV array
            csv.push(row.join(','));
        }

        // Join the CSV array into a single CSV string and return it
        return csv.join('\n');
    }

    // Function to download CSV
    function downloadCSV(csv, filename) {
        var csvFile;
        var downloadLink;

        // CSV file
        csvFile = new Blob([csv], { type: "text/csv" });

        // Download link
        downloadLink = document.createElement("a");

        // File name
        downloadLink.download = filename;

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = "none";

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }

    const handleDownload = () => {
        var table = document.getElementById("data-table");
        var csv = convertToCSV(table);
        var filename = "table-data.csv";
        downloadCSV(csv, filename);
    }
    const getSpinalDiagnosisName = (data) => {
        const filteredData = spinalDiagnososBind.filter(item => data.includes(Object.values(item)[0].value));
        const names = filteredData?.map(item => Object.values(item)[0].name);
        return names.join(', ');
    }
    const getRheumatoidArthritisName = (data) => {
        const filteredData = rheumatoid_arthritis.filter(item => data.includes(Object.values(item)[0].value));
        const names = filteredData?.map(item => Object.values(item)[0].name);
        return names.join(', ');
    }
    return (
        <div>
            {isLoader && (<Loader />)}

            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Reporting" }
            ]} />
            <Tabs tabsWrapperClass="body-tabs body-tabs-layout" onChange={(e) => { handleClickTab(e) }} transform={false} showInkBar={true} items={getTabs()} />
            {selectedTab != 2 && (
                <Card className="main-card mb-3">
                    <CardBody>

                        <CardTitle className="flex-space-between">

                            {selectedTab == 0 ? "Patient Status Wise Report" : selectedTab == 1 ? "Diagnosis Wise Report" : "Visit Progress Report"}
                        </CardTitle>
                        <div>
                            <Row className="treatment-liest-container">
                                <Col md="3" >
                                    <FormGroup>
                                        <Label for="f_name">Select Time Frame</Label>
                                        <Input type="select" name="time_frame" id="time_frame" defaultValue={timeFrame} onChange={(e) => { setTimeFrame(e.target.value) }} >
                                            <option value="">Select</option>
                                            <option value="Today" selected={timeFrame == "Today"}>Today</option>
                                            <option value="Yesterday" selected={timeFrame == "Yesterday"}>Yesterday</option>
                                            <option value="Last_7_days" selected={timeFrame == "Last 7 days"}>Last 7 days</option>
                                            <option value="Last_15_days" selected={timeFrame == "Last 15 days"}>Last 15 days</option>
                                            <option value="Last_month" selected={timeFrame == "Last Month"}>Last Month</option>
                                            <option value="custom_date" selected={timeFrame == "Custom Date"}>Custom Date</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {timeFrame == "custom_date" && (
                                    <>
                                        <Col md="3" >
                                            <FormGroup>
                                                <Label for="f_name">Start Date</Label>
                                                <Input type="date" name="s_date" id="s_date" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                                                <span style={{ color: 'red' }}>{startDateError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col md="3" >
                                            <FormGroup>
                                                <Label for="f_name">End Date</Label>
                                                <Input type="date" name="e_date" id="e_date" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                                                <span style={{ color: 'red' }}>{endDateError}</span>
                                            </FormGroup>
                                        </Col>
                                    </>
                                )}
                                {selectedTab == 0 && (
                                    <Col md="3">
                                        <FormGroup>
                                            <Label for="f_name">Select Patient Status</Label>
                                            <Input type="select" name="patient_status" id="patient_status" defaultValue={patientStatus} onChange={(e) => { setPatientStatus(e.target.value) }} >

                                                <option value="Active" selected={patientStatus == "Active"}>Active</option>
                                                <option value="Completed" selected={patientStatus == "Completed"}>Completed</option>
                                                <option value="LAMA" selected={patientStatus == "LAMA"}>LAMA</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                )}
                                <Col md="3" style={{ display: 'flex', alignItems: 'center', marginTop: '18px' }}>
                                    <button className="btn btn-primary" onClick={(e) => { handleSearch(e) }}>Search</button>
                                </Col>
                            </Row>
                        </div>
                        {selectedTab == 0 && (
                            <>
                                {treatmentDoneStats && (
                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                        <Card className="main-card mb-3">
                                            <CardBody>
                                                <Row>
                                                    <Col md="4">
                                                        <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border"
                                                            style={{ cursor: 'pointer' }} onClick={(e) => { handleClick('treatmentDoneStats') }}
                                                        >
                                                            <div className="icon-wrapper rounded-circle">
                                                                <div className="icon-wrapper-bg bg-white opacity-10" />
                                                                <i className="lnr-cog icon-gradient bg-arielle-smile" />
                                                            </div>
                                                            <div className="widget-subheading" style={{ marginTop: '5px' }}>
                                                                Total Treatment Done
                                                            </div>
                                                            <div className="widget-description text-white">
                                                                {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                                                                <span className="ps-1 fs-32">{treatmentDoneStats?.count}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border"
                                                            style={{ cursor: 'pointer' }} onClick={(e) => { handleClick('patientStatusStats') }}
                                                        >
                                                            <div className="icon-wrapper rounded-circle">
                                                                <div className="icon-wrapper-bg bg-white opacity-10" />
                                                                <i className="lnr-cog icon-gradient bg-arielle-smile" />
                                                            </div>
                                                            <div className="widget-subheading" style={{ marginTop: '5px' }}>
                                                                Patient Status <strong>({patientStatusAfter}) </strong>
                                                            </div>
                                                            <div className="widget-description text-white">
                                                                {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                                                                <span className="ps-1 fs-32">{patientStatusStats?.count}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="4">
                                                        <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border"
                                                            style={{ cursor: 'pointer' }} onClick={(e) => { handleClick('patientRegistrationStats') }}
                                                        >
                                                            <div className="icon-wrapper rounded-circle">
                                                                <div className="icon-wrapper-bg bg-white opacity-10" />
                                                                <i className="lnr-cog icon-gradient bg-arielle-smile" />
                                                            </div>
                                                            <div className="widget-subheading" style={{ marginTop: '5px' }}>
                                                                Total Patient Registration
                                                            </div>
                                                            <div className="widget-description text-white">
                                                                {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                                                                <span className="ps-1 fs-32">{patientRegistrationStats?.count}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                )}
                            </>)}
                    </CardBody>
                </Card>
            )}
            {selectedTab == 1 && (
                <Card className="main-card mb-3">
                    <CardBody>
                        <CardTitle className="flex-space-between">
                            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                <Button color="primary" onClick={(e) => { handleDownload(e) }}>Download</Button>
                            </div>
                        </CardTitle>
                        <div class="table-responsive">
                            <Table striped className="mb-0" id="data-table">
                                <thead>
                                    <tr>
                                        <th>Patient Name</th>
                                        <th>MRN</th>
                                        <th>Status</th>
                                        <th>Spinal Diagnosis</th>
                                        <th>Apendicular Diagnosis</th>
                                        <th>BMI Score</th>
                                        <th>CDI Score</th>
                                        <th>ECDI Score</th>
                                        <th>Grade</th>
                                        <th>Duration</th>
                                        <th>Follow Up</th>
                                        <th>Sessions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {diagnosisReportData && diagnosisReportData?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item?.patient_info?.name}</td>
                                            <td>{item?.patient_info?.id}</td>
                                            <td>{item?.patient_info?.status ? item?.patient_info?.status : item?.status}</td>
                                            <td>{getSpinalDiagnosisName(item?.spinal)}</td>
                                            <td>{getRheumatoidArthritisName(item?.rheumatoid_arthritis)}</td>
                                            <td>{item?.patient_info?.bmi}</td>
                                            <td>{getTotalCdiScore(item?.cdi)}</td>
                                            <td>{getNameOfEcdi(item?.ecdi?.type)}</td>
                                            {/* <td>{getDeformityGradeOfEcdi(item?.ecdi?.deformity_grade)}</td> */}
                                            <td>{item?.grade?.grade == 1 ? 'Grade I' : item?.grade?.grade == 2 ? 'Grade II' : item?.grade?.grade == 3 ? 'Grade III' : 'Grade IV'}</td>
                                            <td>{item?.grade?.duration == 1 ? '6-12 Weeks' : item?.grade?.duration == 2 ? '12-16 Weeks' : item?.grade?.duration == 3 ? '16-24 Weeks' : 'More than 24 Weeks'}</td>
                                            <td>{item?.grade?.followUp == 1 ? 'As Per Need' : item?.grade?.followUp == 2 ? '24 Weeks' : item?.grade?.followUp == 3 ? '2 Years' : 'Life Time'}</td>
                                            <td>{item?.grade?.sessions == 1 ? '6-12' : item?.grade?.sessions == 2 ? '12-20' : item?.grade?.sessions == 3 ? '20-24' : '24-30'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            )}
            {selectedTab == 0 && (
                <>
                    {detailData && (
                        <Card className="main-card mb-3">
                            <CardBody>
                                <CardTitle className="flex-space-between">{detailTitle}</CardTitle>
                                <div class="table-responsive">
                                    <Table striped className="mb-0">
                                        <thead>
                                            <tr>
                                                <th>Patient Name</th>
                                                <th>Status</th>
                                                <th>Center Name</th>
                                                <th>Treatment By</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {detailData && detailData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item?.patient_info?.name ? item?.patient_info?.name : item?.name}</td>
                                                    <td>{item?.patient_info?.status ? item?.patient_info?.status : item?.status}</td>
                                                    <td>{item?.center_info?.name}</td>
                                                    <td>{item?.user_created?.name}</td>
                                                    <td>{item?.created_at.split('T')[0]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    )}
                </>)}
            {selectedTab == 2 && (
                <VisitProgressReport />
            )}
        </div>
    );
};

export default SuperAdminReporting;
