import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';

import {
    Row, Col,
    Button,
    CardHeader,
    Card,
    CardBody,
    Progress,
    TabContent,
    TabPane,
    CardTitle,
    Table,
    Tooltip
} from 'reactstrap';

import BreadCrumb from '../../../../api/helperComponents/BreadCrumb';
import { getDashboardReportSuperAdminAPI, getTodaysTreatmentSuperAdminAPI, getPatientListAPI, getCenterDetailByIDAPI } from './../../../../api/network/Api';
import PageTitle from '../../../../Layout/AppMain/PageTitle';

import Pagination from '../../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../api/helperComponents/Loader';
import { dateTimeFormate } from './../../../../api/helperComponents/dateTimeFormat';

const CenterAdmin = (props) => {
    console.log('CenterAdmin')
    const params = useParams()
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [dashboardTreatment, setDashboardTreatment] = useState([]);
    const [isLoader, setIsLoader] = useState(false);
    const [dashboardStats, setDashboardStats] = useState(null);
    const [centerInfo, setCenterInfo] = useState(null);
    const [centerId, setCenterId] = useState(params?.center_id);

    const [patientList, setPatientList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));

    const [tooltipOpenEdit, setTooltipOpenEdit] = useState(false);
    const [tooltipOpenMedical, setTooltipOpenMedical] = useState(false);
    const [tooltipOpenTreatment, setTooltipOpenTreatment] = useState(false);
    const [tooltipOpenDpep, setTooltipOpenDpep] = useState(false);
    const [tooltipOpenCDI, setTooltipOpenCDI] = useState(false);
    const [tooltipOpenECDI, setTooltipOpenECDI] = useState(false);
    const [tooltipOpenDiagnosis, setTooltipOpenDiagnosis] = useState(false);
    const [tooltipOpenTendinopathies, setTooltipOpenTendinopathies] = useState(false);
    const [tooltipOpenCategorization, setTooltipOpenCategorization] = useState(false);
    const [tooltipOpenSuggestedTreatment, setTooltipOpenSuggestedTreatment] = useState(false);
    const [tooltipIndex, setTooltipIndex] = useState(null);
    const [centerName, setCenterName] = useState(localStorage.getItem('centerName'));


    useEffect(() => {
        getDashboardReport()
        getTodaysTreatment()
        getCenterInfo()
    }, []);

    const toggleEdit = (e, index) => {
        e.preventDefault();
        setTooltipIndex(index)
        setTooltipOpenEdit(!tooltipOpenEdit);
    };

    const toggleMedical = (e) => {
        e.preventDefault();
        setTooltipOpenMedical(!tooltipOpenMedical);
    };

    const toggleTreatment = (e) => {
        e.preventDefault();
        setTooltipOpenTreatment(!tooltipOpenTreatment);
    };

    const toggleDpep = (e) => {
        e.preventDefault();
        setTooltipOpenDpep(!tooltipOpenDpep);
    };
    const toggleCDI = (e) => {
        e.preventDefault();
        setTooltipOpenCDI(!tooltipOpenCDI);
    };
    const toggleECDI = (e) => {
        e.preventDefault();
        setTooltipOpenECDI(!tooltipOpenECDI);
    };
    const toggleDiagnosis = (e) => {
        e.preventDefault();
        setTooltipOpenDiagnosis(!tooltipOpenDiagnosis);
    };
    const toggleTendinopathies = (e) => {
        e.preventDefault();
        setTooltipOpenTendinopathies(!tooltipOpenDiagnosis);
    };
    const toggleCategorization = (e) => {
        e.preventDefault();
        setTooltipOpenCategorization(!tooltipOpenCategorization);
    };
    const toggleSuggestedTreatment = (e) => {
        e.preventDefault();
        setTooltipOpenSuggestedTreatment(!tooltipOpenSuggestedTreatment);
    };

    useEffect(() => {
        if (centerId) {
            getPatientList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getPatientList(centerId, page, rowsPerPage)
    }, [page]);
    const getPatientList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getPatientListAPI('', centerId, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddPatientClick = () => {
        history.push('/patient/add')
    }
    const handleClickEdit = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/edit/${data?.id}`)
    }
    const handleClickPateintHistory = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/medical-histories/${data?.id}`)
    }
    const handleClicktreatment = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/treatments/list/${data?.id}`)
    }
    const handleClickDpep = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/dpeps/list/${data?.id}`)
    }
    const handleClickCDI = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/cdis/list/${data?.id}`)
    }
    const handleClickECDI = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/extended-cdis/list/${data?.id}`)
    }
    const handleClickDiagnosis = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/diagnosis/list/${data?.id}`)
    }
    const handleClickTendinopathies = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/tendinopathies/list/${data?.id}`)
    }
    const handleClickCategorization = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/categorization/list/${data?.id}`)
    }
    const handleClickSuggestedTreatment = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        
        history.push(`/patient/suggested-treatment/list/${data?.id}`)
    }
    const handleClickDashboard = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-'+ data?.center_info?.center_reg_code+ '-' + data?.created_at.substring(0,7).replace('-','')+ '-' + data?.id)
        history.push(`/center/patient/dashboard/${params?.center_id}/${data?.id}`)
    }

    useEffect(() => {
        if (localStorage.getItem('userDetail')) {
            if (JSON.parse(localStorage.getItem('userDetail'))) {
                let userDetail = JSON.parse(localStorage.getItem('userDetail'));
                if (userDetail) {
                    setCenterInfo(userDetail?.center_info)
                }
            }
        }
    }, []);

    const getDashboardReport = async () => {
        setIsLoader(true)
        const response = await getDashboardReportSuperAdminAPI(centerId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDashboardStats(response?.data)
            //    setVisitNumber(response?.data?.count)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getTodaysTreatment = async () => {
        setIsLoader(true)
        const response = await getTodaysTreatmentSuperAdminAPI(centerId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDashboardTreatment(response?.data?.rows)
            //    setVisitNumber(response?.data?.count)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getCenterInfo = async () => {
        setIsLoader(true)
        const response = await getCenterDetailByIDAPI(centerId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCenterInfo(response?.data)
            //    setVisitNumber(response?.data?.count)
            // history.push(`/patient/medical-histories/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClickManageMembers = (e) => {
        history.push('/members')
    }
    const handleClickManageRoles = (e) => {
        history.push('/roles')
    }

    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Dashboard"
                            subheading=""
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Center List", url: "/centers" },
                { label: `Dashboard (${centerName})` },
            ]} />
            <Row>
                <Card className="main-card mb-3">
                    <CardTitle className="flex-space-between">Action Buttons</CardTitle>
                    <CardBody>
                        <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickManageMembers(e) }}>Manage Members</button>
                            <button className="btn btn-primary" style={{ backgroundColor: '#47aaa8', borderColor: '#47aaa8' }} onClick={(e) => { handleClickManageRoles(e) }}>Manage Roles</button>
                        </div>
                    </CardBody>
                </Card>
                <Col md="3">
                    <div className="card mb-3 bg-arielle-smile widget-chart text-white card-border">
                        <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div>
                        <div className="widget-subheading" style={{ marginTop: '5px' }}>
                            Total Treatments
                        </div>
                        <div className="widget-description text-white">
                            {/* <FontAwesomeIcon icon={faAngleUp} />  */}
                            <span className="ps-1 fs-32">{dashboardStats?.treatments}</span>
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="card mb-3 bg-midnight-bloom widget-chart text-white card-border">
                        <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div>
                        <div className="widget-subheading" style={{ marginTop: '5px' }}>
                            Total Patients
                        </div>
                        <div className="widget-description text-white">
                            {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                            <span className="ps-1 fs-32">{dashboardStats?.patients}</span>
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="card mb-3 bg-grow-early widget-chart text-white card-border">
                        {/* <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div> */}
                        <div className="widget-subheading" style={{ marginTop: '17px', marginBottom: '17px' }}>
                            <i className="fa fa-usd" />
                            <span style={{ marginLeft: '20px' }}>Treatments</span>
                        </div>
                        <div className="widget-description text-white" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {/* <FontAwesomeIcon icon={faAngleUp} /> */}
                            <span className="ps-1">Treatments Bought: {centerInfo?.treatments_bought}</span>
                            <span className="ps-1">Treatments Refund: {centerInfo?.treatments_refund}</span>
                            <span className="ps-1">Treatments Used: {centerInfo?.treatments_used}</span>
                        </div>
                    </div>
                </Col>
                <Col md="3">
                    <div className="card mb-3 bg-love-kiss widget-chart card-border">
                        {/* <div className="icon-wrapper rounded-circle">
                            <div className="icon-wrapper-bg bg-white opacity-10" />
                            <i className="lnr-cog icon-gradient bg-arielle-smile" />
                        </div> */}
                        <div className="widget-subheading" style={{ marginTop: '10px' }}>
                            <i className="fa fa-users" />
                            <span style={{ marginLeft: '20px' }}>Total Members: <strong>{parseInt(dashboardStats?.accountants + dashboardStats?.consultantDoctors + dashboardStats?.staff + dashboardStats?.treatmentDoctors)}</strong></span>
                        </div>
                        <div className="widget-description text-white" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <span className="ps-1">Accountants: {dashboardStats?.accountants}</span>
                            <span className="ps-1">Consultant Doctors: {dashboardStats?.consultantDoctors}</span>
                            <span className="ps-1">Staff: {dashboardStats?.staff}</span>
                            <span className="ps-1">Treatment Doctors: {dashboardStats?.treatmentDoctors}</span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Today's Treatment List</CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Patient Name</th>
                                    <th>Center Name</th>
                                    <th>Treatment By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboardTreatment && dashboardTreatment.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.patient_info?.name}</td>
                                        <td>{item?.center_info?.name}</td>
                                        <td>{item?.user_created?.name}</td>
                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                </CardBody>

            </Card>
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Patient List
                        {permissions.includes("PATIENT_CREATE") && (
                            <button className="btn btn-primary" onClick={() => handleAddPatientClick()}>
                                Add Patient
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Contact No</th>
                                    <th>Gender</th>
                                    <th>DOB</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {patientList && patientList.map((item, index) => (
                                    <tr key={index}>
                                        <td><span style={{ cursor: 'pointer', color: 'blue' }} onClick={(e) => { handleClickDashboard(e, item) }}>{item?.name}</span></td>
                                        <td>{item?.contact_number}</td>
                                        <td>{item?.gender == "1" ? "Male" : item?.gender == "2" ? "Female" : "Other"}</td>
                                        <td>{item?.dob.split('T')[0]}</td>
                                        <td>{dateTimeFormate(item?.created_at)}</td>


                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
}

export default CenterAdmin;
