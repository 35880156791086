import { Route, Redirect, useHistory } from "react-router-dom";
import React, { Suspense, lazy, Fragment, useEffect, useState } from 'react';

import {
    ToastContainer,
} from 'react-toastify';
import userUtil from "../../utils/UserUtils";
import axios, { CancelTokenSource } from "axios";
import { getUserDetailAPI } from './../../api/network/CommonAPI';
import Loader from "../../api/helperComponents/Loader";
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar";

// const Dashboards = lazy(() => import('../../Pages/Dashboards/index'));
// Super Admin
// const SuperAdminCenters = lazy(() => import('../../Pages/SuperAdmin/Centers/Component/CenterList'));
// const SuperAdminCentersAdd = lazy(() => import('../../Pages/SuperAdmin/Centers/Component/CenterAdd'));
// const SuperAdminCentersUserList = lazy(() => import('../../Pages/SuperAdmin/Centers/Component/userList'));
// const SuperAdminCentersUserAdd = lazy(() => import('../../Pages/SuperAdmin/Centers/Component/userAdd'));


import Dashboards from '../../Pages/Dashboards/index';


import SuperAdminCenters from '../../Pages/SuperAdmin/Centers/Component/CenterList';
import SuperAdminCentersAdd from '../../Pages/SuperAdmin/Centers/Component/CenterAdd';
import SuperAdminCentersUserList from '../../Pages/SuperAdmin/Centers/Component/userList';
import SuperAdminCentersUserAdd from '../../Pages/SuperAdmin/Centers/Component/userAdd';
import SuperAdminProfession from '../../Pages/SuperAdmin/Professions/List';
import SuperAdminProfessionAdd from '../../Pages/SuperAdmin/Professions/Add';



import AdminStaff from '../../Pages/Member/list';
import AdminStaffAdd from '../../Pages/Member/add';
import AdminMachineList from '../../Pages/Machine/list';
import AdminMachineAdd from '../../Pages/Machine/add';
import PatientsList from '../../Pages/Patient/list';
import PatientAdd from '../../Pages/Patient/add';
import PatientMedicialHistoryList from '../../Pages/Patient/medical-history/list';
import PatientMedicialHistoryAdd from '../../Pages/Patient/medical-history/add';
import RolesList from '../../Pages/Roles/list';
import RolesEdit from '../../Pages/Roles/add';
import PatientTreatmentAdd from '../../Pages/Patient/treatment/add';
import PatientTreatmentList from '../../Pages/Patient/treatment/list';
import patientTreatments from '../../Pages/Treatments/list';

import PatientDpepList from '../../Pages/Patient/dpep/list';
import PatientDpepAdd from '../../Pages/Patient/dpep/add';

import PatientCdiList from '../../Pages/Patient/CDI/list';
import PatientCdiAdd from '../../Pages/Patient/CDI/add';

import PatientECdiList from '../../Pages/Patient/ECDI/list';
import PatientECdiAdd from '../../Pages/Patient/ECDI/add';

import PatientDiagosisList from '../../Pages/Patient/Diagnosis/list';
import PatientDiagosisAdd from '../../Pages/Patient/Diagnosis/add';

import PatientTendinopathiesList from '../../Pages/Patient/Tendinopathies/list';
import PatientTendinopathiesAdd from '../../Pages/Patient/Tendinopathies/add';

import PatientCategorizationList from '../../Pages/Patient/Categorization/list';
import PatientCategorizationAdd from '../../Pages/Patient/Categorization/add';

import PatientSuggestedTreatmentList from '../../Pages/Patient/Suggested-treatment/list';
import PatientSuggestedTreatmentAdd from '../../Pages/Patient/Suggested-treatment/add';

import PatientTripleLine from '../../Pages/Patient/Triple-line/list';
import PatientTripleAdd from '../../Pages/Patient/Triple-line/add';

import PatientLabTestList from '../../Pages/Patient/LabTest/list';
import PatientLabTestAdd from '../../Pages/Patient/LabTest/add';

import PatientDashboard from '../../Pages/Patient/Dashboard/index';
import CenterDashboard from '../../Pages/SuperAdmin/Centers/Component/dashboard';
import CenterPatientDashboard from '../../Pages/SuperAdmin/Centers/Component/PatientDashboard';

import SuperAdminReporting from '../../Pages/SuperAdmin/Reporting/index';
import CenterAdminReporting from '../../Pages/Reporting/index';
// import RolesAdd from '../../Pages/Roles/add';

import TestCategoriesList from '../../Pages/TestCategories/list';
import TestCategoriesEdit from '../../Pages/TestCategories/add';


// login
import Login from '../../Pages/auth/login';

//Forgot Passsword
import ForgotPassword from '../../Pages/auth/ForgotPassword';
import ResetPassword from '../../Pages/auth/ResetPassword';


// const AdminStaff = lazy(() => import('../../Pages/Member/list'));
// const AdminStaffAdd = lazy(() => import('../../Pages/Member/add'));
// const AdminMachineList = lazy(() => import('../../Pages/Machine/list'));
// const AdminMachineAdd = lazy(() => import('../../Pages/Machine/add'));
// const PatientsList = lazy(() => import('../../Pages/Patient/list'));
// const PatientAdd = lazy(() => import('../../Pages/Patient/add'));
// const RolesList = lazy(() => import('../../Pages/roles/list'));
// const RolesAdd = lazy(() => import('../../Pages/roles/add'));
// const RolesEdit = lazy(() => import('../../Pages/roles/edit'));

// const Login = lazy(() => import('../../Pages/auth/login'));

const AppMain = () => {
    const history = useHistory();
    const cancelTokenSource = axios.CancelToken.source();
    const [isLoader, setIsLoader] = useState(false);
    const [helperTextLoader, setHelperTextLoader] = useState("");
    const [userInfo, setUserInfo] = useState(userUtil?.userData ? userUtil?.userData : JSON.parse(localStorage.getItem('userDetail')));


    useEffect(() => {
        if (userUtil?.userData && localStorage.getItem('user-token')) {
            setUserInfo(userUtil?.userData)
        } else {
            if (localStorage.getItem('user-token')) {
                if (JSON.parse(localStorage.getItem('userDetail'))) {
                    setUserInfo(JSON.parse(localStorage.getItem('userDetail')))
                    userUtil.setUserData(JSON.parse(localStorage.getItem('userDetail')))
                } else {
                    getUserDetail()
                }
                // getUserDetail()
            }
        }
    }, [userUtil?.userData]);
    // useEffect(() => {
    //     axios.defaults.headers = { 'x-auth-token': localStorage.getItem('user-token') };
    //     if (!localStorage.getItem('user-token')) {
    //         history.push('/login')
    //     }
    // }, []);
    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, [location.pathname]);

    const getUserDetail = async () => {
        setHelperTextLoader('Fetching User Detail')
        const response = await getUserDetailAPI(cancelTokenSource.token);
        setIsLoader(false)
        if (response.success) {
            setUserInfo(response?.data)
            userUtil.setUserData(response?.data)
        }
    }

    return (
        <Fragment>
            {isLoader && (<Loader helperText={helperTextLoader} />)}
            {localStorage.getItem('user-token') ? (
                <>
                    <AppHeader />
                    {userInfo?.role?.name == "SUPER_ADMIN" ? (
                        <div className="app-main">
                            <AppSidebar />
                            <div className="app-main__outer">
                                <div className="app-main__inner">
                                    <Route path="/dashboard" component={Dashboards} />
                                    <Route path="/centers" component={SuperAdminCenters} />
                                    <Route path="/center/add" component={SuperAdminCentersAdd} />
                                    <Route path="/center/edit/:center_id" component={SuperAdminCentersAdd} />
                                    <Route path="/center/user/list/:center_id" component={SuperAdminCentersUserList} />
                                    <Route path="/center/user/add/:center_id" component={SuperAdminCentersUserAdd} />
                                    <Route path="/center/user/edit/:center_id/:user_id" component={SuperAdminCentersUserAdd} />
                                    <Route path="/center/dashboard/:center_id" component={CenterDashboard} />
                                    <Route path="/center/patient/dashboard/:center_id/:patient_id" component={CenterPatientDashboard} />
                                    <Route path="/professions" component={SuperAdminProfession} />
                                    <Route path="/profession/add" component={SuperAdminProfessionAdd} />
                                    <Route path="/profession/edit/:profession_id" component={SuperAdminProfessionAdd} />

                                    <Route path="/patients" component={PatientsList} />
                                    <Route path="/patient/dashboard/:patient_id" component={PatientDashboard} />
                                    <Route path="/patient/add" component={PatientAdd} />
                                    <Route path="/patient/edit/:patient_id" component={PatientAdd} />
                                    <Route path="/patient/medical-histories/:patient_id" component={PatientMedicialHistoryList} />
                                    <Route path="/patient/medical-history/add/:patient_id" component={PatientMedicialHistoryAdd} />
                                    <Route path="/patient/single-medical-history/view/:patient_id/:medical_history_id" component={PatientMedicialHistoryAdd} />
                                    <Route path="/patient/treatments/list/:patient_id" component={PatientTreatmentList} />
                                    <Route path="/patient/treatments/add/:patient_id" component={PatientTreatmentAdd} />
                                    <Route path="/patient/treatments/edit/:treatment_id" component={PatientTreatmentAdd} />
                                    <Route path="/patient/treatment/:patient_id/:treatment_id" component={PatientTreatmentAdd} />
                                    <Route path="/patient-treatments" component={patientTreatments} />
                                    <Route path="/patient/dpeps/list/:patient_id" component={PatientDpepList} />
                                    <Route path="/patient/dpep/add/:patient_id" component={PatientDpepAdd} />
                                    <Route path="/patient/dpep/edit/:patient_id/:dped_id" component={PatientDpepAdd} />

                                    <Route path="/patient/cdis/list/:patient_id" component={PatientCdiList} />
                                    <Route path="/patient/cdi/add/:patient_id" component={PatientCdiAdd} />
                                    <Route path="/patient/cdi/edit/:patient_id/:cdi_id" component={PatientCdiAdd} />

                                    <Route path="/patient/extended-cdis/list/:patient_id" component={PatientECdiList} />
                                    <Route path="/patient/extended-cdi/add/:patient_id" component={PatientECdiAdd} />
                                    <Route path="/patient/extended-cdi/edit/:patient_id/:e_cdi_id" component={PatientECdiAdd} />

                                    <Route path="/patient/diagnosis/list/:patient_id" component={PatientDiagosisList} />
                                    <Route path="/patient/diagnosis/add/:patient_id" component={PatientDiagosisAdd} />
                                    <Route path="/patient/diagnosis/edit/:patient_id/:diagnosis_id" component={PatientDiagosisAdd} />

                                    <Route path="/patient/tendinopathies/list/:patient_id" component={PatientTendinopathiesList} />
                                    <Route path="/patient/tendinopathies/add/:patient_id" component={PatientTendinopathiesAdd} />
                                    <Route path="/patient/tendinopathies/edit/:patient_id/:tendinopathies_id" component={PatientTendinopathiesAdd} />

                                    <Route path="/patient/categorization/list/:patient_id" component={PatientCategorizationList} />
                                    <Route path="/patient/categorization/add/:patient_id" component={PatientCategorizationAdd} />
                                    <Route path="/patient/categorization/edit/:patient_id/:categorization_id" component={PatientCategorizationAdd} />

                                    <Route path="/patient/suggested-treatment/list/:patient_id" component={PatientSuggestedTreatmentList} />
                                    <Route path="/patient/suggested-treatment/add/:patient_id" component={PatientSuggestedTreatmentAdd} />
                                    <Route path="/patient/suggested-treatment/edit/:patient_id/:suggestedtreatment_id" component={PatientSuggestedTreatmentAdd} />

                                    <Route path="/patient/triple-line/list/:patient_id" component={PatientTripleLine} />
                                    <Route path="/patient/triple-line/add/:patient_id" component={PatientTripleAdd} />
                                    <Route path="/patient/triple-line/edit/:patient_id/:triple_line_id" component={PatientTripleAdd} />

                                    <Route path="/patient/lab-test/list/:patient_id" component={PatientLabTestList} />
                                    <Route path="/patient/lab-test/add/:patient_id" component={PatientLabTestAdd} />
                                    <Route path="/patient/lab-test/edit/:patient_id/:lab_id" component={PatientLabTestAdd} />

                                    <Route path="/roles" component={RolesList} />
                                    <Route path="/role/edit/:role_key" component={RolesEdit} />

                                    <Route path="/members" component={AdminStaff} />
                                    <Route path="/member/add" component={AdminStaffAdd} />
                                    <Route path="/member/edit/:staff_id" component={AdminStaffAdd} />

                                    <Route path="/machines" component={AdminMachineList} />
                                    <Route path="/machine/add" component={AdminMachineAdd} />
                                    <Route path="/machine/edit/:machine_id" component={AdminMachineAdd} />
                                    
                                    <Route path="/test-categories" component={TestCategoriesList} />
                                    <Route path="/test-category/add" component={TestCategoriesEdit} />
                                    <Route path="/test-category/edit/:test_category_id" component={TestCategoriesEdit} />

                                   
                                    {/* <Route path="/patient/lab-test/add/:patient_id" component={PatientLabTestAdd} />
                                    <Route path="/patient/lab-test/edit/:patient_id" component={PatientLabTestAdd} /> */}

                                    <Route path="/reporting" component={SuperAdminReporting} />
                                    {/* <>
                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/dashboard" component={Dashboards} />
                                        </Suspense>
                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/centers" component={SuperAdminCenters} />
                                        </Suspense>

                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/center/add" component={SuperAdminCentersAdd} />
                                        </Suspense>
                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/center/edit/:center_id" component={SuperAdminCentersAdd} />
                                        </Suspense>
                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/center/user/list/:center_id" component={SuperAdminCentersUserList} />
                                        </Suspense>
                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/center/user/add/:center_id" component={SuperAdminCentersUserAdd} />
                                        </Suspense>
                                        <Suspense fallback={
                                            <div className="loader-container">
                                                <div className="loader-container-inner">
                                                    <h6 className="mt-3">
                                                        Please wait while we load this page
                                                    </h6>
                                                </div>
                                            </div>
                                        }>
                                            <Route path="/center/user/edit/:center_id/:user_id" component={SuperAdminCentersUserAdd} />
                                        </Suspense>



                                    </> */}
                                </div>
                            </div>
                        </div>
                    ) :
                        <div className="app-main">
                            <AppSidebar />
                            <div className="app-main__outer">
                                <div className="app-main__inner">
                                    <Route path="/dashboard" component={Dashboards} />
                                    <Route path="/members" component={AdminStaff} />
                                    <Route path="/member/add" component={AdminStaffAdd} />
                                    <Route path="/member/edit/:staff_id" component={AdminStaffAdd} />
                                    <Route path="/machines" component={AdminMachineList} />
                                    <Route path="/machine/add" component={AdminMachineAdd} />
                                    <Route path="/machine/edit/:machine_id" component={AdminMachineAdd} />
                                    <Route path="/patients" component={PatientsList} />
                                    <Route path="/patient/add" component={PatientAdd} />
                                    <Route path="/patient/edit/:patient_id" component={PatientAdd} />
                                    <Route path="/patient/medical-histories/:patient_id" component={PatientMedicialHistoryList} />
                                    <Route path="/patient/medical-history/add/:patient_id" component={PatientMedicialHistoryAdd} />
                                    <Route path="/patient/single-medical-history/view/:patient_id/:medical_history_id" component={PatientMedicialHistoryAdd} />
                                    <Route path="/patient/treatments/list/:patient_id" component={PatientTreatmentList} />
                                    <Route path="/patient/treatments/add/:patient_id" component={PatientTreatmentAdd} />
                                    <Route path="/patient/treatments/edit/:treatment_id" component={PatientTreatmentAdd} />
                                    <Route path="/patient/treatment/:patient_id/:treatment_id" component={PatientTreatmentAdd} />
                                    <Route path="/patient-treatments" component={patientTreatments} />
                                    <Route path="/roles" component={RolesList} />
                                    <Route path="/role/edit/:role_key" component={RolesEdit} />
                                    <Route path="/patient/dpeps/list/:patient_id" component={PatientDpepList} />
                                    <Route path="/patient/dpep/add/:patient_id" component={PatientDpepAdd} />
                                    <Route path="/patient/dpep/edit/:patient_id/:dped_id" component={PatientDpepAdd} />

                                    <Route path="/patient/cdis/list/:patient_id" component={PatientCdiList} />
                                    <Route path="/patient/cdi/add/:patient_id" component={PatientCdiAdd} />
                                    <Route path="/patient/cdi/edit/:patient_id/:cdi_id" component={PatientCdiAdd} />

                                    <Route path="/patient/extended-cdis/list/:patient_id" component={PatientECdiList} />
                                    <Route path="/patient/extended-cdi/add/:patient_id" component={PatientECdiAdd} />
                                    <Route path="/patient/extended-cdi/edit/:patient_id/:e_cdi_id" component={PatientECdiAdd} />

                                    <Route path="/patient/diagnosis/list/:patient_id" component={PatientDiagosisList} />
                                    <Route path="/patient/diagnosis/add/:patient_id" component={PatientDiagosisAdd} />
                                    <Route path="/patient/diagnosis/edit/:patient_id/:diagnosis_id" component={PatientDiagosisAdd} />

                                    <Route path="/patient/tendinopathies/list/:patient_id" component={PatientTendinopathiesList} />
                                    <Route path="/patient/tendinopathies/add/:patient_id" component={PatientTendinopathiesAdd} />
                                    <Route path="/patient/tendinopathies/edit/:patient_id/:tendinopathies_id" component={PatientTendinopathiesAdd} />

                                    <Route path="/patient/categorization/list/:patient_id" component={PatientCategorizationList} />
                                    <Route path="/patient/categorization/add/:patient_id" component={PatientCategorizationAdd} />
                                    <Route path="/patient/categorization/edit/:patient_id/:categorization_id" component={PatientCategorizationAdd} />

                                    <Route path="/patient/suggested-treatment/list/:patient_id" component={PatientSuggestedTreatmentList} />
                                    <Route path="/patient/suggested-treatment/add/:patient_id" component={PatientSuggestedTreatmentAdd} />
                                    <Route path="/patient/suggested-treatment/edit/:patient_id/:suggestedtreatment_id" component={PatientSuggestedTreatmentAdd} />

                                    <Route path="/patient/triple-line/list/:patient_id" component={PatientTripleLine} />
                                    <Route path="/patient/triple-line/add/:patient_id" component={PatientTripleAdd} />
                                    <Route path="/patient/triple-line/edit/:patient_id/:triple_line_id" component={PatientTripleAdd} />

                                    <Route path="/patient/dashboard/:patient_id" component={PatientDashboard} />
                                    
                                    <Route path="/patient/lab-test/list/:patient_id" component={PatientLabTestList} />
                                    <Route path="/patient/lab-test/add/:patient_id" component={PatientLabTestAdd} />
                                    <Route path="/patient/lab-test/edit/:patient_id/:lab_id" component={PatientLabTestAdd} />

                                    <Route path="/reporting" component={CenterAdminReporting} />
                                    {/* center Dashboard For Super Admin */}

                                    {/* <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/dashboard" component={Dashboards} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/members" component={AdminStaff} />
                                    </Suspense>

                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/member/add" component={AdminStaffAdd} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/member/edit/:staff_id" component={AdminStaffAdd} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/machines" component={AdminMachineList} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/machine/add" component={AdminMachineAdd} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/machine/edit/:machine_id" component={AdminMachineAdd} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/patients" component={PatientsList} />
                                    </Suspense>

                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/patient/add" component={PatientAdd} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/patient/edit/:patient_id" component={PatientAdd} />
                                    </Suspense>
                                    <Suspense fallback={
                                        <div className="loader-container">
                                            <div className="loader-container-inner">
                                                <h6 className="mt-3">
                                                    Please wait while we load this page
                                                </h6>
                                            </div>
                                        </div>
                                    }>
                                        <Route path="/roles/list" component={RolesEdit} />
                                    </Suspense> */}
                                </div>
                            </div>
                        </div>
                    }
                </>
            ) :
                <Suspense fallback={
                    <div className="loader-container">
                        <div className="loader-container-inner">
                            <h6 className="mt-3">
                                Please wait while we load this page
                            </h6>
                        </div>
                    </div>
                }>
                    <Route path="/login" component={Login} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/resetPassword/:token" component={ResetPassword} />
                </Suspense>

            }


            <Route exact path="/" render={() => (
                <Redirect to="/dashboard" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;