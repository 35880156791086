import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getPatientMedicalHistoryListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
import { isToday } from '../../../utils/isToday';
const CenterList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientMedicalHistoryList, setPatientMedicalHistoryList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));

    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    let patientId = params?.patient_id
    useEffect(() => {

        if (centerId) {
            getPatientMedicalHistoryList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getPatientMedicalHistoryList(centerId, page, rowsPerPage)
    }, [page]);
    const getPatientMedicalHistoryList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getPatientMedicalHistoryListAPI(centerId, patientId, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientMedicalHistoryList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddPatientMedicalHistoryClick = (e) => {
        history.push(`/patient/medical-history/add/${patientId}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/single-medical-history/view/${patientId}/${id}`)
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: " Medical History" },
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Patient Medical History List
                        {(permissions.includes("PATIENT_MEDICAL_HISTORY_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddPatientMedicalHistoryClick()}>
                                Add Patient Medical History
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Cranio Cervical Rotation Type</th>
                                    <th>Heterotrophic Calcific Area in ligamentum nuchae</th>
                                    <th>Clinical Presentation</th>
                                    <th>Pain Type</th>
                                    <th>Created At</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {patientMedicalHistoryList && patientMedicalHistoryList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item?.cranio_cervical_rotation_type}</td>
                                        <td>{item?.heterotrophic_calcific_area_ligamentum_nuchae}</td>
                                        <td>{item?.clinical_presentation ? item?.clinical_presentation.join(',') : ''}</td>
                                        <td>{item?.pain_type}</td>
                                        <td>{dateTimeFormate(item?.created_at)}</td>
                                        {((permissions.includes("PATIENT_MEDICAL_HISTORY_UPDATE") || role == "SUPER_ADMIN") && isToday(item?.created_at)) && (
                                            <td>
                                                <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                            </td>
                                        )}

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default CenterList;
