import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback,

} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitSuggestedTreatmentAPI, submiSuggestedTreatmentEditAPI, getSugestedTreatmentDetailAPI, getRecentPatientCategorizationsAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const DiagnosisAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [questionValue, setQuestionValue] = useState({});
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            question: 'Patient Grade',
            key: 'patient_grade',
            description: 'Mark only one oval',
            options: [
                { GRADE_I: { key: 'GRADE_I', value: 1, name: 'Grade I' } },
                { GRADE_II: { key: 'GRADE_II', value: 2, name: 'Grade II' } },
                { GRADE_III: { key: 'GRADE_III', value: 3, name: 'Grade III' } },
                { GRADE_IV: { key: 'GRADE_IV', value: 4, name: 'Grade IV' } },
            ],
        },
        {
            question: 'Duration',
            key: 'duration',
            description: 'Mark only one oval',
            options: [
                { WEEKS_6_12: { key: 'Weeks_6_12Weeks_6_12', value: 1, name: '6-12 Weeks' } },
                { WEEKS_12_16: { key: 'WEEKS_12_16', value: 2, name: '12-16 Weeks' } },
                { WEEKS_16_24: { key: 'WEEKS_16_24', value: 3, name: '16-24 Weeks' } },
                { MORE_THAN_24_WEEKS: { key: 'MORE_THAN_24_WEEKS', value: 4, name: 'More than 24 Weeks' } },
            ],
        },

        {
            question: 'No Of Sessions',
            key: 'no_of_sessions',
            description: 'Mark only one oval',
            options: [
                { 6_12: { key: '6_12', value: 1, name: '6-12' } },
                { 12_20: { key: '12_20', value: 2, name: '12-20' } },
                { 20_24: { key: '20_24', value: 3, name: '20-24' } },
                { 24_30: { key: '24_30', value: 4, name: '24-30' } },
            ],
        },
        {
            question: 'Follow Up',
            key: 'follow_up',
            description: 'Mark only one oval',
            options: [
                { AS_PER_NEED: { key: 'AS_PER_NEED', value: 1, name: 'As Per Need' } },
                { WEEKS_24: { key: 'WEEKS_24', value: 2, name: '24 Weeks' } },
                { YEARS_2: { key: 'YEARS_2', value: 3, name: '2 Years' } },
                { LIFE_TIME: { key: 'LIFE_TIME', value: 4, name: 'Life Time' } },
            ],
        },
        {
            question: 'Physiotherapy',
            key: 'physiotherapy',
            description: 'Mark only one oval',
            options: [
                { OBSERVE_POSTURE_CORRECTIVE_EDUCATIVE_ADVICE_AS_CONVEYED: { key: 'OBSERVE_POSTURE_CORRECTIVE_EDUCATIVE_ADVICE_AS_CONVEYED', value: 1, name: 'Observe Posture Corrective educative advice as conveyed' } },
                { NOT_REQUIRED: { key: 'NOT_REQUIRED', value: 2, name: 'Not required' } },
            ],
        },
    ]);

    useEffect(() => {
        if (params?.suggestedtreatment_id) {
            getSugestedTreatmentDetail(params?.suggestedtreatment_id)

        } else {
            getRecentPatientCategorizations()
        }
    }, [params?.suggestedtreatment_id]);


    const getRecentPatientCategorizations = async () => {
        setIsLoader(true)
        const response = await getRecentPatientCategorizationsAPI(userUtil?.userData?.center_id, params?.patient_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {

            let patient_grade = response?.data?.calculated?.grade

            let duration = response?.data?.calculated?.duration

            let no_of_sessions = response?.data?.calculated?.sessions

            let follow_up = response?.data?.calculated?.followUp


            let dataToStore = {
                patient_grade: patient_grade,
                duration: duration,
                no_of_sessions: no_of_sessions,
                follow_up: follow_up,
            }
            setQuestionValue(dataToStore)
        }
    }
    const getSugestedTreatmentDetail = async (suggestedtreatment_id) => {
        setIsLoader(true)
        const response = await getSugestedTreatmentDetailAPI(userUtil?.userData?.center_id, params?.patient_id, suggestedtreatment_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let patient_grade = response?.data?.patient_grade

            let duration = response?.data?.duration

            let no_of_sessions = response?.data?.no_of_sessions

            let follow_up = response?.data?.follow_up

            let physiotherapy = response?.data?.physiotherapy

            let dataToStore = {
                patient_grade: patient_grade,
                duration: duration,
                no_of_sessions: no_of_sessions,
                physiotherapy: physiotherapy,
                follow_up: follow_up,
            }
            setQuestionValue(dataToStore)
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')

        if (params?.suggestedtreatment_id) {
            handleSubmitUserEdit();
        } else {
            handleSubmitUser();
        }


    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.patient_id,
        }
        questionValue['id'] = params?.suggestedtreatment_id
        questionValue['customCreatedAt'] =  customCreatedAt != ''? customCreatedAt:null
        const response = await submiSuggestedTreatmentEditAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Suggested Treatment Plan Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/suggested-treatment/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)

        questionValue['customCreatedAt'] =  customCreatedAt != ''? customCreatedAt:null
        const response = await submitSuggestedTreatmentAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Suggested Treatment Plan Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/suggested-treatment/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClick = (e, data, id = null) => {
        console.log(data.key, 'data.key')
        console.log(id, 'id')
        // var selectedValues = [];

        // const checkbox = document.getElementById(data.key + "_" + id);
        // const checkbox = document.querySelector('input[name='+data?.key+']');
        // console.log(checkbox, 'checkbox')
        // if (checkbox) {
        //     checkbox.checked = true;
        //     console.log(checkbox.checked, 'checkbox')
        // } else {
        //     console.error('Checkbox not found');
        // }

        // var checkboxes = document.getElementsByName(data?.key);

        // for (var i = 0; i < checkboxes.length; i++) {
        //     if (checkboxes[i].checked) {
        //         selectedValues.push(checkboxes[i].value);
        //     }
        // }
        questionValue[data?.key] = e.target.value
        setQuestionValue(questionValue)
        console.log(questionValue, 'selectedValues selectedValues')
    }
    const checkIsSelected = (key, value) => {
        console.log('checkIsSelectedcheckIsSelected', 'key=', key, 'value=', value)
        let isChecked = false;
        let SelectedValues = questionValue[key]
        if (SelectedValues) {
            SelectedValues = SelectedValues.map(Number)
            if (SelectedValues) {
                for (let s of SelectedValues) {
                    if (s == value) {
                        return true
                        isChecked = true
                    }
                }
            }
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Suggested Treatment Plan", url: `/patient/suggested-treatment/list/${params?.patient_id}` },
                        { label: params?.suggestedtreatment_id ? "Edit Suggested Treatment Plan" : "Add Suggested Treatment Plan" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.suggestedtreatment_id ? "Edit" : "Add"}{" "} Suggested Treatment Plan</CardTitle>
                            <Row>

                                {diagnosisQuestions && diagnosisQuestions.map((item, index) => {
                                    return (
                                        <Col md="6" >
                                            {!params?.suggestedtreatment_id ?
                                                <>
                                                    <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                        {console.log(questionValue, 'questionValue')}
                                                        <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                        {item?.options && Object.keys(item?.options).map(itemOption => {
                                                            let key = Object.keys(item?.options[itemOption])
                                                            console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                            let optionItem = item?.options[itemOption][key]
                                                            let optionValue = item?.options[itemOption][key]?.value
                                                            let optionName = item?.options[itemOption][key]?.name
                                                            let optionKey = item?.options[itemOption][key]?.key

                                                            let isChecked = false;

                                                            let SelectedValues = questionValue[item?.key]
                                                            console.log(SelectedValues, 'SelectedValues')
                                                            console.log(optionValue, 'optionValue')
                                                            if (SelectedValues == optionValue) {
                                                                isChecked = true
                                                            }
                                                            return (
                                                                <FormGroup check style={{ marginLeft: '30px' }}>
                                                                    <Label check >
                                                                        {item?.key == 'physiotherapy' ?
                                                                            <Input name={item?.key} defaultValue={optionValue} type={"radio"}
                                                                                defaultChecked={isChecked}
                                                                                onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                            />
                                                                            :
                                                                            <Input name={item?.key} defaultValue={optionValue} type={"radio"}
                                                                                checked={isChecked}
                                                                                onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                            />
                                                                        }
                                                                        {optionName}
                                                                    </Label>
                                                                </FormGroup>
                                                            )
                                                        })}

                                                    </FormGroup>

                                                </>
                                                :
                                                <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>

                                                    <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                    {item?.options && Object.keys(item?.options).map(itemOption => {
                                                        let key = Object.keys(item?.options[itemOption])
                                                        console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                        let optionItem = item?.options[itemOption][key]
                                                        let optionValue = item?.options[itemOption][key]?.value
                                                        let optionName = item?.options[itemOption][key]?.name
                                                        let optionKey = item?.options[itemOption][key]?.key

                                                        console.log(questionValue, 'questionValue')

                                                        let isChecked = false;

                                                        let SelectedValues = questionValue[item?.key]
                                                        // if (SelectedValues) {
                                                        //     SelectedValues = SelectedValues.map(Number)
                                                        //     if (SelectedValues) {
                                                        //         for (let s of SelectedValues) {
                                                        //             if (s == optionValue) {
                                                        //                 isChecked = true
                                                        //             }
                                                        //         }
                                                        //     }
                                                        // }
                                                        if (SelectedValues == optionValue) {
                                                            isChecked = true
                                                        }
                                                        console.log(isChecked, 'isChecked isChecked isChecked isChecked')
                                                        return (
                                                            <FormGroup check style={{ marginLeft: '30px' }}>
                                                                <Label check>
                                                                    <Input name={item?.key} id={item?.key + "_" + optionValue} checked={isChecked} defaultValue={optionValue} type={"radio"}
                                                                        onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                    />
                                                                    {optionName}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    })}

                                                </FormGroup>
                                            }
                                        </Col>
                                    )
                                })}
                                <FormGroup>
                                    <Label for="cus_created_at">Custom Created At</Label>
                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                </FormGroup>
                            </Row>

                            {!params?.suggestedtreatment_id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DiagnosisAdd;
