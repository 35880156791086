import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitPatientAPI, getPatientDetailAPI, submitPatientEditAPI, getProfessionListAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import userUtil from '../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [gender, setGender] = useState("");
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [age, setAge] = useState("");
    const [professionList, setProfessionList] = useState([]);
    const [profession, setProfession] = useState("");
    const [nic, setNic] = useState("");
    const [referredBy, setReferredBy] = useState("");
    const [reason, setReason] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [dobError, setDobError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [genderError, setGenderError] = useState(false);
    const [professionError, setProfessionError] = useState(false);
    const [nicError, setNicError] = useState(false);
    const [weightError, setWeightError] = useState(false);
    const [heightError, setHeightError] = useState(false);
    const [ageError, setAgeError] = useState(false);
    const [referredByError, setReferredByError] = useState("");
    const [reasonError, setReasonError] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [bmi, setBMI] = useState("");
    const [bMICategory, setBMICategory] = useState("");
    const [professionOptions, setProfessionOptions] = useState([]);
    const [patientStatus, setPatientStatus] = useState(null);
    const [patientStatusError, setPatientStatusError] = useState(false);
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };
    // const options = [
    //     {name: 'Swedish', value: 'sv'},
    //     {name: 'English', value: 'en'},
    // ];
    useEffect(() => {
        getProfessions()
        if (params?.patient_id) {
            getPatientDetail(params?.patient_id)

        }
    }, [params?.patient_id]);
    // useEffect(() => {
    //     if (dob != '') {
    //         calculateAge(dob)
    //     }
    // }, [dob]);
    useEffect(() => {
        if (weight != '' && height != '') {
            calculateBMI(weight, height)
        }
    }, [weight, height]);
    useEffect(() => {
        if (professionList.length > 0) {
            let options = []
            for (let a of professionList) {
                options.push({ name: a?.name, value: a?.id })
            }
            options.sort((item1, item2) => {
                return item1.name.localeCompare(item2.name);
            });
            setProfessionOptions(options)
        }

    }, [professionList]);


    const getPatientDetail = async (patientId) => {
        setIsLoader(true)
        const response = await getPatientDetailAPI(userUtil?.userData?.center_id, patientId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setFirstName(response?.data?.name?.split(' ')[0])
            setLastName(response?.data?.name?.split(' ')[1])
            setDob(response?.data?.dob?.split('T')[0])
            setPhone(response?.data?.contact_number)
            setGender(response?.data?.gender.toString())
            setAddress(response?.data?.address)
            setProfession(response?.data?.profession)
            setNic(response?.data?.nic)
            setWeight(response?.data?.weight)
            setHeight(response?.data?.height)
            setAge(response?.data?.age)
            setPatientStatus(response?.data?.status)
            setReferredBy(response?.data?.referred_by)
            setReason(response?.data?.reason)
            setCustomCreatedAt(response?.data?.created_at.split('T')[0])
        }
    }
    const getProfessions = async () => {
        setIsLoader(true)
        const response = await getProfessionListAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setProfessionList(response?.data)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        if (firstName == '' || lastName == '' || phone == '' || address == '' || gender == '' || profession == '' || nic == '' || weight == '' || height == '' || age == '' || reason == '') {
            if (firstName == '') {
                setFirstNameError(true)
            } else {
                setFirstNameError(false)
            }
            if (lastName == '') {
                setLastNameError(true)
            } else {
                setLastNameError(false)
            }
            if (phone == '') {
                setPhoneError(true)
            } else {
                setPhoneError(false)
            }
            if (address == '') {
                setAddressError(true)
            } else {
                setAddressError(false)
            }
            if (address?.length <= 2) {
                setAddressError(true)
            } else {
                setAddressError(false)
            }

            if (gender == '') {
                setGenderError(true)
            } else {
                setGenderError(false)
            }
            if (profession == '') {
                setProfessionError(true)
            } else {
                setProfessionError(false)
            }
            if (nic == '') {
                setNicError(true)
            } else {
                setNicError(false)
            }
            if (weight == '') {
                setWeightError(true)
            } else {
                setWeightError(false)
            }
            if (height == '') {
                setHeightError(true)
            } else {
                setHeightError(false)
            }
            if (age == '') {
                setAgeError(true)
            } else {
                setAgeError(false)
            }
            if (referredBy == '') {
                setReferredByError(true)
            } else {
                setReferredByError(false)
            }
            if (reason == '') {
                setReasonError(true)
            } else {
                setReasonError(false)
            }
        } else {
            if (address?.length <= 2) {
                setAddressError(true)
                return;
            } else {
                setAddressError(false)
            }
            const regex = /^(?:\+92|0)[0-9]{10}$/;
            if (!regex.test(phone)) {
                setPhoneError(true);
                return;
            }
            const regexNic = /^\d{5}-?\d{7}-?\d{1}$/;
            console.log(nic, 'nic')
            if (!regexNic.test(nic)) {
                setNicError(true);
                return;
            }
            if (params?.patient_id) {
                if (patientStatus == null) {
                    setPatientStatusError(true)
                    return;
                } else {
                    setPatientStatusError(false)
                }
                handleSubmitUserEdit();
            } else {
                handleSubmitUser();
            }

        }
    }
    const calculateDOB = (age) => {
        const currentYear = new Date().getFullYear();
        const birthYear = currentYear - age;
        return `${birthYear}-01-01`; // Assuming January 1st as default birthdate
    };
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.patient_id,
            name: firstName + " " + lastName,
            dob: calculateDOB(age),
            contact: phone,
            address: address,
            gender: gender,
            profession: profession,
            nic: nic,
            weight: weight,
            height: height,
            age: age,
            bmi: bmi,
            status: patientStatus,
            referred_by: referredBy,
            reason: reason,
        }
        const response = await submitPatientEditAPI(userUtil?.userData?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Patient Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/patients')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            name: firstName + " " + lastName,
            dob: calculateDOB(age),
            contact: phone,
            address: address,
            gender: gender,
            profession: profession,
            nic: nic,
            weight: weight,
            height: height,
            age: age,
            bmi: bmi, 
            referred_by: referredBy,
            reason: reason,
            customCreatedAt: customCreatedAt
        }
        const response = await submitPatientAPI(userUtil?.userData?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Patient Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/patients')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleChangeContactNumber = (value) => {
        console.log(value, 'value')
        const inputValue = value;
        const regex = /^(?:\+92|0)[0-9]{10}$/;

        if (regex.test(inputValue)) {
            setPhoneError(false);
            setPhone(inputValue);
        } else {
            setPhone(inputValue);
            setPhoneError(true);
        }
    };
    const handleChangeCNIC = (value) => {
        console.log(value, 'value')
        const regex = /^\d{5}-?\d{7}-?\d{1}$/;

        if (regex.test(value)) {
            console.log('accepted')
            setNic(value);
            setNicError(false);
        } else {
            console.log('not accepted')
            setNic(value);
            setNicError(true);
        }
    };
    const calculateBMI = (weight, heightInFeet) => {
        console.log('calculateBMI called');

        // Convert feet & inches to total inches
        const feet = Math.floor(heightInFeet); // Extract feet
        const inches = (heightInFeet % 1) * 10; // Extract inches (e.g., 5.6 → 6 inches)

        // Convert height to meters (1 inch = 0.0254 meters)
        const heightInMeters = ((feet * 12) + inches) * 0.0254;

        // Calculate BMI
        const bmi = weight / (heightInMeters ** 2);

        // Determine BMI category
        let category = "";
        if (bmi < 18.5) {
            category = "Underweight";
        } else if (bmi >= 18.5 && bmi <= 24.9) {
            category = "Normal weight";
        } else if (bmi >= 25.0 && bmi <= 29.9) {
            category = "Overweight";
        } else {
            category = "Obesity";
        }

        // Update state variables
        setBMICategory(category);
        setBMI(bmi.toFixed(2));

        console.log(category, 'category');
        console.log(bmi.toFixed(2), 'bmi');

        return bmi.toFixed(2);
    };

    // const calculateAge = (birthDate) => {
    //     // Split the birth date into year, month, and day
    //     birthDate = new Date(birthDate);
    //     const birthYear = birthDate.getFullYear();
    //     const birthMonth = birthDate.getMonth() + 1; // Month is zero-based
    //     const birthDay = birthDate.getDate();

    //     // Get the current date
    //     const currentDate = new Date();
    //     const currentYear = currentDate.getFullYear();
    //     const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
    //     const currentDay = currentDate.getDate();

    //     // Calculate the age
    //     let age = currentYear - birthYear;

    //     // Check if the current date is before the birth date for the current year
    //     if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    //         age--; // Subtract 1 from the age if the birthday hasn't occurred yet
    //     }
    //     setAge(age)
    //     // return age;
    // }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    {params?.patient_id ? (
                        <BreadCrumb items={[
                            { label: "Dashboard", url: "/" },
                            { label: "Patients", url: "/patients" },
                            { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                            { label: params?.patient_id ? " Edit Patient" : " Add Patient" }
                        ]} />
                    ) : (
                        <BreadCrumb items={[
                            { label: "Dashboard", url: "/" },
                            { label: "Patients", url: "/patients" },
                            { label: params?.patient_id ? " Edit Patient" : " Add Patient" }
                        ]} />
                    )}

                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.patient_id ? "Edit" : "Add"}{" "} Patient</CardTitle>
                            <div className="row">
                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">First Name<span style={{ color: 'red' }}>*</span></Label>
                                        <Input type="text" autoComplete="off" name="f_name" id="f_name" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="Enter First Name" />
                                        {firstNameError && (
                                            <span className="field-error">Enter First Name to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md="6" >
                                    <FormGroup>
                                        <Label for="f_name">Last Name<span style={{ color: 'red' }}>*</span></Label>
                                        <Input type="text" autoComplete="off" name="l_name" id="l_name" defaultValue={lastName} onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Enter Last Name" />
                                        {lastNameError && (
                                            <span className="field-error">Enter Last Name to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            </div>
                            {/* <FormGroup>
                                <Label for="f_name">Name</Label>
                                <Input type="text" autoComplete="off" name="f_name" id="f_name" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Enter Name" />
                                {firstNameError && (
                                    <span className="field-error">Enter Name to Continue</span>
                                )}
                            </FormGroup> */}
                            {/* <FormGroup>
                                <Label for="dob">Date Of Birth<span style={{ color: 'red' }}>*</span></Label>
                                <Input type="date" name="dob" id="dob" defaultValue={dob} onChange={(e) => setDob(e.target.value)}
                                    placeholder="Enter DOB" />
                                {dobError && (
                                    <span className="field-error">Select Date Of Birth to Continue</span>
                                )}
                            </FormGroup> */}
                            <FormGroup>
                                <Label for="email">Contact No<span style={{ color: 'red' }}>*</span></Label>
                                <Input type="number" name="phone" id="phone" defaultValue={phone} onChange={(e) => handleChangeContactNumber(e.target.value)}
                                    placeholder="Enter Contact No" />
                                <p className="cdi-label-description">Contact No. Format: 0300*******</p>
                                {phoneError && (
                                    <span className="field-error">Enter Contact No to Continue</span>
                                )}

                            </FormGroup>
                            <FormGroup>
                                <Label for="address">Address<span style={{ color: 'red' }}>*</span></Label>
                                <Input type="text" autoComplete="off" name="address" id="address" defaultValue={address} onChange={(e) => setAddress(e.target.value)}
                                    placeholder="Enter Address" />
                                {addressError && (
                                    <span className="field-error">Enter Address to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="gender">Gender<span style={{ color: 'red' }}>*</span></Label>
                                <Input type="select" name="select" id="exampgenderleSelect" defaultValue={gender} onChange={(e) => setGender(e.target.value)}>
                                    <option value="">Select Gender</option>
                                    <option value="1" selected={gender == '1' ? true : false}>Male</option>
                                    <option value="2" selected={gender == '2' ? true : false}>Female</option>
                                    <option value="3" selected={gender == '3' ? true : false}>Other</option>
                                </Input>
                                {genderError && (
                                    <span className="field-error">Select Gender to Continue</span>
                                )}
                            </FormGroup>
                            {console.log(profession, 'profession')}
                            <FormGroup>
                                <Label for="profession">Profession<span style={{ color: 'red' }}>*</span></Label>
                                {/* <Input type="select" name="profession" id="profession" defaultValue={profession} onChange={(e) => setProfession(e.target.value)}>
                                    <option value="">Select Profession</option>
                                    {professionList && professionList.map((item, index) => (
                                        <option value={item?.id} selected={profession == item?.id ? true : false}>{item?.name}</option>
                                    ))}
                                </Input> */}
                                <SelectSearch search options={professionOptions} value={profession} name={"profession"} placeholder="Select Profession" onChange={(e) => setProfession(e)} />
                                {professionError && (
                                    <span className="field-error">Select Profession to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="nic">NIC<span style={{ color: 'red' }}>*</span></Label>
                                <Input type="number" name="nic" id="nic" defaultValue={nic} onChange={(e) => handleChangeCNIC(e.target.value)}
                                    placeholder="Enter NIC" />
                                <p className="cdi-label-description mb-0">NIC Format: 35202********</p>
                                {nicError && (
                                    <span className="field-error">Enter  NIC to Continue</span>
                                )}
                            </FormGroup>
                            <Row className="justify-content-center">
                                <Col md="4" >
                                    <FormGroup>
                                        <Label for="weight">Weight in kg<span style={{ color: 'red' }}>*</span></Label>
                                        <Input type="number" name="weight" id="weight" defaultValue={weight} onChange={(e) => setWeight(e.target.value)}
                                            placeholder="Enter Weight in kg" />
                                        {weightError && (
                                            <span className="field-error">Enter Weight to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md="4" >
                                    <FormGroup>
                                        <Label for="height">Height in Feet<span style={{ color: 'red' }}>*</span></Label>
                                        <Input type="number" name="height" id="height" defaultValue={height} onChange={(e) => setHeight(e.target.value)}
                                            placeholder="Enter Height in Feet E.g 5.8" />
                                        {heightError && (
                                            <span className="field-error">Enter Height to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md="4" >
                                    <FormGroup>
                                        <Label for="age">Age<span style={{ color: 'red' }}>*</span></Label>
                                        <Input type="number" name="age" id="age" defaultValue={age}
                                            onChange={(e) => setAge(e.target.value)}
                                            placeholder="Enter Age" />
                                        {ageError && (
                                            <span className="field-error">Enter Age to Continue</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md="12" >
                                    <FormGroup>
                                        <Label for="age">BMI</Label>
                                        <Input type="text" name="bmi" id="bmi" defaultValue={bmi} disabled
                                            placeholder="BMI (Auto Calculated)" />
                                        <span
                                            style={{ color: bMICategory == "Underweight" ? "red" : bMICategory == "Normal weight" ? "green" : bMICategory == "Overweight" ? "orange" : "red" }}
                                        >{bMICategory}</span>
                                    </FormGroup>
                                </Col>
                                <FormGroup>
                                    <Label for="address">Referred By</Label>
                                    <Input type="text" autoComplete="off" name="referred_by" id="referred_by" defaultValue={referredBy} onChange={(e) => setReferredBy(e.target.value)}
                                        placeholder="Enter Referred By" />
                                    {referredByError && (
                                        <span className="field-error">Enter Referred By to Continue</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="address">Reason<span style={{ color: 'red' }}>*</span></Label>
                                    <Input type="text" autoComplete="off" name="reason" id="reason" defaultValue={reason} onChange={(e) => setReason(e.target.value)}
                                        placeholder="Enter Reason" />
                                    {reasonError && (
                                        <span className="field-error">Enter Reason to Continue</span>
                                    )}
                                </FormGroup>
                                {params?.patient_id && (
                                    <Col md="12" >
                                        <FormGroup>
                                            <Label for="age">Status</Label>
                                            <Input type="select" name="status" id="status" defaultValue={patientStatus} onChange={(e) => setPatientStatus(e.target.value)}>
                                                <option value="">Select Status</option>
                                                <option value="ACTIVE" selected={patientStatus == 'ACTIVE' ? true : false}>Active</option>
                                                <option value="COMPLIANT" selected={patientStatus == 'COMPLIANT' ? true : false}>Compliant</option>
                                                <option value="NON_COMPLIANT" selected={patientStatus == 'NON_COMPLIANT' ? true : false}>Non Compliant</option>
                                                <option value="LAMA" selected={patientStatus == 'LAMA' ? true : false}>LAMA</option>
                                            </Input>

                                            {patientStatusError && (
                                                <span className="field-error">Select Status to Continue</span>
                                            )}

                                        </FormGroup>
                                    </Col>
                                )}
                                <FormGroup>
                                    <Label for="cus_created_at">Custom Created At</Label>
                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                </FormGroup>
                            </Row>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
