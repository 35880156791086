// export const apiBaseUrl: string = "ht"

import axios from "axios";
import { CancelToken, AxiosResponse } from 'axios';
import userUtil from "../../utils/UserUtils";

const TIMEOUT = 120000;


const API = axios.create({
    baseURL: _baseUrl(),
    responseType: 'json',
});
function deleteLocalStorage() {
    localStorage.clear();
}
API.interceptors.response.use(function (response) {
    if (response?.data?.success == false && response?.data?.status_code?.value == 401) {
        deleteLocalStorage();
        userUtil.setUserData(null)
        window.location.href = `/login`
    }
    
    return response;
}, function (error) {
    console.log("error", error);
    console.log(error?.toJSON(), 'response?.data?.status_code?.value')
    console.log(error?.response?.status, 'error?.response?.status')
    console.log(error?.toJSON()?.config?.url ,'error?.toJSON()?.config?.url ')
    if (401 === error?.response?.status && error?.toJSON()?.config?.url != "login") {
        localStorage.clear();
        deleteLocalStorage()
        window.location.href = `/login`

        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
});


function _baseUrl() {
    console.log(process.env.REACT_APP_API_URL, 'process.env.API_URL');
    return process.env.REACT_APP_API_URL
}

export async function processRequest(request, token) {
    const headers = { ...axios.defaults.headers, ...request.headers };
    switch (request.type) {
        case 'GET':
            console.log('API ==>', request.urlString);
            const getResponse = await API.get(request.urlString, { cancelToken: token, headers: headers, timeout: TIMEOUT });
            return getResponse;
        case 'POST':
            const postResponse = await API.post(request.urlString, request.params, { cancelToken: token, headers: headers, timeout: TIMEOUT });
            return postResponse;
        case 'PUT':
            const putResponse = await API.put(request.urlString, request.params, { cancelToken: token, headers: headers, timeout: TIMEOUT });
            return putResponse;
        case 'DELETE':
            const deleteResponse = await API.delete(request.urlString, { cancelToken: token, headers: headers, timeout: TIMEOUT });
            return deleteResponse;
    }
}