import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback, Modal, ModalBody, ModalHeader

} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitCategorizationAPI, submiTendinopathiesEditAPI, getCategorizationDetailAPI, getSuggestedTreatmentAPI, getRecentPatientCategorizationsAPI, submitSuggestedTreatmentAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const DiagnosisAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [questionValue, setQuestionValue] = useState({});
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [suggestedTreamentQuestionValue, setSuggestedTreamentQuestionValue] = useState({});
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [isShowSuggestedTreatment, setIsShowSuggestedTreatment] = useState(false);

    const [diagnosisQuestions, setDiagnosisQuestions] = useState([
        {
            question: 'Grade 1',
            key: 'grade_1',
            description: 'Tick all that apply',
            options: [
                { SCLEROSIS: { key: 'SCLEROSIS', value: 1, name: 'Sclerosis' } },
                { OLISTHESIS: { key: 'OLISTHESIS', value: 2, name: 'Olisthesis' } },
                { TRIPLE_LINE_SIGNTRIPLE_LINE_SIGN: { key: 'TRIPLE_LINE_SIGN', value: 3, name: 'Triple Line Sign' } },
                { CLINICAL_SIGNS: { key: 'CLINICAL_SIGNS', value: 4, name: 'Clinical Signs' } },
                { NO_NEUROLOGICAL_SYMPTOMS_SIGNS: { key: 'NO_NEUROLOGICAL_SYMPTOMS_SIGNS', value: 5, name: 'No Neurological Symptoms / Signs' } }
            ],
        },
        {
            question: 'Grade 2',
            key: 'grade_2',
            description: 'Tick all that apply',
            options: [
                { DEFORMITY: { key: 'DEFORMITY', value: 1, name: 'Deformity' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULITIS_ONLY: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULITIS_ONLY', value: 2, name: 'Symptoms and Signs related to radiculitis only' } },
            ],
        },
        {
            question: 'Grade 3',
            key: 'grade_3',
            description: 'Tick all that apply',
            options: [
                { REVERSIBLE_DAMAGE: { key: 'REVERSIBLE_DAMAGE', value: 1, name: 'Reversible Damage' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_SINGLE_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_SINGLE_LEVEL', value: 2, name: 'Symptoms and signs related to radiculopathy - Sensory - Single-Level' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_MULTIPLE_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_SENSORY_MULTIPLE_LEVEL', value: 3, name: 'Symptoms and signs related to radiculopathy - Sensory - Multi-Level' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_SINGLE_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_SINGLE_LEVEL', value: 4, name: 'Symptoms and signs related to radiculopathy - Motor - Single-Level' } },
                { SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_MULTI_LEVEL: { key: 'SYMPTOMS_AND_SIGNS_RELATED_TO_RADICULOPATHY_MOTOR_MULTI_LEVEL', value: 5, name: 'Symptoms and signs related to radiculopathy - Motor - Multi-Level' } },
                { MRI_MULTI_LEVEL_DISC_BULGES: { key: 'MRI_MULTI_LEVEL_DISC_BULGES', value: 6, name: 'MRI - Multi-level Disc bulges' } },
                { MRI_DISC_PROTRUSION: { key: 'MRI_DISC_PROTRUSION', value: 7, name: 'MRI - Disc Protrusion' } },
                { MRI_DISC_EXTRUSION: { key: 'MRI_DISC_EXTRUSION', value: 8, name: 'MRI - Disc Extrusion' } },
                { MODERATE_TO_SEVERE_VERTEBRAL_ROTATION: { key: 'MODERATE_TO_SEVERE_VERTEBRAL_ROTATION', value: 9, name: 'Moderate to Severe Vertebral Rotation' } },
                { GRADE_III_O_A_KNEE_HIP_SHOULDER: { key: 'GRADE_III_O_A_KNEE_HIP_SHOULDER', value: 10, name: 'Grade III O.A Knee, Hip, Shoulder' } },
            ],
        },
        {
            question: 'Grade 4',
            key: 'grade_4',
            description: 'Tick all that apply',
            options: [
                { SEVERE_DAMAGE: { key: 'SEVERE_DAMAGE', value: 1, name: 'Severe Damage' } },
                { PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_KNEE: { key: 'PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_KNEE', value: 2, name: 'Previous Surgery of Spine Large Joints - Knee' } },
                { PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_HIP: { key: 'PREVIOUS_SURGERY_OF_SPINE_LARGE_JOINTS_HIP', value: 3, name: 'Previous Surgery of Spine Large Joints - Hip' } },
                { MYELOPATHY: { key: 'MYELOPATHY', value: 4, name: 'Myelopathy' } },
                { CERVICAL_DYSTONIA: { key: 'CERVICAL_DYSTONIA', value: 5, name: 'Cervical Dystonia' } },
                { SPINE_CEREBELLAR_DISEASE: { key: 'SPINE_CEREBELLAR_DISEASE', value: 6, name: 'Spine-Cerebellar Disease' } },
                { PARKINSONS_DISEASE: { key: 'PARKINSONS_DISEASE', value: 7, name: "Parkinson's Disease" } },
                { PREVIOUS_HISTORY_OF_BRAIN_ISCHEMIA_WITH_HEMIPARESIS_HEMIPLEGIA: { key: 'PREVIOUS_HISTORY_OF_BRAIN_ISCHEMIA_WITH_HEMIPARESIS_HEMIPLEGIA', value: 8, name: 'Previous History of Brain Ischemia with Hemiparesis/Hemiplegia' } },
                { END_STAGE_INFLAMMATORY_DISORDERS_WITH_RIGIDITY_DEFORMITY: { key: 'END_STAGE_INFLAMMATORY_DISORDERS_WITH_RIGIDITY_DEFORMITY', value: 9, name: 'End Stage Inflammatory Disorders with rigidity / deformity' } },
                { RHEUMATOID_ARTHRITIS: { key: 'RHEUMATOID_ARTHRITIS', value: 10, name: 'Rheumatoid Arthritis' } },
                { ANKYLOSING_SPONDYLITIS: { key: 'ANKYLOSING_SPONDYLITIS', value: 11, name: 'Ankylosing Spondylitis' } },
                { DEGENERATIVE_SCOLIOSIS: { key: 'DEGENERATIVE_SCOLIOSIS', value: 12, name: 'Degenerative Scoliosis' } },
                { SEVERE_SKIN_DISEASE: { key: 'SEVERE_SKIN_DISEASE', value: 13, name: 'Severe Skin Disease' } },
                { GRADE_IV_OA_KNEE: { key: 'GRADE_IV_OA_KNEE', value: 14, name: 'Grade IV O.A Knee' } },
                { SEVERE_OA_HIP: { key: 'SEVERE_OA_HIP', value: 15, name: 'Severe O.A Hip' } },
                { SEVERE_OA_SHOULDER: { key: 'SEVERE_OA_SHOULDER', value: 16, name: 'Severe O.A Shoulder' } },
            ],
        },
    ]);
    const [suggestedTreatmentQuestions, setSuggestedTreatmentQuestions] = useState([
        {
            question: 'Patient Grade',
            key: 'patient_grade',
            description: 'Mark only one oval',
            options: [
                { GRADE_I: { key: 'GRADE_I', value: 1, name: 'Grade I' } },
                { GRADE_II: { key: 'GRADE_II', value: 2, name: 'Grade II' } },
                { GRADE_III: { key: 'GRADE_III', value: 3, name: 'Grade III' } },
                { GRADE_IV: { key: 'GRADE_IV', value: 4, name: 'Grade IV' } },
            ],
        },
        {
            question: 'Duration',
            key: 'duration',
            description: 'Mark only one oval',
            options: [
                { WEEKS_6_12: { key: 'Weeks_6_12Weeks_6_12', value: 1, name: '6-12 Weeks' } },
                { WEEKS_12_16: { key: 'WEEKS_12_16', value: 2, name: '12-16 Weeks' } },
                { WEEKS_16_24: { key: 'WEEKS_16_24', value: 3, name: '16-24 Weeks' } },
                { MORE_THAN_24_WEEKS: { key: 'MORE_THAN_24_WEEKS', value: 4, name: 'More than 24 Weeks' } },
            ],
        },

        {
            question: 'No Of Sessions',
            key: 'no_of_sessions',
            description: 'Mark only one oval',
            options: [
                { 6_12: { key: '6_12', value: 1, name: '6-12' } },
                { 12_20: { key: '12_20', value: 2, name: '12-20' } },
                { 20_24: { key: '20_24', value: 3, name: '20-24' } },
                { 24_30: { key: '24_30', value: 4, name: '24-30' } },
            ],
        },
        {
            question: 'Follow Up',
            key: 'follow_up',
            description: 'Mark only one oval',
            options: [
                { AS_PER_NEED: { key: 'AS_PER_NEED', value: 1, name: 'As Per Need' } },
                { WEEKS_24: { key: 'WEEKS_24', value: 2, name: '24 Weeks' } },
                { YEARS_2: { key: 'YEARS_2', value: 3, name: '2 Years' } },
                { LIFE_TIME: { key: 'LIFE_TIME', value: 4, name: 'Life Time' } },
            ],
        },
        {
            question: 'Physiotherapy',
            key: 'physiotherapy',
            description: 'Mark only one oval',
            options: [
                { OBSERVE_POSTURE_CORRECTIVE_EDUCATIVE_ADVICE_AS_CONVEYED: { key: 'OBSERVE_POSTURE_CORRECTIVE_EDUCATIVE_ADVICE_AS_CONVEYED', value: 1, name: 'Observe Posture Corrective educative advice as conveyed' } },
                { NOT_REQUIRED: { key: 'NOT_REQUIRED', value: 2, name: 'Not required' } },
            ],
        },
    ]);
    useEffect(() => {
        if (params?.categorization_id) {
            getDiagnosisDetail(params?.categorization_id)

        }
    }, [params?.categorization_id]);


    const getDiagnosisDetail = async (categorization_id) => {
        setIsLoader(true)
        const response = await getCategorizationDetailAPI(userUtil?.userData?.center_id, params?.patient_id, categorization_id, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let grade_1 = response?.data?.grade_1
            grade_1 = grade_1.map(Number);

            let grade_2 = response?.data?.grade_2
            grade_2 = grade_2.map(Number);

            let grade_3 = response?.data?.grade_3
            grade_3 = grade_3.map(Number);

            let grade_4 = response?.data?.grade_4
            grade_4 = grade_4.map(Number);

            let dataToStore = {
                grade_1: grade_1,
                grade_2: grade_2,
                grade_3: grade_3,
                grade_4: grade_4,
            }
            setQuestionValue(dataToStore)
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')

        if (params?.categorization_id) {
            handleSubmitUserEdit();
        } else {
            handleSubmitUser();
        }


    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.patient_id,
        }
        questionValue['id'] = params?.categorization_id
        questionValue['customCreatedAt'] = customCreatedAt != ''?customCreatedAt:null
        const response = await submiTendinopathiesEditAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Categorization Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/patient/dashboard/${params?.patient_id}`)
            // history.push(`/patient/categorization/list/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        questionValue['customCreatedAt'] = customCreatedAt != ''?customCreatedAt:null
        const response = await submitCategorizationAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        // setIsLoader(false)
        if (response.success) {
            toast("Categorization Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            getSuggestedTreatment()
            // setIsShowSuggestedTreatment()
            // history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            setIsLoader(false)
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getSuggestedTreatment = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const response = await getRecentPatientCategorizationsAPI(userUtil?.userData?.center_id, params?.patient_id, cancelTokenSource.token)

        if (response.success) {


            let patient_grade = response?.data?.calculated?.grade

            let duration = response?.data?.calculated?.duration

            let no_of_sessions = response?.data?.calculated?.sessions

            let follow_up = response?.data?.calculated?.followUp


            let dataToStore = {
                patient_grade: patient_grade,
                duration: duration,
                no_of_sessions: no_of_sessions,
                follow_up: follow_up,
            }
            setSuggestedTreamentQuestionValue(dataToStore)
            setTimeout(function () {
                setIsLoader(false)
                setIsShowSuggestedTreatment(true)
            }, 1000)
        }
    }
    const handleClick = (e, data, id = null) => {
        console.log(data.key, 'data.key')
        console.log(id, 'id')
        var selectedValues = [];

        // const checkbox = document.getElementById(data.key + "_" + id);
        // const checkbox = document.querySelector('input[name='+data?.key+']');
        // console.log(checkbox, 'checkbox')
        // if (checkbox) {
        //     checkbox.checked = true;
        //     console.log(checkbox.checked, 'checkbox')
        // } else {
        //     console.error('Checkbox not found');
        // }

        var checkboxes = document.getElementsByName(data?.key);

        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                selectedValues.push(checkboxes[i].value);
            }
        }
        questionValue[data?.key] = selectedValues
        setQuestionValue(questionValue)
        console.log(questionValue, 'selectedValues selectedValues')
    }
    const checkIsSelected = (key, value) => {
        console.log('checkIsSelectedcheckIsSelected', 'key=', key, 'value=', value)
        let isChecked = false;
        let SelectedValues = questionValue[key]
        if (SelectedValues) {
            SelectedValues = SelectedValues.map(Number)
            if (SelectedValues) {
                for (let s of SelectedValues) {
                    if (s == value) {
                        return true
                        isChecked = true
                    }
                }
            }
        }
    }
    const handleUpdateSuggestedPlan = (e) => {
        history.push(`/patient/suggested-treatment/add/${params?.patient_id}`)
    }
    const handleSaveSuggestedTreatment = (e) => {
        handleSubmitSuggestedTreament()
    }

    const handleSubmitSuggestedTreament = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)

        const response = await submitSuggestedTreatmentAPI(userUtil?.userData?.center_id, params?.patient_id, suggestedTreamentQuestionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Suggested Treatment Plan Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/suggested-treatment/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleClickSuggestedTreatment = (e, data, id = null) => {
        console.log(data.key, 'data.key')
        console.log(id, 'id')
        suggestedTreamentQuestionValue[data?.key] = e.target.value
        setSuggestedTreamentQuestionValue(suggestedTreamentQuestionValue)
        console.log(suggestedTreamentQuestionValue, 'selectedValues selectedValues')
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Categorization", url: `/patient/categorization/list/${params?.patient_id}` },
                        { label: params?.categorization_id ? "Edit Categorization" : "Add Categorization" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.categorization_id ? "Edit" : "Add"}{" "} Categorization</CardTitle>
                            <Row>

                                {diagnosisQuestions && diagnosisQuestions.map((item, index) => {
                                    return (
                                        <Col md="6" >
                                            {!params?.categorization_id ?
                                                <>
                                                    <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                        {console.log(questionValue, 'questionValue')}
                                                        {/* <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                        <p className="cdi-label-description">{item?.description}</p> */}
                                                        {item?.options && Object.keys(item?.options).map(itemOption => {
                                                            let key = Object.keys(item?.options[itemOption])
                                                            console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                            let optionItem = item?.options[itemOption][key]
                                                            let optionValue = item?.options[itemOption][key]?.value
                                                            let optionName = item?.options[itemOption][key]?.name
                                                            let optionKey = item?.options[itemOption][key]?.key

                                                            console.log(questionValue, 'questionValue')

                                                            // let isChecked = false;

                                                            // let SelectedValues = questionValue[item?.key]
                                                            // if (SelectedValues) {
                                                            //     SelectedValues = SelectedValues.map(Number)
                                                            //     if (SelectedValues) {
                                                            //         for (let s of SelectedValues) {
                                                            //             if (s == optionValue) {
                                                            //                 isChecked = true
                                                            //             }
                                                            //         }
                                                            //     }
                                                            // }
                                                            const isChecked = questionValue[item?.key]?.includes(optionValue);
                                                            if (item?.key == 'grade_1' && optionValue == '1') {
                                                                console.log(isChecked, 'isChecked isChecked isChecked isChecked')
                                                            }
                                                            // Define the style based on whether the checkbox is checked or not
                                                            const labelStyle = {
                                                                color: isChecked ? 'green' : 'black',
                                                            };
                                                            console.log(labelStyle, 'labelStyle')
                                                            return (
                                                                <FormGroup check style={{ marginLeft: '30px' }}>
                                                                    <Label check style={labelStyle}>
                                                                        <Input name={item?.key} defaultValue={optionValue} type={"checkbox"}
                                                                            onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                        />
                                                                        {optionName}
                                                                    </Label>
                                                                </FormGroup>
                                                            )
                                                        })}

                                                    </FormGroup>

                                                </>
                                                :
                                                <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>

                                                    <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                    {item?.options && Object.keys(item?.options).map(itemOption => {
                                                        let key = Object.keys(item?.options[itemOption])
                                                        console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                        let optionItem = item?.options[itemOption][key]
                                                        let optionValue = item?.options[itemOption][key]?.value
                                                        let optionName = item?.options[itemOption][key]?.name
                                                        let optionKey = item?.options[itemOption][key]?.key

                                                        console.log(questionValue, 'questionValue')

                                                        let isChecked = false;

                                                        let SelectedValues = questionValue[item?.key]
                                                        if (SelectedValues) {
                                                            SelectedValues = SelectedValues.map(Number)
                                                            if (SelectedValues) {
                                                                for (let s of SelectedValues) {
                                                                    if (s == optionValue) {
                                                                        isChecked = true
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        console.log(isChecked, 'isChecked isChecked isChecked isChecked')
                                                        return (
                                                            <FormGroup check style={{ marginLeft: '30px' }}>
                                                                <Label check>
                                                                    <Input name={item?.key} id={item?.key + "_" + optionValue} checked={isChecked} defaultValue={optionValue} type={"checkbox"}
                                                                        onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                    />
                                                                    {optionName}
                                                                </Label>
                                                            </FormGroup>
                                                        )
                                                    })}

                                                </FormGroup>
                                            }
                                        </Col>
                                    )
                                })}
                                <FormGroup>
                                    <Label for="cus_created_at">Custom Created At</Label>
                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                </FormGroup>
                            </Row>

                            {!params?.categorization_id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </CardBody>

                    </Card>
                </Col>
            </Row>
            <Modal isOpen={isShowSuggestedTreatment} toggle={(e) => { setIsShowSuggestedTreatment(!isShowSuggestedTreatment) }} style={{ minWidth: '40%' }}>
                <ModalHeader toggle={(e) => { setIsShowSuggestedTreatment(!isShowSuggestedTreatment) }} charCode="Y">Suggested Treatment</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <h5 className="mt-4" style={{ textAlign: 'center' }}>Here is your Suggested Treatment</h5>
                        <div className="row">
                            {suggestedTreatmentQuestions && suggestedTreatmentQuestions.map((item, index) => {
                                return (
                                    <Col md="6" >
                                        <>
                                            <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                {console.log(suggestedTreamentQuestionValue, 'suggestedTreamentQuestionValue')}
                                                <legend className="col-form-label">{parseInt(index + 1)}{". "}{item?.question}</legend>
                                                {item?.options && Object.keys(item?.options).map(itemOption => {
                                                    let key = Object.keys(item?.options[itemOption])
                                                    console.log(item?.options[itemOption], 'item?.options[itemOption]')
                                                    let optionItem = item?.options[itemOption][key]
                                                    let optionValue = item?.options[itemOption][key]?.value
                                                    let optionName = item?.options[itemOption][key]?.name
                                                    let optionKey = item?.options[itemOption][key]?.key

                                                    let isChecked = false;

                                                    let SelectedValues = suggestedTreamentQuestionValue[item?.key]
                                                    console.log(SelectedValues, 'SelectedValues')
                                                    console.log(optionValue, 'optionValue')
                                                    if (SelectedValues == optionValue) {
                                                        console.log(optionValue, '-==-', item?.question, '-==-', optionName, '-==-', 'optionValue selected')
                                                        isChecked = true
                                                    }
                                                    return (
                                                        <FormGroup check style={{ marginLeft: '30px' }}>
                                                            <Label check >
                                                                {item?.key == 'physiotherapy' ?
                                                                    <Input name={item?.key} defaultValue={optionValue} type={"radio"}
                                                                        defaultChecked={isChecked}
                                                                        onChange={(e) => { handleClickSuggestedTreatment(e, item, optionValue) }}
                                                                    />
                                                                    :
                                                                    <Input name={item?.key} defaultValue={optionValue} type={"radio"}
                                                                        onChange={(e) => { handleClickSuggestedTreatment(e, item, optionValue) }}
                                                                        defaultChecked={isChecked}
                                                                    />
                                                                }
                                                                {optionName}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })}

                                            </FormGroup>

                                        </>

                                    </Col>
                                )
                            })}

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* <button className="btn btn-primary mt-4" onClick={(e) => { handleUpdateSuggestedPlan(e) }}>Update Suggested Treatment</button> */}
                            <button className="btn btn-primary mt-4" onClick={(e) => { handleSaveSuggestedTreatment(e) }}>Save And Continue</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default DiagnosisAdd;
