import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { VerifyTokenAPI, resetPasswordAPI } from './../../api/network/CommonAPI';
import axios, { CancelTokenSource } from "axios";
import { useHistory, useParams } from 'react-router-dom';
import Loader from "../../api/helperComponents/Loader";
import userUtil from "../../utils/UserUtils";
import { toast, Bounce } from 'react-toastify';
import { Link } from 'react-router-dom';
function LoginScreen() {
    const cancelTokenSource = axios.CancelToken.source();
    const history = useHistory();
    const params = useParams()
    const [password, setPassword] = useState("");
    const [cPassword, setCPassword] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [helperTextLoader, setHelperTextLoader] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [confirmPassError, setConfirmPassError] = useState(false);
    console.log(params?.token,'params?.token ')
    useEffect(() => {
       
        if (params?.token) {
            
            handleVerifyToken(params?.token)
        }
    }, [params?.token]);
    const handleForgot = async () => {
        if(password != cPassword){
            setConfirmPassError(true)
            return
        }
        setIsLoader(true)
        let dataToSend = {
            newPassword: password,
            token: params?.token
        }
        const response = await resetPasswordAPI(dataToSend);
        if (response?.success) {
            toast("Password Reset Successfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            setIsLoader(false)
            setTimeout(()=>{
                history.push('/login')
            },200)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            setIsLoader(false)
        }
    }
    const handleVerifyToken = async () => {
        setIsLoader(true)
        let dataToSend = {
            token: params?.token
        }
        const response = await VerifyTokenAPI(dataToSend);
        console.log(response, 'response')
        if (response?.success) {
            setIsLoader(false)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            setIsLoader(false)
        }
    }
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleCPasswordVisibility = () => {
        setShowCPassword(!showCPassword);
    };
    return (
        <div className="login-parent-container">
            {isLoader && (<Loader helperText={helperTextLoader} />)}
            <h3 style={{ color: '#0681ee' }}>OrthoSuite</h3>
            <div className="login-container">
                <h2 className="mb-4 text-center">Reset Password</h2>

                
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <div style={{ position: 'relative' }}>
                        <input  type={showPassword ? "text" : "password"} className="form-control" id="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} />
                        <div
                            onClick={togglePasswordVisibility}
                            style={{ position: 'absolute', right: '5px', top: '8px' }}
                        >
                            {showPassword ?
                                <i
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                    className="fa fa-eye"
                                /> : <i
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                    className="fa fa-eye-slash"
                                ></i>}

                        </div>
                    </div>
                    
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Confirm Password</label>
                    <div style={{ position: 'relative' }}>
                        <input  type={showCPassword ? "text" : "password"} className="form-control" id="password" placeholder="Enter Confirm password" onChange={(e) => setCPassword(e.target.value)} />
                        <div
                            onClick={toggleCPasswordVisibility}
                            style={{ position: 'absolute', right: '5px', top: '8px' }}
                        >
                            {showCPassword ?
                                <i
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                    className="fa fa-eye"
                                /> : <i
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                    className="fa fa-eye-slash"
                                ></i>}

                        </div>
                    </div>
                    {confirmPassError && <p style={{color:'red'}}>Confirm Password Not Matched</p>}
                    
                </div>
                <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                        <Link to='/login'>Login</Link>
                    </div>
                <button onClick={() => handleForgot()} className="btn btn-primary w-100">{isLoader ? <i className='fa fa-spinner'></i> : 'Login'}</button>

            </div>
        </div>
    );
}


export default LoginScreen;
