import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitCDIFormAPI, getSpecificCDIAPI, updateCDIFormAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const CdiAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [finalPayloadData, setFinalPayloadData] = useState({
        "c2_c3_olisthesis_lateral": null,
        "c2_c3_olisthesis_flexion": null,
        "c2_c3_olisthesis_extension": null,
        "c2_c3_olisthesis_anterior": null,
        "c2_c3_olisthesis_posterior": null,
        "c2_c3_disc_space_narrowing": null,

        "c3_c4_olisthesis_lateral": null,
        "c3_c4_olisthesis_flexion": null,
        "c3_c4_olisthesis_extension": null,
        "c3_c4_olisthesis_anterior": null,
        "c3_c4_olisthesis_posterior": null,
        "c3_c4_disc_space_narrowing": null,

        "c4_c5_olisthesis_lateral": null,
        "c4_c5_olisthesis_flexion": null,
        "c4_c5_olisthesis_extension": null,
        "c4_c5_olisthesis_anterior": null,
        "c4_c5_olisthesis_posterior": null,
        "c4_c5_disc_space_narrowing": null,

        "c5_c6_olisthesis_lateral": null,
        "c5_c6_olisthesis_flexion": null,
        "c5_c6_olisthesis_extension": null,
        "c5_c6_olisthesis_anterior": null,
        "c5_c6_olisthesis_posterior": null,
        "c5_c6_disc_space_narrowing": null,

        "c6_c7_olisthesis_lateral": null,
        "c6_c7_olisthesis_flexion": null,
        "c6_c7_olisthesis_extension": null,
        "c6_c7_olisthesis_anterior": null,
        "c6_c7_olisthesis_posterior": null,
        "c6_c7_disc_space_narrowing": null
    });

    const [questions, setQuestions] = useState([
        { name: 'C2-C3', description: 'Mark only one oval per row', key: 'c2_c3' },
        { name: 'C3-C4', description: 'Mark only one oval per row', key: 'c3_c4' },
        { name: 'C4-C5', description: 'Mark only one oval per row', key: 'c4_c5' },
        { name: 'C5-C6', description: 'Mark only one oval per row', key: 'c5_c6' },
        { name: 'C6-C7', description: 'Mark only one oval per row', key: 'c6_c7' },
    ])
    const [optionsCdi, setOptionsCdi] = useState(
        [
            { name: 'Olisthesis (Lateral)', key: 'olisthesis_lateral' },
            { name: 'Olisthesis (Flexion)', key: 'olisthesis_flexion' },
            { name: 'Olisthesis (Extension)', key: 'olisthesis_extension' },
            { name: 'Osteophytes (Anterior)', key: 'olisthesis_anterior' },
            { name: 'Osteophytes (Posterior)', key: 'olisthesis_posterior' },
            { name: 'Disk Space Narrowing', key: 'disc_space_narrowing' },
        ]
    );
    useEffect(() => {
        // getProfessions()
        if (params?.cdi_id) {
            getPatientCDIDetail(params?.cdi_id)

        }
    }, [params?.cdi_id]);
    let patientId = params?.patient_id


    const getPatientCDIDetail = async (cdiId) => {
        const response = await getSpecificCDIAPI(centerId, patientId, cdiId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
            let data = response?.data
            delete data['created_at']
            delete data['created_by']
            delete data['patient_id']
            delete data['updated_at']
            setFinalPayloadData(data)

            console.log(data, 'data')
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }


    const handleClick = (e, key) => {
        console.log(finalPayloadData['key'], 'finalPayloadData')
        finalPayloadData[key] = e.target.value
        console.log(finalPayloadData, 'finalPayloadData')
        setFinalPayloadData(finalPayloadData)
        console.log(finalPayloadData, 'finalPayloadData')
    }
    const handleSubmit = async (e) => {
        if (validData()) {
            toast("Select all required options!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return;
        }
        finalPayloadData['customCreatedAt'] = customCreatedAt != '' ? customCreatedAt : null
        if (!params?.cdi_id) {
            setIsLoader(true)
            const response = await submitCDIFormAPI(centerId, patientId, finalPayloadData, cancelTokenSource.token)
            setIsLoader(false)
            if (response.success) {
                toast("Data Added Successfully!", {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'success'
                });
                history.push(`/patient/dashboard/${params?.patient_id}`)
                // history.push(`/patient/cdis/list/${params?.patient_id}`)
            } else {
                toast(response?.system_message, {
                    transition: Bounce,
                    closeButton: true,
                    autoClose: 5000,
                    position: 'bottom-center',
                    type: 'error'
                });
            }
        } else {
            updateCDiForm()
        }
    }
    const updateCDiForm = async (e) => {
        if (validData()) {
            toast("Select all required options!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return;
        }
        setIsLoader(true)
        const response = await updateCDIFormAPI(centerId, patientId, params?.cdi_id, finalPayloadData, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Data Added Successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push(`/patient/dashboard/${params?.patient_id}`)
            // history.push(`/patient/cdis/list/${params?.patient_id}`)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }

    }

    const validData = () => {
        for (const key in finalPayloadData) {
            if (finalPayloadData.hasOwnProperty(key) && finalPayloadData[key] === null) {
                return false
            }
        }
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "CDI Factor Scoring", url: `/patient/cdis/list/${params?.patient_id}` },
                        { label: params?.cdi_id ? "Edit CDI" : "Add CDI" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.cdi_id ? "Edit" : "Add"}{" "} CDI Factor Scoring</CardTitle>

                            {/* <FormGroup>
                                <Label for="f_name">Name</Label>
                                <Input type="text" name="f_name" id="f_name" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)}
                                    placeholder="Enter Name" />
                            </FormGroup> */}
                            {questions && questions.map((item, index) => (
                                <FormGroup key={index} style={{ padding: '20px', border: '1px solid silver', borderRadius: '5px' }}>
                                    <div >
                                        <Label for="f_name"><span style={{ marginRight: '5px' }}>{parseInt(index + 1)}.</span>{item?.name}</Label>
                                        <p className="cdi-label-description">{item?.description}</p>
                                    </div>
                                    <div class="table-responsive">
                                        <Table className="mb-0" >
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>0</th>
                                                    <th>1</th>
                                                    <th>2</th>
                                                    <th>3</th>
                                                </tr>
                                            </thead>
                                            {!params?.cdi_id ? (
                                                <tbody>
                                                    {optionsCdi && optionsCdi.map((itemOption, indexOption) => (
                                                        <tr key={indexOption}>
                                                            <td>{itemOption?.name}</td>
                                                            <td>
                                                                {console.log(finalPayloadData[item?.key + "_" + itemOption?.key], 'finalPayloadData[item?.key + "_" + itemOption?.key]')}
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input name={item?.key + "_" + itemOption?.key} type={'radio'}
                                                                            defaultChecked={finalPayloadData[item?.key + "_" + itemOption?.key] == 0 ? true : false}
                                                                            value={0} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                        {" "}
                                                                    </Label>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input name={item?.key + "_" + itemOption?.key} type={'radio'} defaultChecked={finalPayloadData[item?.key + "_" + itemOption?.key] == 1 ? true : false} value={1} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                        {" "}
                                                                    </Label>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input name={item?.key + "_" + itemOption?.key} type={'radio'} defaultChecked={finalPayloadData[item?.key + "_" + itemOption?.key] == 2 ? true : false} value={2} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                        {" "}
                                                                    </Label>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup check>
                                                                    <Label check>
                                                                        <Input name={item?.key + "_" + itemOption?.key} type={'radio'} defaultChecked={finalPayloadData[item?.key + "_" + itemOption?.key] == 3 ? true : false} value={3} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                        {" "}
                                                                    </Label>
                                                                </FormGroup>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            ) :
                                                (
                                                    <tbody>
                                                        {optionsCdi && optionsCdi.map((itemOption, indexOption) => (
                                                            <tr key={indexOption}>
                                                                <td>{itemOption?.name}</td>
                                                                <td>
                                                                    {console.log(finalPayloadData[item?.key + "_" + itemOption?.key], 'finalPayloadData[item?.key + "_" + itemOption?.key]')}
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input name={item?.key + "_" + itemOption?.key} type={'radio'}
                                                                                checked={finalPayloadData[item?.key + "_" + itemOption?.key] == 0 ? true : false}
                                                                                value={0} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input name={item?.key + "_" + itemOption?.key} type={'radio'} checked={finalPayloadData[item?.key + "_" + itemOption?.key] == 1 ? true : false} value={1} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input name={item?.key + "_" + itemOption?.key} type={'radio'} checked={finalPayloadData[item?.key + "_" + itemOption?.key] == 2 ? true : false} value={2} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </td>
                                                                <td>
                                                                    <FormGroup check>
                                                                        <Label check>
                                                                            <Input name={item?.key + "_" + itemOption?.key} type={'radio'} checked={finalPayloadData[item?.key + "_" + itemOption?.key] == 3 ? true : false} value={3} onChange={(e) => { handleClick(e, item?.key + "_" + itemOption?.key) }} />
                                                                            {" "}
                                                                        </Label>
                                                                    </FormGroup>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                )}
                                        </Table>
                                    </div>
                                </FormGroup>
                            ))}
                            <FormGroup>
                                <Label for="cus_created_at">Custom Created At</Label>
                                <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                            </FormGroup>


                            {!params?.cdi_id && (
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            )}
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CdiAdd;
