import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitCenterAPI, getCenterDetailByIDAPI, submitCenterEditAPI } from './../../../../api/network/Api';
import PageTitle from '../../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../../api/helperComponents/Loader';
import BreadCrumb from '../../../../api/helperComponents/BreadCrumb';
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [name, setName] = useState("");
    const [location, setLocation] = useState("");
    const [status, setStatus] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [centerInitials, setCenterInitials] = useState("");
    const [centerRegistrationCode, setCenterRegistrationCode] = useState("");
    const [treatmentsBought, setTreatmentsBought] = useState("");
    const [nameError, setNameError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [contactNumberError, setContactNumberError] = useState(false);
    const [treatmentsBoughtError, setTreatmentsBoughtError] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [centerInitialsError, setCenterInitialsError] = useState(false);
    const [centerRegistrationCodeError, setCenterRegistrationCodeError] = useState(false);
    const [isLoader, setIsLoader] = useState(false);


    useEffect(() => {
        if (params?.center_id) {
            getCenterDetail(params?.center_id)
        }
    }, [params?.center_id]);

    const getCenterDetail = async (centerID) => {
        setIsLoader(true)
        const response = await getCenterDetailByIDAPI(centerID, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setName(response?.data?.name)
            setLocation(response?.data?.location)
            setContactNumber(response?.data?.contact_number)
            setTreatmentsBought(response?.data?.treatments_bought)
            setStatus(response?.data?.status)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        console.log(name, 'name')
        if (name == '' || contactNumber == '' || location == '') {
            if (name == '') {
                setNameError(true)
            } else {
                setNameError(false)
            }
            if (contactNumber == '') {
                setContactNumberError(true)
            } else {
                setContactNumberError(false)
            }
            if (location == '') {
                setLocationError(true)
            } else {
                setLocationError(false)
            }
            if (centerInitials == '') {
                setCenterInitialsError(true)
            } else {
                setCenterInitialsError(false)
            }
            if (centerRegistrationCode == '') {
                setCenterRegistrationCodeError(true)
            } else {
                setCenterRegistrationCodeError(false)
            }
            // if (treatmentsBought == '') {
            //     setTreatmentsBoughtError(true)
            // } else {
            //     setTreatmentsBoughtError(false)
            // }

        } else {
            if (params?.center_id) {
                if (status == '') {
                    setStatusError(true)
                    return;
                } else {
                    setStatusError(false)
                }
            }
            const inputValue = contactNumber;
            const regex = /^(?:\+92|0)[0-9]{10}$/;
            if (!regex.test(inputValue)) {
                setContactNumberError(true);
                return;
            }
            if (params?.center_id) {
                handleSubmitCenterEdit();
            } else {
                handleSubmitCenter();
            }

        }
    }
    const handleSubmitCenterEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            name: name,
            contact: contactNumber,
            location: location,
            // treatments_bought: treatmentsBought,
            status: status,
            center_initials: centerInitials,
            center_reg_code: centerRegistrationCode,
        }
        const response = await submitCenterEditAPI(params?.center_id, dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Center Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/centers')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitCenter = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            name: name,
            contact: contactNumber,
            location: location,
            // treatments_bought: treatmentsBought,
        }
        const response = await submitCenterAPI(dataToSend, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Center Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            history.push('/centers')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleChangeContactNumber = (value) => {
        console.log(value, 'value')
        const inputValue = value;
        const regex = /^(?:\+92|0)[0-9]{10}$/;

        if (regex.test(inputValue)) {
            console.log(inputValue, 'inputValue')
            setContactNumberError(false);
            setContactNumber(inputValue);
        } else {
            setContactNumber(inputValue);
            setContactNumberError(true);
        }
    };
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Centers", url: "/centers" },
                        { label: params?.center_id ? "Edit Center" : "Add Center" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.center_id ? "Edit" : "Add"}{" "}Center</CardTitle>

                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Input type="text" name="name" id="name" defaultValue={name} onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter Center Name" />
                                {console.log(nameError, 'nameError')}
                                {nameError && (
                                    <span className="field-error">Enter Name to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">Center Initials</Label>
                                <Input type="text" name="center_initials" id="center_initials" defaultValue={centerInitials} onChange={(e) => setCenterInitials(e.target.value)}
                                    placeholder="Enter Center Initials" />
                                {centerInitialsError && (
                                    <span className="field-error">Enter Center Initials to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">Center Registration Code</Label>
                                <Input type="text" name="center_reg_code" id="center_reg_code" defaultValue={centerRegistrationCode} onChange={(e) => setCenterRegistrationCode(e.target.value)}
                                    placeholder="Enter Registration Code" />
                                {centerRegistrationCodeError && (
                                    <span className="field-error">Enter Registration Code to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="contactNumber">Contact Number</Label>
                                <Input type="number" name="contactNumber" id="contactNumber" defaultValue={contactNumber} onChange={(e) => handleChangeContactNumber(e.target.value)}
                                    placeholder="Enter Contact Number" />
                                <p className="cdi-label-description">Contact No. Format: 0300*******</p>
                                {contactNumberError && (
                                    <span className="field-error">Enter Contact Number to Continue</span>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="location">Location</Label>
                                <Input type="text" name="location" id="location" defaultValue={location} onChange={(e) => setLocation(e.target.value)}
                                    placeholder="Enter Location" />
                                {locationError && (
                                    <span className="field-error">Enter Location to Continue</span>
                                )}
                            </FormGroup>
                            {/* <FormGroup>
                                <Label for="treatments_bought">Treatments Bought</Label>
                                <Input type="number" name="treatments_bought" id="treatments_bought" defaultValue={treatmentsBought} onChange={(e) => setTreatmentsBought(e.target.value)}
                                    placeholder="Enter Treatments Bought" />
                                {treatmentsBoughtError && (
                                    <span className="field-error">Enter Treatments Bought to Continue</span>
                                )}
                            </FormGroup> */}
                            {params?.center_id && (
                                <FormGroup>
                                    <Label for="status">Status</Label>
                                    <Input type="select" name="status" id="status" defaultValue={status} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="">Select Status</option>
                                        <option value={true} selected={status === true ? true : false}>Active</option>
                                        <option value={false} selected={status === false ? true : false}>In Active</option>

                                    </Input>
                                    {statusError && (
                                        <span className="field-error">Select Status to Continue</span>
                                    )}
                                </FormGroup>
                            )}
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                            </div>

                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
