export const QuestionList = [
    {
        sort: 1,
        question: 'Cranio Cervical Rotation Type',
        key: 'cranio_cervical_rotation_type',
        description: '',
        type: 'single-select',
        options: ['AN OL', 'AN OR', 'AN ON', 'AR OR', 'AR OL', 'AR ON', 'AL OL', 'AL OR', 'AL ON', 'A0 OL', 'A0 OR', 'A0 ON','Other','None'],
    },
    {
        sort: 2,
        question: 'Heterotrophic Calcific Area in ligamentum nuchae',
        key: 'heterotrophic_calcific_area_ligamentum_nuchae',
        description: '',
        type: 'single-select',
        options: ['Yes', 'No'],
    },
    {
        sort: 3,
        question: 'Clinical Presentation',
        key: 'clinical_presentation',
        description: '',
        type: 'multi-select',
        options: ['CS - Cervical Spondylosis Radiculopathy (CSR)', 'Cervical Spondylotic Myelopathy (CSM)', 'Vertebral Artery Type CS (VACS)', 'Sympathetic Cervical Spondylosis', 'Positive Past History Presently Asymptomatic','Other','None'],
    },
    {
        sort: 4,
        question: 'Pain Type',
        key: 'pain_type',
        description: '',
        type: 'single-select',
        options: ['Acute', 'Recurrent', 'Chronic', 'Acute on Chronic','None'],
    },
    {
        sort: 5,
        question: 'Pain Character Type',
        key: 'pain_character_type',
        description: '',
        type: 'multi-select',
        options: ['Dull', 'Cramps', 'Piercing', 'Cutting', 'Throbbing', 'Radiation', 'Burning','Other','None'],
    },
    {
        sort: 6,
        question: 'Aggravate',
        key: 'aggravate',
        description: '',
        type: 'multi-select',
        options: ['Activity', 'Rest', 'Standing', 'Sitting','Other','None'],
    },
    {
        sort: 7,
        question: 'Relieve',
        key: 'relieve',
        description: '',
        type: 'multi-select',
        options: ['Exercise', 'Massage', 'Physiotherapy', 'Drugs', 'Activity', 'Rest','Other','None'],
    },
    {
        sort: 8,
        question: 'Mode of Injuries',
        key: 'mode_of_injuries',
        description: '',
        type: 'multi-select',
        options: ['RTA', 'Fall', 'Surgery Under G/A', 'Dental Procedures', 'Bad Postures', 'Bedroom TV', 'Use of Mobile on Bed', 'Congenital','Other','None'],
    },
    {
        sort: 9,
        question: 'Associated Diseases',
        key: 'associated_diseases',
        description: '',
        type: 'multi-select',
        options: ['HT - HyperTension', 'IHD - Ischemic Heart Disease', 'Diabetes Mellitus Type 1', 'Diabetes Mellitus Type 2', 'Asthma', 'Hypothyroidism', 'Hyperthyroidism', 'Osteoporosis', 'Arthroscopy','Other','None'],
    },
    {
        sort: 10,
        question: 'Previous Surgeries',
        key: 'previous_surgeries',
        description: '',
        type: 'multi-select',
        options: ['Fracture Fixation', 'Spine', 'Joint Replacement', 'Left Knee Surgery', 'Right Knee Surgery', 'Left Hip Surgery', 'Right Hip Surgery',
            'Arthroscopy', 'Hysterectomy', 'C-Section', 'Myomectomy (Excision Fibroids)', 'Laparotomy',
            'Cholecystectomy', 'Hemorrhoidectomy', 'Appendectomy', 'Prostatectomy', 'Mastectomy', 'Tonsillectomy','Other','None'],
        // conditions: [{
        //     "Fracture Fixation": {
        //         options: {
        //             "bone": {
        //                 key: 'previous_surgeries_fracture_fixation_bone',
        //                 type: 'text',
        //             },
        //             "Area of Bone": {
        //                 key: 'previous_surgeries_fracture_fixation_area_of_bone',
        //                 type: 'text',
        //             },
        //             "Type of Fixation": {
        //                 key: 'previous_surgeries_fracture_fixation_type_of_fixation',
        //                 type: 'text',
        //             },
        //         }
        //     }
        // }]
    },
    {
        sort: 11,
        question: 'Systemic Diseases',
        key: 'system_diseases',
        description: '',
        type: 'multi-select',
        options: ['Ischemic Heart Disease', 'Hypothyroidism', 'Diabetes Myelitis Type I', 'Diabetes Myelitis Type II', 'Hypertension', 'Depretion', 'Anxiety', 'PCO', 'Asthma','Other', 'None'],
    },
    {
        sort: 12,
        question: 'Autonomic Dysfunction',
        key: 'autonomic_dysfunction',
        description: '',
        type: 'multi-select',
        options: [
            'Headache',
            'Dizziness',
            'Polyurea',
            'Nocturia',
            'Hyperactivity',
            'Anger',
            'Depression',
            'Dilated Pupil',
            'Blurred Vision',
            'Ptosis',
            'Constricted Pupil',
            'Watering from Eyes',
            'Dryness of Eyes',
            'Dryness of Mouth',
            'Increased Saliva',
            'Parotid Swelling',
            'Anxiety',
            'Inhibit Heart Activity / Dilate Arteries',
            'Drop Attacks',
            'Dilate Bronchi',
            'Improve Respiratory Efforts',
            'Decrease Rate of Respiratory Infection',
            'Allergic Episodes',
            'Improve Sleep Apnoea',
            'Improve Snoring',
            'Constrict Bronchi',
            'Dyspnoea / Breathing Difficulty',
            'Stimulate stomach motility and secretions',
            'Inhibit Stomach Mobility & Secretions',
            'Full Abdomen Indigestion',
            'Flatulence',
            'Poor Diabetic Control',
            'Inhibit Pancreas',
            'Relaxes Bladder',
            'Dysmanorrhoea',
            'Painful Menstrual Cycle',
            'Amenorrhoea / Sterility',
            'Absent Menstrual Bleed',
            'Menorrhagia',
            'Increases Menstrual Bleeding',
            'Oligomenorrhea',
            'Decrease Menstrual Bleed',
            'Recurrent Abortions',
            'Stimulate Ejaculation',
            'Premature Ejaculation',
            'Stimulate Erection',
            'Painful Priapism',
            'Insomnia',
            'Tinnitus',
            'Hand Tremors Left',
            'Hand Tremors Right',
            'Facial Tics',
            'Facial Asymmetry',
            'Menstrual Dysfunction',
            'Dyspnea',
            'Palpitations',
            'Acid Peptic Disease',
            'Constipation',
            'Irritable Bowel Syndrome (IBS)',
            'Erectile Dysfunction',
            'Renal Stones',
            'Gall Bladder Stone',
            'Dysfunctional Uterine bleeding',
            'Other',
            'None of the above'
        ],
    },
    {
        sort: 13,
        question: 'Medications',
        key: 'medication',
        description: '',
        type: 'multi-select',
        options: ['Anxiolytics', 'Sedatives', 'Anti-Depressant', 'Steroids', 'Anti-Convulsant', 'Anti-Platelet', 'Anti-Coagulant', 'Beta-Blockers', 'Calcium Antagonist', 'Muscle Relaxants', 'Analgesic NSAIDS', 'Non-Steroidal Anti Inflammatory drugs', 'Bronchodilators', 'Laxatives', 'Anti-Biotics', 'Laxatives', 'Anti-Biotics', 'Nutritional Supplemets', 'Diuretics', 'Horomes', 'Bisphophates', 'Anti-mitotic', 'Anti-Cholesterol', 'Vasodilator', 'Thiazide Diuretics', 'Opiod Analgesic', 'Anti-Rheumatic Drugs', 'Anti-Diabetic', 'ACE inhibitor','Other', 'None'],
    }
]



export const associated_diseases_arthroscopy = [
    {
        sort: 1,
        question: 'associated_diseases_arthroscopy',
        key: 'associated_diseases_arthroscopy',
        description: '',
        type: 'multi-select',
        options: [
            {
                key: 'left_knee',
                name: 'Left Knee'
            },
            {
                key: 'right_knee',
                name: 'Right Knee'
            },
            {
                key: 'left_shoulder',
                name: 'Left Shoulder'
            },
            {
                key: 'right_shoulder',
                name: 'Right Shoulder'
            },
            {
                key: 'other',
                name: 'Other'
            }
        ]
    }
]



