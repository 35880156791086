import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getCdiListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
import { isToday } from '../../../utils/isToday';
const CdiList = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientCDIList, setPatientCDIList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));

    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);
    let patientId = params?.patient_id
    useEffect(() => {

        if (centerId) {
            getCdiList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getCdiList(centerId, page, rowsPerPage)
    }, [page]);
    const getCdiList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getCdiListAPI(centerId, patientId, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientCDIList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddPatientCDIClick = (e) => {
        history.push(`/patient/cdi/add/${patientId}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/cdi/edit/${patientId}/${id}`)
    }
    function calculateSubScore(level, data) {
        console.log(data, 'data')
        console.log(data[`${level}_olisthesis_lateral`], 'level')
        const lateral = Number(data[`${level}_olisthesis_lateral`]);
        const flexion = Number(data[`${level}_olisthesis_flexion`]);
        const extension = Number(data[`${level}_olisthesis_extension`]);
        const anterior = Number(data[`${level}_olisthesis_anterior`]);
        const posterior = Number(data[`${level}_olisthesis_posterior`]);
        const discSpaceNarrowing = Number(data[`${level}_disc_space_narrowing`]);

        const maxFlexionExtension = Math.max(flexion, extension);
        const maxAnteriorPosterior = Math.max(anterior, posterior);

        return lateral + maxFlexionExtension + maxAnteriorPosterior + discSpaceNarrowing;
    }
    const getTotalCdiScore = (item) => {

        return calculateSubScore('c2_c3', item) + calculateSubScore('c3_c4', item) + calculateSubScore('c4_c5', item) + calculateSubScore('c5_c6', item) + calculateSubScore('c6_c7', item)

    }
    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "CDI Factor Scoring" },
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Cervical Degenerative Index (CDI) Factor Scoring
                        {(permissions.includes("PATIENT_MEDICAL_HISTORY_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddPatientCDIClick()}>
                                Add CDI Factor Scoring
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                    <Table striped className="mb-0">
                        <thead>
                            <tr>
                                <th>SubScore C2-C3</th>
                                <th>SubScore C3-C4</th>
                                <th>SubScore C4-C5</th>
                                <th>SubScore C5-C6</th>
                                <th>SubScore C6-C7</th>
                                <th>Total CDI Score</th>
                                <th>Created At</th>
                                <th>Action</th>

                            </tr>
                        </thead>
                        <tbody>
                            {patientCDIList && patientCDIList.map((item, index) => (
                                <tr key={index}>
                                    <td>{calculateSubScore('c2_c3', item)}</td>
                                    <td>{calculateSubScore('c3_c4', item)}</td>
                                    <td>{calculateSubScore('c4_c5', item)}</td>
                                    <td>{calculateSubScore('c5_c6', item)}</td>
                                    <td>{calculateSubScore('c6_c7', item)}</td>
                                    <td>{getTotalCdiScore(item)}</td>
                                    <td>{dateTimeFormate(item?.created_at)}</td>
                                    {((permissions.includes("PATIENT_CDI_UPDATE") || role == "SUPER_ADMIN") && isToday(item?.created_at)) && (
                                        <td>
                                            <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                        </td>
                                    )}

                                </tr>
                            ))}


                        </tbody>
                    </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default CdiList;
