import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback,

} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitTripleLine, submiTendinopathiesEditAPI, getTripleLineDetailAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import { QuestionList } from './questions-list';
const DiagnosisAdd = () => {
    const history = useHistory()
    const params = useParams()
    const cancelTokenSource = axios.CancelToken.source();
    const [firstName, setFirstName] = useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [questionValue, setQuestionValue] = useState({});
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));

    useEffect(() => {
        if (params?.triple_line_id) {
            getTripleLineDetail(params?.triple_line_id)

        }
    }, [params?.triple_line_id]);


    const getTripleLineDetail = async (tripleLineId) => {
        setIsLoader(true)
        const response = await getTripleLineDetailAPI(userUtil?.userData?.center_id, params?.patient_id, tripleLineId, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            console.log(response?.data, 'response?.data')
            setQuestionValue(response?.data)
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        }
    }
    const validateAnswers = (questionList, answers) => {
        // Iterate through each question in the question list
        for (const category of questionList) {
            for (const question of category.questions) {
                // Check if there is an answer for each question
                const answerKey = question.key;
                if (!answers.hasOwnProperty(answerKey)) {
                    return false; // Answer is missing for a question
                }
            }
        }
        return true; // All questions have answers
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log('handleSubmit called')
        if (!validateAnswers(QuestionList, questionValue)) {
            toast("Please Fill all the fields", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            return
        } else {
            if (params?.triple_line_id) {
                handleSubmitUserEdit();
            } else {
                handleSubmitUser();
            }
        }
    }
    const handleSubmitUserEdit = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        let dataToSend = {
            id: params?.patient_id,
        }
        questionValue['id'] = params?.triple_line_id
        questionValue['customCreatedAt'] =  customCreatedAt != ''? customCreatedAt:null
        const response = await submiTendinopathiesEditAPI(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Tendinopathies Updated Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/tendinopathies/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handleSubmitUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsLoader(true)
        questionValue['customCreatedAt'] = customCreatedAt != ''? customCreatedAt:null

        const response = await submitTripleLine(userUtil?.userData?.center_id, params?.patient_id, questionValue, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            toast("Triple Line Added Successsfully", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
            // history.push(`/patient/tendinopathies/list/${params?.patient_id}`)
            history.push(`/patient/dashboard/${params?.patient_id}`)
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }

    const handleClick = (e, data, optionData) => {
        questionValue[data?.key] = optionData
        setQuestionValue(questionValue)
        console.log(questionValue, 'questionValue')
    }
    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "Triple Line", url: `/patient/triple-line/list/${params?.patient_id}` },
                        { label: params?.triple_line_id ? "Edit Triple Line" : "Add Triple Line" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.triple_line_id ? "Edit" : "Add"}{" "} Triple Line</CardTitle>

                            {QuestionList && QuestionList.map((questionHead, questionIndex) => (

                                <Row key={questionIndex}>
                                    <h3 style={{ borderBottom: '1px solid silver' }}>{questionHead?.questionHeading}</h3>
                                    {questionHead?.questions && questionHead?.questions.map((item, index) => (
                                        <Col md="6" key={index}>
                                            <FormGroup tag="fieldset" row style={{ marginLeft: '20px' }}>
                                                <legend className="col-form-label">{parseInt(index + 1)}. {item?.question} </legend>
                                                <p className="cdi-label-description">{item?.description}</p>
                                                {item?.options && item?.options.map((optionValue, optionValueIndex) => {
                                                    let isChecked = false
                                                    console.log(questionValue, 'questionValue questionValue')
                                                    console.log(questionValue[item?.key], '::::', item?.key, 'questionValue questionValue[item?.key]')
                                                    console.log(optionValue, 'optionValue optionValue')
                                                    // if (questionValue[item?.key] == optionValue) {
                                                    //     isChecked = true;
                                                    // }
                                                    for (let a in questionValue) {

                                                        if (a == item?.key) {
                                                            console.log(questionValue[a], 'questionValuequestionValuequestionValuequestionValuequestionValue')
                                                            if (questionValue[a] == optionValue) {
                                                                isChecked = true;
                                                            }
                                                        }
                                                    }
                                                    console.log(isChecked, 'isChecked')
                                                    return (
                                                        <FormGroup check style={{ marginLeft: '30px' }}>
                                                            <Label check key={optionValueIndex}>
                                                                {params?.triple_line_id ? (
                                                                    <Input name={item?.key} checked={isChecked} defaultValue={optionValue} type={"radio"}
                                                                        onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                    />
                                                                ) : (
                                                                    <Input name={item?.key} defaultChecked={isChecked} defaultValue={optionValue} type={"radio"}
                                                                        onChange={(e) => { handleClick(e, item, optionValue) }}
                                                                    />
                                                                )}
                                                                {optionValue}
                                                            </Label>
                                                        </FormGroup>
                                                    )
                                                })}
                                            </FormGroup>
                                        </Col>
                                    ))}
                                </Row>
                            ))
                            }
                            <FormGroup>
                                <Label for="cus_created_at">Custom Created At</Label>
                                <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                            </FormGroup>
                            {!params?.triple_line_id &&
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button className="btn btn-primary" onClick={(e) => handleSubmit(e)}>Submit</button>
                                </div>
                            }
                        </CardBody>
                    </Card >
                </Col >
            </Row >
        </div >
    );
};

export default DiagnosisAdd;
