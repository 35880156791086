import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button, Modal, ModalBody, ModalHeader
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getPatientListAPI, checkIfCenterIsSetupAPI } from './../../api/network/Api';
import PageTitle from '../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../api/helperComponents/Pagination';
import { useHistory } from 'react-router-dom';
import userUtil from '../../utils/UserUtils';
import Loader from '../../api/helperComponents/Loader';
import BreadCrumb from '../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../api/helperComponents/dateTimeFormat';
const PatientList = (props) => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const [patientList, setPatientList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));

    const [tooltipOpenEdit, setTooltipOpenEdit] = useState(false);
    const [tooltipOpenMedical, setTooltipOpenMedical] = useState(false);
    const [tooltipOpenTreatment, setTooltipOpenTreatment] = useState(false);
    const [tooltipOpenDpep, setTooltipOpenDpep] = useState(false);
    const [tooltipOpenCDI, setTooltipOpenCDI] = useState(false);
    const [tooltipOpenECDI, setTooltipOpenECDI] = useState(false);
    const [tooltipOpenDiagnosis, setTooltipOpenDiagnosis] = useState(false);
    const [tooltipOpenTendinopathies, setTooltipOpenTendinopathies] = useState(false);
    const [tooltipOpenCategorization, setTooltipOpenCategorization] = useState(false);
    const [tooltipOpenSuggestedTreatment, setTooltipOpenSuggestedTreatment] = useState(false);
    const [tooltipIndex, setTooltipIndex] = useState(null);
    const [isPatientCanAdd, setIsPatientCanAdd] = useState(false);
    const [isLoaderCenterSetup, setIsLoaderCenterSetup] = useState(false);
    const [isModalPatientCannotAdd, setIsModalPatientCannotAdd] = useState(false);
    const [searchName, setSearchName] = useState('');
    useEffect(() => {
        if (centerId) {
            checkIfCenterIsSetup(centerId)
        }
    }, [centerId]);
    const toggleEdit = (e, index) => {
        e.preventDefault();
        setTooltipIndex(index)
        setTooltipOpenEdit(!tooltipOpenEdit);
    };

    const toggleMedical = (e) => {
        e.preventDefault();
        setTooltipOpenMedical(!tooltipOpenMedical);
    };

    const toggleTreatment = (e) => {
        e.preventDefault();
        setTooltipOpenTreatment(!tooltipOpenTreatment);
    };

    const toggleDpep = (e) => {
        e.preventDefault();
        setTooltipOpenDpep(!tooltipOpenDpep);
    };
    const toggleCDI = (e) => {
        e.preventDefault();
        setTooltipOpenCDI(!tooltipOpenCDI);
    };
    const toggleECDI = (e) => {
        e.preventDefault();
        setTooltipOpenECDI(!tooltipOpenECDI);
    };
    const toggleDiagnosis = (e) => {
        e.preventDefault();
        setTooltipOpenDiagnosis(!tooltipOpenDiagnosis);
    };
    const toggleTendinopathies = (e) => {
        e.preventDefault();
        setTooltipOpenTendinopathies(!tooltipOpenDiagnosis);
    };
    const toggleCategorization = (e) => {
        e.preventDefault();
        setTooltipOpenCategorization(!tooltipOpenCategorization);
    };
    const toggleSuggestedTreatment = (e) => {
        e.preventDefault();
        setTooltipOpenSuggestedTreatment(!tooltipOpenSuggestedTreatment);
    };

    useEffect(() => {
        if (centerId) {
            getPatientList(searchName, centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getPatientList(searchName, centerId, page, rowsPerPage)
    }, [page]);
    const getPatientList = async (searchNameData, centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getPatientListAPI(searchNameData, centerId, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setPatientList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const checkIfCenterIsSetup = async (centerId) => {
        setIsLoaderCenterSetup(true)
        const response = await checkIfCenterIsSetupAPI(centerId, cancelTokenSource.token)
        setIsLoaderCenterSetup(false)
        if (response.success) {
            setIsPatientCanAdd(response?.data?.count == 0 ? false : true)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddPatientClick = () => {
        if (isPatientCanAdd) {
            history.push('/patient/add')
        } else {
            setIsModalPatientCannotAdd(true)
        }
    }
    const handleClickEdit = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/edit/${data?.id}`)
    }
    const handleClickPateintHistory = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/medical-histories/${data?.id}`)
    }
    const handleClicktreatment = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/treatments/list/${data?.id}`)
    }
    const handleClickDpep = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/dpeps/list/${data?.id}`)
    }
    const handleClickCDI = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/cdis/list/${data?.id}`)
    }
    const handleClickECDI = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/extended-cdis/list/${data?.id}`)
    }
    const handleClickDiagnosis = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/diagnosis/list/${data?.id}`)
    }
    const handleClickTendinopathies = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/tendinopathies/list/${data?.id}`)
    }
    const handleClickCategorization = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/categorization/list/${data?.id}`)
    }
    const handleClickSuggestedTreatment = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/suggested-treatment/list/${data?.id}`)
    }
    const handleClickDashboard = (e, data) => {
        localStorage.setItem('patientNameID', data?.center_info?.center_initials + '-' + data?.center_info?.center_reg_code + '-' + data?.created_at.substring(0, 7).replace('-', '') + '-' + data?.id)
        history.push(`/patient/dashboard/${data?.id}`)
    }
    const handleAddMembers = (e) => {
        history.push(`/member/add`)
    }
    const handleClickSearch = () => {
        getPatientList(searchName, centerId, page, rowsPerPage)
    }
    return (
        <div>
            {(isLoader || isLoaderCenterSetup) && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Patient List
                        <div style={{ display: 'flex' }}>
                            <input type="text" className='form-control' onChange={(e) => { setSearchName(e.target.value) }} placeholder='Search By Name' />
                            <button style={{ marginLeft: '10px' }} className="btn btn-primary" onClick={(e) => { handleClickSearch() }}>Search</button>
                        </div>
                        {permissions.includes("PATIENT_CREATE") && (
                            <button className="btn btn-primary" onClick={() => handleAddPatientClick()}>
                                Add Patient
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Contact No</th>
                                    <th>Gender</th>
                                    <th>DOB</th>
                                    <th>Created At</th>
                                    {(permissions.includes("PATIENT_UPDATE") || permissions.includes("PATIENT_MEDICAL_HISTORY_CREATE") || permissions.includes("PATIENT_TREATMENT_CREATE") || permissions.includes("PATIENT_MEDICAL_HISTORY_UPDATE") || permissions.includes("PATIENT_MEDICAL_HISTORY_READ")) && (
                                        <th>Action</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {patientList && patientList.map((item, index) => (
                                    <tr key={index}>
                                        <td><span style={{ cursor: 'pointer', color: 'blue' }} onClick={(e) => { handleClickDashboard(e, item) }}>{item?.name}</span></td>
                                        <td>{item?.contact_number}</td>
                                        <td>{item?.gender == "1" ? "Male" : item?.gender == "2" ? "Female" : "Other"}</td>
                                        <td>{item?.dob.split('T')[0]}</td>
                                        <td>{dateTimeFormate(item?.created_at)}</td>
                                        <td>
                                            <span>
                                                <i
                                                    id={"tooltip-edit-"}
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                    className="fa fa-eye"
                                                    onClick={(e) => { handleClickDashboard(e, item) }} // Add an onClick event handler here
                                                ></i>
                                                <Tooltip
                                                    {...props}
                                                    isOpen={tooltipOpenEdit}
                                                    target={"tooltip-edit-"}
                                                    toggle={(e) => { toggleEdit(e, index) }}
                                                >
                                                    Patient Dashboard
                                                </Tooltip>
                                            </span>
                                        </td>

                                    </tr>
                                ))}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
            <Modal isOpen={isModalPatientCannotAdd} toggle={(e) => { setIsModalPatientCannotAdd(!isModalPatientCannotAdd) }} style={{ minWidth: '40%' }}>
                <ModalHeader toggle={(e) => { setIsModalPatientCannotAdd(!isModalPatientCannotAdd) }} charCode="Y">Alert</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <i className="fa fa-cancel" style={{ fontSize: '100px', color: 'red' }}></i>
                        <h5 className="mt-4">Please create members, then you will be able to add patients!</h5>
                        <button className="btn btn-primary mt-4" onClick={(e) => { handleAddMembers(e) }}>Add Members</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

PatientList.args = {
    autohide: true,
    flip: true,
};

PatientList.argTypes = {
    placement: {
        control: { type: 'select' },
        options: ['top', 'left', 'right', 'bottom'],
    },
};
export default PatientList;
