import React, { useState, useEffect, useRef } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback, Table
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { submitDpedAPI, getSpecificDpedDetailAPI, getPatientDeepRadiculopathyMotorChartListAPI, getPatientDeepRadiculopathyMotorSensoryChartListAPI, submitRadiculopathyChartDataAPI, submitRadiculopathySensoryChartDataAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import { QuestionList } from './../medical-history/questions-list';
import { Stepper } from 'react-form-stepper';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
const DpepAdd = () => {
    const history = useHistory()
    const params = useParams()
    const canvasRef = useRef(null);
    const formRefRadiculopathyChart = useRef(null);
    const formRefRadiculopathySensoryChart = useRef(null);
    const cancelTokenSource = axios.CancelToken.source();
    const [questionValue, setQuestionValue] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [totalStep, setTotalStep] = useState(4);
    const [questionsStepWise, setQuestionsStepWise] = useState([]);
    const [showArthroscopy, setShowArthroscopy] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedCoords, setSelectedCoords] = useState([]);
    const [finalObject, setFinalObject] = useState({});
    const [errorCategory, setErrorCategory] = useState(false);
    const [dpedId, setDpedId] = useState(null);
    const [dPEDDetailData, setDPEDDetailData] = useState(null);
    const [customCreatedAt, setCustomCreatedAt] = useState(null);
    const [radiculopathyMotorChart, setRadiculopathyMotorChart] = useState({
        "c_5_g_0": 0,
        "c_5_g_1": 0,
        "c_5_g_2": 0,
        "c_5_g_3": 0,
        "c_5_g_4": 0,
        "c_5_g_5": 0,
        "c_6_g_0": 0,
        "c_6_g_1": 0,
        "c_6_g_2": 0,
        "c_6_g_3": 0,
        "c_6_g_4": 0,
        "c_6_g_5": 0,
        "c_7_g_0": 0,
        "c_7_g_1": 0,
        "c_7_g_2": 0,
        "c_7_g_3": 0,
        "c_7_g_4": 0,
        "c_7_g_5": 0,
        "c_8_g_0": 0,
        "c_8_g_1": 0,
        "c_8_g_2": 0,
        "c_8_g_3": 0,
        "c_8_g_4": 0,
        "c_8_g_5": 0,
        "t_1_g_0": 0,
        "t_1_g_1": 0,
        "t_1_g_2": 0,
        "t_1_g_3": 0,
        "t_1_g_4": 0,
        "t_1_g_5": 0,
        "l_2_g_0": 0,
        "l_2_g_1": 0,
        "l_2_g_2": 0,
        "l_2_g_3": 0,
        "l_2_g_4": 0,
        "l_2_g_5": 0,
        "l_3_g_0": 0,
        "l_3_g_1": 0,
        "l_3_g_2": 0,
        "l_3_g_3": 0,
        "l_3_g_4": 0,
        "l_3_g_5": 0,
        "l_4_g_0": 0,
        "l_4_g_1": 0,
        "l_4_g_2": 0,
        "l_4_g_3": 0,
        "l_4_g_4": 0,
        "l_4_g_5": 0,
        "l_5_g_0": 0,
        "l_5_g_1": 0,
        "l_5_g_2": 0,
        "l_5_g_3": 0,
        "l_5_g_4": 0,
        "l_5_g_5": 0,
        "s_1_g_0": 0,
        "s_1_g_1": 0,
        "s_1_g_2": 0,
        "s_1_g_3": 0,
        "s_1_g_4": 0,
        "s_1_g_5": 0,
        "s_2_g_0": 0,
        "s_2_g_1": 0,
        "s_2_g_2": 0,
        "s_2_g_3": 0,
        "s_2_g_4": 0,
        "s_2_g_5": 0
    });
    const [radiculopathyMotorSensoryChart, setRadiculopathyMotorSensoryChart] = useState({
        "c_2_hypo_esthesia": 0,
        "c_2_hyper_esthesia": 0,
        "c_3_hypo_esthesia": 0,
        "c_3_hyper_esthesia": 0,
        "c_4_hypo_esthesia": 0,
        "c_4_hyper_esthesia": 0,
        "c_5_hypo_esthesia": 0,
        "c_5_hyper_esthesia": 0,
        "c_6_hypo_esthesia": 0,
        "c_6_hyper_esthesia": 0,
        "c_7_hypo_esthesia": 0,
        "c_7_hyper_esthesia": 0,
        "c_8_hypo_esthesia": 0,
        "c_8_hyper_esthesia": 0,
        "t_1_hypo_esthesia": 0,
        "t_1_hyper_esthesia": 0,
        "t_2_hypo_esthesia": 0,
        "t_2_hyper_esthesia": 0,
        "t_3_hypo_esthesia": 0,
        "t_3_hyper_esthesia": 0,
        "t_4_hypo_esthesia": 0,
        "t_4_hyper_esthesia": 0,
        "t_5_hypo_esthesia": 0,
        "t_5_hyper_esthesia": 0,
        "t_6_hypo_esthesia": 0,
        "t_6_hyper_esthesia": 0,
        "t_7_hypo_esthesia": 0,
        "t_7_hyper_esthesia": 0,
        "t_8_hypo_esthesia": 0,
        "t_8_hyper_esthesia": 0,
        "t_9_hypo_esthesia": 0,
        "t_9_hyper_esthesia": 0,
        "t_10_hypo_esthesia": 0,
        "t_10_hyper_esthesia": 0,
        "t_11_hypo_esthesia": 0,
        "t_11_hyper_esthesia": 0,
        "t_12_hypo_esthesia": 0,
        "t_12_hyper_esthesia": 0,
        "l_1_hypo_esthesia": 0,
        "l_1_hyper_esthesia": 0,
        "l_2_hypo_esthesia": 0,
        "l_2_hyper_esthesia": 0,
        "l_3_hypo_esthesia": 0,
        "l_3_hyper_esthesia": 0,
        "l_4_hypo_esthesia": 0,
        "l_4_hyper_esthesia": 0,
        "l_5_hypo_esthesia": 0,
        "l_5_hyper_esthesia": 0,
        "s_1_hypo_esthesia": 0,
        "s_1_hyper_esthesia": 0,
        "right_hemi_hypo_esthesia": 0,
        "right_hemi_hyper_esthesia": 0,
        "left_hemi_hypo_esthesia": 0,
        "left_hemi_hyper_esthesia": 0

    });
    const [finalRadiculopathyChartData, setFinalRadiculopathyChartData] = useState(null);
    const [finalRadiculopathySensoryChartData, setFinalRadiculopathySensoryChartData] = useState(null);
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [showAssociatedDiseasesArthroscopyOther, setShowAssociatedDiseasesArthroscopyOther] = useState(false);
    const [overlayStyle, setOverlayStyle] = useState({
        width: '100px', height: '19px', top: '779px', left: '1571px'
    });
    let patientId = params?.patient_id
    let dped_id = params?.dped_id
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [alphabet, setAlphabet] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']);
    const [dpepStepData, setDpepStepData] = useState([{ label: 'DPEP' }, { label: 'Radiculopathy' }, { label: 'Physical Findings' }, { label: 'Report' }]);
    const [coordsPoint, setCoordsPoint] = useState([
        {
            "key": 1,
            "name": "Right Frontal Head",
            "coords": "411,124,414,242,341,242,337,228,334,214,340,198,343,171,351,147,364,135,384,124"
        },
        {
            "key": 2,
            "name": "Left Frontal Head",
            "coords": "418,122,418,240,486,243,489,233,493,217,487,201,487,182,480,154,464,135,443,124"
        },
        {
            "key": 3,
            "name": "Right Face",
            "coords": "365,287,411,323,409,244,344,241"
        },
        {
            "key": 4,
            "name": "Left Face",
            "coords": "416,244,418,322,462,289,472,269,476,253,482,244,452,242"
        },
        {
            "key": 5,
            "name": "Right Supraclavicular/Neck",
            "coords": "368,298,411,329,412,383,316,361"
        },
        {
            "key": 6,
            "name": "Left Supraclavicular/Neck",
            "coords": "416,328,415,383,515,361,466,332,462,298"
        },
        {
            "key": 7,
            "name": "Right Upper Chest",
            "coords": "319,367,326,402,315,443,294,478,382,481,408,477,414,407,411,392,401,382"
        },
        {
            "key": 8,
            "name": "Left Upper Chest",
            "coords": "416,390,418,477,530,483,505,447,497,411,497,383,500,371"
        },
        {
            "key": 9,
            "name": "Right Lower Chest",
            "coords": "293,489,414,486,412,543,401,547,398,565,386,583,366,612,337,620,328,630,303,647,294,597,289,562"
        },
        {
            "key": 10,
            "name": "Left Lower Chest",
            "coords": "418,484,416,543,429,543,440,568,459,605,500,633,525,644,534,619,533,582,537,539,534,502,530,489"
        },
        {
            "key": 11,
            "name": "Right Hypochondrium",
            "coords": "371,614,371,652,309,647"
        },
        {
            "key": 12,
            "name": "Epigastrium",
            "coords": "401,555,426,549,430,566,441,586,445,600,444,616,447,645,426,645,401,643,380,643,376,617"
        },
        {
            "key": 13,
            "name": "Left Hypochondrium",
            "coords": "451,605,451,647,509,648"
        },
        {
            "key": 14,
            "name": "Right Lumbar",
            "coords": "303,651,373,655,372,718,334,723,291,720,290,702"
        },
        {
            "key": 15,
            "name": "Umbilical Area",
            "coords": "375,652,448,654,448,722,376,722"
        },
        {
            "key": 16,
            "name": "Left Lumbar",
            "coords": "448,651,523,651,537,690,541,722,450,720"
        },
        {
            "key": 17,
            "name": "Right Iliac Fossa",
            "coords": "287,725,372,726,371,780,314,761,282,772"
        },
        {
            "key": 18,
            "name": "Hypogastrium",
            "coords": "375,725,450,727,448,786,423,820,414,861,391,810,373,789"
        },
        {
            "key": 19,
            "name": "Left Iliac Fossa",
            "coords": "450,725,541,725,548,771,501,761,473,767,451,785"
        },
        {
            "key": 20,
            "name": "Right Shoulder",
            "coords": "315,366,273,369,247,383,233,405,221,434,221,450,248,450,268,454,280,465,289,480,315,443,319,407,321,391"
        },
        {
            "key": 21,
            "name": "Left Shoulder",
            "coords": "501,366,545,365,569,376,586,394,598,412,608,448,580,446,562,448,547,464,536,479,509,446,500,411"
        },
        {
            "key": 22,
            "name": "Right Arm",
            "coords": "218,453,210,504,201,540,185,580,211,586,232,586,254,600,262,615,275,573,283,544,285,508,286,479,272,464,251,451"
        },
        {
            "key": 23,
            "name": "Left Arm",
            "coords": "608,454,620,516,644,579,615,579,584,582,569,602,552,582,545,557,543,521,541,493,545,465,570,451"
        },
        {
            "key": 24,
            "name": "Right Elbow",
            "coords": "176,595,164,624,160,648,179,667,196,675,219,674,241,668,251,648,251,629,253,610,237,595,212,588"
        },
        {
            "key": 25,
            "name": "Left Elbow",
            "coords": "566,612,570,643,586,659,616,670,643,663,656,650,661,633,655,608,647,595,631,586,609,584,588,591"
        },
        {
            "key": 26,
            "name": "Right Forearm",
            "coords": "157,655,133,772,160,769,176,772,187,790,200,763,215,738,226,708,240,679,200,680"
        },
        {
            "key": 27,
            "name": "Left Forearm",
            "coords": "581,666,634,783,647,781,658,770,670,770,684,772,695,777,688,751,679,718,672,677,668,650,633,669"
        },
        {
            "key": 28,
            "name": "Right Wrist",
            "coords": "125,794,128,818,143,830,162,830,180,822,183,800,155,793,135,781"
        },
        {
            "key": 29,
            "name": "Left Wrist",
            "coords": "644,795,644,812,644,822,659,829,668,836,684,833,701,820,705,801,693,784,674,791"
        },
        {
            "key": 30,
            "name": "Right Palm",
            "coords": "119,809,90,833,65,866,72,870,85,865,100,849,108,848,83,908,80,926,92,923,105,891,114,877,112,899,104,933,108,945,133,884,135,899,129,926,136,940,148,908,155,884,158,891,158,913,161,924,173,872,182,833,144,833"
        },
        {
            "key": 31,
            "name": "Left Palm",
            "coords": "708,808,741,834,756,852,762,866,752,869,720,852,729,876,738,893,752,923,740,924,717,880,709,887,713,908,722,933,719,940,695,890,687,897,697,931,687,934,674,883,668,888,670,923,662,918,645,834,690,836"
        },
        {
            "key": 32,
            "name": "Right Groin",
            "coords": "280,773,276,788,297,805,326,825,371,836,397,845,393,818,371,793,339,769,308,765"
        },
        {
            "key": 33,
            "name": "Left Groin",
            "coords": "423,844,450,840,479,833,520,811,541,797,550,783,540,766,518,765,493,765,475,772,455,786,437,809,429,823"
        },
        {
            "key": 34,
            "name": "Right Front Thigh",
            "coords": "275,797,319,826,373,843,407,851,405,929,398,981,391,1035,384,1072,357,1083,329,1090,307,1085,287,1076,276,1059,268,972,266,905"
        },
        {
            "key": 35,
            "name": "Left Front Thigh",
            "coords": "419,849,419,890,423,954,433,1030,437,1066,455,1084,476,1090,498,1092,516,1091,533,1085,543,1074,550,1052,554,1002,562,947,559,909,555,865,557,818,551,794,497,829"
        },
        {
            "key": 36,
            "name": "Right Knee",
            "coords": "279,1077,276,1113,279,1144,279,1176,291,1167,312,1163,339,1163,362,1172,371,1178,376,1131,382,1092,383,1077,336,1092"
        },
        {
            "key": 37,
            "name": "Left Knee",
            "coords": "441,1079,450,1132,454,1176,484,1164,512,1161,533,1169,548,1185,550,1132,550,1107,550,1079,495,1099"
        },
        {
            "key": 38,
            "name": "Right Leg",
            "coords": "280,1189,276,1219,282,1251,289,1307,303,1382,308,1405,323,1398,341,1397,358,1401,365,1392,362,1360,365,1333,368,1286,377,1242,376,1207,368,1183,350,1169,323,1164,300,1171"
        },
        {
            "key": 39,
            "name": "Left Leg",
            "coords": "455,1184,448,1248,458,1294,466,1367,468,1396,490,1387,509,1387,520,1396,529,1381,532,1339,544,1265,550,1209,545,1185,520,1167,486,1163"
        },
        {
            "key": 40,
            "name": "Right Ankle",
            "coords": "311,1413,303,1427,307,1438,319,1451,340,1456,351,1448,364,1438,364,1421,355,1409,332,1414"
        },
        {
            "key": 41,
            "name": "Left Ankle",
            "coords": "469,1398,487,1406,507,1406,516,1401,522,1420,520,1431,511,1437,504,1442,491,1446,472,1441,465,1423,461,1413"
        },
        {
            "key": 42,
            "name": "Right Foot",
            "coords": "304,1446,321,1457,343,1462,358,1451,366,1460,371,1481,371,1499,364,1513,347,1513,319,1514,291,1514,279,1505"
        },
        {
            "key": 43,
            "name": "Left Foot",
            "coords": "466,1444,457,1468,457,1490,459,1507,476,1512,494,1515,515,1515,538,1515,550,1504,544,1489,526,1468,523,1451,522,1435,500,1447"
        },
        {
            "key": 44,
            "name": "Right Occipital Head",
            "coords": "1163,109,1163,235,1227,235,1234,219,1238,202,1235,188,1230,153,1216,126,1192,112"
        },
        {
            "key": 45,
            "name": "Left Occipital Head",
            "coords": "1160,110,1160,233,1094,232,1084,210,1088,194,1094,174,1091,153,1106,129,1128,112"
        },
        {
            "key": 46,
            "name": "Right Neck",
            "coords": "1162,236,1163,350,1257,347,1218,318,1209,286,1210,265,1224,235"
        },
        {
            "key": 47,
            "name": "Left Neck",
            "coords": "1159,237,1160,349,1059,352,1102,324,1113,295,1113,276,1098,235"
        },
        {
            "key": 48,
            "name": "Right Scapular",
            "coords": "1163,355,1280,354,1307,360,1330,375,1346,403,1355,436,1356,461,1356,477,1342,493,1320,500,1288,496,1267,484,1163,405"
        },
        {
            "key": 49,
            "name": "Left Scapular",
            "coords": "1159,353,1159,403,1044,492,1016,498,987,491,969,475,965,452,971,414,983,388,1002,362,1035,349"
        },
        {
            "key": 50,
            "name": "Right Backoff chest",
            "coords": "1163,409,1162,504,1280,643,1288,552,1284,519,1280,494"
        },
        {
            "key": 51,
            "name": "Left Backoff chest",
            "coords": "1159,404,1162,504,1045,640,1037,559,1042,496"
        },
        {
            "key": 52,
            "name": "Right Loin",
            "coords": "1163,509,1163,704,1213,772,1242,758,1270,756,1292,761,1286,666,1281,650"
        },
        {
            "key": 53,
            "name": "Left Loin",
            "coords": "1160,512,1160,704,1110,763,1060,750,1033,756,1037,698,1038,658"
        },
        {
            "key": 54,
            "name": "Right Back Arm",
            "coords": "1360,481,1378,550,1399,591,1416,642,1436,768,1423,781,1403,789,1384,782,1360,749,1343,700,1323,645,1306,585,1293,556,1284,521,1282,498,1325,503"
        },
        {
            "key": 55,
            "name": "Left Back Arm",
            "coords": "963,482,942,556,916,618,884,770,898,785,919,791,944,784,970,724,1005,627,1033,550,1034,527,1037,495,1001,498"
        },
        {
            "key": 56,
            "name": "Right Dorsum Hand",
            "coords": "1438,779,1442,791,1470,804,1484,825,1503,850,1509,861,1495,865,1470,847,1475,870,1489,903,1493,920,1479,910,1461,874,1452,871,1463,902,1470,932,1454,932,1435,870,1434,893,1445,924,1439,933,1427,911,1416,875,1414,893,1416,918,1409,920,1402,885,1392,838,1388,806,1382,789,1410,789"
        },
        {
            "key": 57,
            "name": "Left Dorsum Hand",
            "coords": "938,789,934,802,930,821,922,877,913,920,903,917,908,875,901,890,887,933,880,931,880,908,885,871,876,884,869,921,859,932,855,914,872,867,858,865,856,882,837,920,826,915,841,874,856,843,837,859,824,861,815,857,834,828,860,803,883,777,905,792"
        },
        {
            "key": 58,
            "name": "Right Gluteal",
            "coords": "1293,766,1305,884,1303,899,1280,918,1255,932,1218,928,1187,913,1170,884,1170,857,1174,821,1188,797,1216,775,1249,756"
        },
        {
            "key": 59,
            "name": "Left Gluteal",
            "coords": "1027,759,1016,890,1028,912,1046,924,1069,931,1102,931,1123,919,1141,901,1155,876,1150,840,1144,815,1127,793,1099,758"
        },
        {
            "key": 60,
            "name": "Right Back of Thigh",
            "coords": "1168,885,1183,1053,1195,1071,1228,1085,1265,1084,1283,1074,1294,1059,1303,1016,1307,946,1307,902,1246,940,1203,922"
        },
        {
            "key": 61,
            "name": "Left Back of Thigh",
            "coords": "1152,887,1146,984,1139,1044,1127,1063,1106,1077,1077,1080,1046,1073,1028,1058,1020,1017,1013,965,1019,902,1064,938,1126,922,1126,922"
        },
        {
            "key": 62,
            "name": "Right Calf",
            "coords": "1293,1178,1296,1226,1288,1280,1278,1344,1270,1383,1262,1394,1243,1396,1227,1395,1210,1387,1212,1327,1205,1294,1198,1258,1198,1221,1203,1178,1221,1160,1252,1153,1274,1160"
        },
        {
            "key": 63,
            "name": "Left Calf",
            "coords": "1030,1177,1024,1224,1030,1252,1037,1291,1044,1341,1049,1380,1058,1398,1071,1406,1085,1405,1101,1398,1107,1391,1112,1348,1119,1308,1121,1276,1124,1227,1121,1180,1109,1166,1071,1153,1053,1156"
        },
        {
            "key": 64,
            "name": "Right Heel",
            "coords": "1202,1477,1202,1499,1207,1512,1235,1513,1271,1517,1291,1509,1291,1489,1278,1474,1263,1462,1235,1459"
        },
        {
            "key": 65,
            "name": "Left Heel",
            "coords": "1051,1470,1035,1487,1028,1507,1037,1514,1062,1516,1084,1514,1113,1510,1123,1488,1103,1467,1076,1459"
        },
        {
            "key": 66,
            "name": "Sacrococcyx",
            "coords": "1159,708,1207,770,1178,805,1169,834,1166,862,1156,855,1139,800,1113,768"
        }
    ]);
    const [categories, setCategories] = useState([
        {
            name: "Numbness (Activity)",
            key: "A",
            db_key: "numbness_activity",
            color: '#ffff0094'
        },
        {
            name: "Numbness (Constant)",
            key: "B",
            db_key: "numbness_constant",
            color: '#0b1f67a1'
        }, {
            name: "Numbness (Sleep)",
            key: "C",
            db_key: "numbness_sleep",
            color: '#ff9a00a1'
        }, {
            name: "Pain",
            key: "D",
            db_key: "pain",
            color: '#ca0000a1'
        }, {
            name: "Tenderness",
            key: "E",
            db_key: "tenderness",
            color: '#00ca20a1'
        }, {
            name: "Swelling",
            key: "F",
            db_key: "swelling",
            color: '#ae00caa1'
        }, {
            name: "Radicular Pain",
            key: "G",
            db_key: "radicular_pain",
            color: '#ca0071a1'
        }, {
            name: "Deformity",
            key: "H",
            db_key: "deformity",
            color: '#00ffff8c'
        }, {
            name: "Wound",
            key: "I",
            db_key: "wound",
            color: '#ff00ff73'
        }, {
            name: "Disability",
            key: "J",
            db_key: "disability",
            color: '#964b007d'
        }, {
            name: "Radiculopathy Sensory",
            key: "K",
            db_key: "radiculopathy_sensory",
            color: '#80808096'
        },
        {
            name: "Radiculopathy Motor",
            key: "L",
            db_key: "radiculopathy_motor",
            color: '#0080807d'
        },
    ]);


    useEffect(() => {
        if (params?.dped_id) {
            setDpedId(params?.dped_id)
            getSpecificDpedDetail(params?.dped_id)
            getPatientDeepRadiculopathyMotorChartList(params?.dped_id)
            getPatientDeepRadiculopathyMotorSensoryChartList(params?.dped_id)
        }
    }, [params?.dped_id]);
    useEffect(() => {
        if (activeStep == 4) {
            getSpecificDpedDetail(dpedId)
        }
    }, [activeStep]);
    useEffect(() => {
        if (finalObject) {
            console.log(finalObject, 'finalObject')
        }
    }, [finalObject]);

    useEffect(() => {
        if (finalRadiculopathyChartData) {
            submitRadiculopathyChartData(finalRadiculopathyChartData, dpedId)
            setFinalRadiculopathyChartData(null)
        }
    }, [finalRadiculopathyChartData]);

    useEffect(() => {
        if (finalRadiculopathySensoryChartData) {
            submitRadiculopathyensoryChartData(finalRadiculopathySensoryChartData, dpedId)
            setFinalRadiculopathySensoryChartData(null)
        }
    }, [finalRadiculopathySensoryChartData]);


    const handleMouseOver = (e) => {
        const coords = e.target.getAttribute('coords').split(',');
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = '#1ec0ba4d';
        ctx.beginPath();
        ctx.moveTo(coords[0], coords[1]);

        for (let i = 2; i < coords.length; i += 2) {
            ctx.lineTo(coords[i], coords[i + 1]);
        }

        ctx.closePath();
        ctx.fill();
    };
    const getSelectedCoords = (coords) => {
        console.log(coords, 'coords getSelectedCoords')
        coords = coords.split(",");
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#1ec0ba4d";

        ctx.beginPath();
        ctx.moveTo(coords[0], coords[1]);

        for (let i = 2; i < coords.length; i += 2) {
            ctx.lineTo(coords[i], coords[i + 1]);
        }

        ctx.closePath();
        ctx.fill();
    };

    const handleMouseOut = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
    const getSelectedCoordsAgainstCategory = (categoryKey) => {
        let getSelectedCoordsPoints = selectedCoords[categoryKey]
        let allCoordsForCategory = []
        if (getSelectedCoordsPoints) {
            if (getSelectedCoordsPoints.length > 0) {
                for (let coordsP of getSelectedCoordsPoints) {
                    for (let i = 0; i < coordsPoint.length; i++) {
                        if (coordsPoint[i].key === coordsP) {
                            // return coordsPoint[i].coords;
                            allCoordsForCategory.push(coordsPoint[i].coords)
                            // getSelectedCoords(coordsPoint[i].coords)
                        }
                    }
                }
            }
        }
        console.log(allCoordsForCategory, 'allCoordsForCategory')
        let color = '';
        if (categories) {
            for (let cate of categories) {
                if (cate.key == categoryKey) {
                    color = cate.color
                }
            }
        }
        console.log(color, 'color')
        highlightCoords(allCoordsForCategory, color)
    }
    const handleClickCategory = (category) => {
        setErrorCategory(false)
        if (selectedCoords[category?.key] === undefined) {
            selectedCoords[category?.key] = [];
            handleMouseOut()
        }
        if (selectedCoords[category?.key].length == 0) {
            handleMouseOut()
        }
        console.log(selectedCoords, 'selectedCoords')
        setSelectedCategory(category?.key)
        setSelectedCoords(selectedCoords)
        getSelectedCoordsAgainstCategory(category?.key)
        if (Object.keys(finalObject).length > 0) {
            // handleCall()
        }
    }
    const handleClickCoords = (e, item) => {

        if (selectedCoords[selectedCategory]) {
            if (selectedCoords[selectedCategory].includes(item?.key)) {
                selectedCoords[selectedCategory] = selectedCoords[selectedCategory].filter(key => key !== item?.key);
            } else {
                selectedCoords[selectedCategory].push(item?.key);
            }
            let formattedData = {};

            for (const key in selectedCoords) {
                console.log(selectedCoords[key], 'selectedCoords[key]')
                if (selectedCoords[key].length > 0) {
                    formattedData[key] = selectedCoords[key];
                }
            }
            setFinalObject(formattedData)
            console.log(formattedData, 'selectedCoords');
        } else {
            setErrorCategory(true)
            console.error("Please select a category first.");
        }
        getSelectedCoordsAgainstCategory(selectedCategory)
    }



    const highlightColor = "#FF0000"; // Define the highlight color

    const highlightCoords = (coordsArray, color) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        coordsArray.forEach(coords => {
            coords = coords.split(",");
            ctx.beginPath();
            ctx.moveTo(coords[0], coords[1]);

            for (let i = 2; i < coords.length; i += 2) {
                ctx.lineTo(coords[i], coords[i + 1]);
            }

            ctx.closePath();
            ctx.fillStyle = color;
            ctx.fill();

            // Highlight selected coordinates
            if (selectedCoords[selectedCategory]) {
                ctx.fillStyle = color;
                selectedCoords[selectedCategory].forEach(selectedCoord => {
                    if (selectedCoord === coords[0]) {
                        ctx.beginPath();
                        ctx.moveTo(coords[0], coords[1]);

                        for (let i = 2; i < coords.length; i += 2) {
                            ctx.lineTo(coords[i], coords[i + 1]);
                        }

                        ctx.closePath();
                        ctx.fill();
                    }
                });
            }
        });
    };

    const handleCall = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // setIsLoader(true)
        for (const key in finalObject) {
            if (key != 'physical_findings' && key != 'id') {
                finalObject[key] = Array.from(new Set(finalObject[key]));
            }
        }
        if (dpedId) {
            finalObject['id'] = dpedId
        }
        if (finalObject['physical_findings']) {
            if (finalObject['physical_findings']?.id) {
                delete finalObject['physical_findings']['id']
            }
        }
        finalObject['customCreatedAt'] =  customCreatedAt != ''? customCreatedAt:null

        const response = await submitDpedAPI(centerId, patientId, finalObject, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDpedId(response?.data?.id)
            if (activeStep == 4) {
                getSpecificDpedDetail(dpedId)
            }
            if (activeStep == 2) {
                setActiveStep(activeStep + 1)
            }
            if (activeStep == 3) {
                setActiveStep(activeStep + 1)
            }
            // setPatientMedicalHistoryList(response?.data?.rows)
            // setCount(response?.data?.count)

        } else {
            // toast(response?.system_message, {
            //     transition: Bounce,
            //     closeButton: true,
            //     autoClose: 5000,
            //     position: 'bottom-center',
            //     type: 'error'
            // });
        }
    }
    const getSpecificDpedDetail = async (dped_id_detail) => {
        setIsLoader(true)
        const response = await getSpecificDpedDetailAPI(centerId, patientId, dped_id_detail, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            // setSelectedCategory('A')
            setDPEDDetailData(response?.data)
            updateResponse(response?.data)
            setCustomCreatedAt(response?.data?.created_at?.split('T')[0])
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientDeepRadiculopathyMotorChartList = async (dped_id_detail) => {
        setIsLoader(true)
        const response = await getPatientDeepRadiculopathyMotorChartListAPI(centerId, patientId, dped_id_detail, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let data = response?.data
            const filteredObject = {};
            for (const key in radiculopathyMotorChart) {
                if (key in data) {
                    filteredObject[key] = data[key];
                } 
            }
            setRadiculopathyMotorChart(filteredObject)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const getPatientDeepRadiculopathyMotorSensoryChartList = async (dped_id_detail) => {
        setIsLoader(true)
        const response = await getPatientDeepRadiculopathyMotorSensoryChartListAPI(centerId, patientId, dped_id_detail, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let data = response?.data
            const filteredObject = {};
            for (const key in radiculopathyMotorSensoryChart) {
                if (key in data) {
                    filteredObject[key] = data[key];
                } 
            }
console.log(filteredObject,'filteredObjectfilteredObjectfilteredObjectfilteredObjectfilteredObject')
            setRadiculopathyMotorSensoryChart(filteredObject)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const updateResponse = (data) => {
        const transformedResponse = {};
        for (const category of categories) {
            const areas = data[category.db_key].map(item => parseInt(item.area));
            transformedResponse[category.key] = areas;
        }
        if (data?.physical_findings) {
            transformedResponse['physical_findings'] = data?.physical_findings
        }

        setSelectedCoords(transformedResponse)
        setFinalObject(transformedResponse)
        // setTimeout(function(){
        //     getSelectedCoordsAgainstCategory("A")
        // },1000)

        console.log(transformedResponse, 'transformedResponse')
    }
    const handleClickPhysicalFindings = (e, type, selection) => {
        if (!finalObject['physical_findings']) {
            finalObject['physical_findings'] = {}
        }
        finalObject['physical_findings'][type] = selection
        console.log(finalObject, 'finalObject')
        setFinalObject(finalObject)
    }
    const handleBackButtonClick = () => {
        if (activeStep == 1) {
            handleCall()
        } else {
            setActiveStep(activeStep - 1)
        }
    }
    const handleNextButtonClick = () => {
        if (activeStep == 4) {
            // history.push(`/patient/dpeps/list/${patientId}`)
            history.push(`/patient/dashboard/${patientId}`)
        } else {
            if (activeStep == 2) {
                if ((finalObject['K'] || finalObject['L'])) {
                    setIsLoader(true)
                    generateRadiculopathyData()
                } else {
                    setActiveStep(activeStep + 1)
                }
            }
            if (activeStep == 3) {
                setIsLoader(true)
                if (finalObject?.physical_findings) {
                    if (finalObject?.physical_findings?.facial_atonia && finalObject?.physical_findings?.facial_tics && finalObject?.physical_findings?.hand_tremors_constant && finalObject?.physical_findings?.hand_tremors_intentional && finalObject?.physical_findings?.hand_tremors_strong_grip) {

                    } else {
                        toast("Fill all the required fields", {
                            transition: Bounce,
                            closeButton: true,
                            autoClose: 5000,
                            position: 'bottom-center',
                            type: 'error'
                        });
                    }
                } else {
                    toast("Fill all the required fields", {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                }
            } else {
                setActiveStep(activeStep + 1)
            }
            handleCall()

        }
    }
    const getCategoryName = (key) => {
        if (categories) {
            for (let cate of categories) {
                if (cate.key == key) {
                    return cate?.name
                }
            }
        }
    }
    const getCategoryColor = (key) => {
        if (categories) {
            for (let cate of categories) {
                if (cate.key == key) {
                    return cate?.color
                }
            }
        }
    }
    const getNameOfPoints = (selectedCoordsData) => {
        let cordsName = []
        if (selectedCoordsData) {
            for (let c of selectedCoordsData) {
                for (let a of coordsPoint) {
                    if (a?.key == c) {
                        cordsName.push(a?.name)
                    }
                }
            }
        }
        return cordsName.join(',');

    }
    const handleClickRadiculopathyMotorChart = (e) => {
        console.log(e.target.id)
        console.log(e.target.value)
        console.log(e.target.name)
    }
    const handleSubmitRadiculopathyChart = (e) => {
        e.preventDefault();
        const formData = new FormData(formRefRadiculopathyChart.current);
        const data = {};
        debugger;
        // Iterate through the FormData entries and construct the data object
        for (let [name, value] of formData.entries()) {
            // Find the selected radio button
            const selectedRadio = document.querySelector(`input[name="${name}"]:checked`);
            if (selectedRadio) {
                data[selectedRadio.id] = value;
            }
        }
        const mergedData = mergeObjects(radiculopathyMotorChart, data);

        setFinalRadiculopathyChartData(mergedData);

        console.log(data, 'datadata');
        console.log(mergedData, 'mergeObjects(payload,data)');
    };


    const handleSubmitRadiculopathySensoryChart = (e) => {
        e.preventDefault();
        const formData = new FormData(formRefRadiculopathySensoryChart.current);
        const data = {};
        console.log(formData, 'formData')
        console.log(formData.entries(), 'ormData.entries()')
        // Iterate through the FormData entries and construct the data object
        for (let [name, value] of formData.entries()) {
            console.log(name, 'name')
            console.log(value, 'value')
            // Find the selected radio button
            const selectedRadio = document.querySelector(`input[name="${name}"]:checked`);
            if (selectedRadio) {
                data[selectedRadio.id] = value;
            }
        }
        const mergedData = mergeObjects(radiculopathyMotorSensoryChart, data);

        setFinalRadiculopathySensoryChartData(mergedData);

        console.log(data, 'datadata');
        console.log(mergedData, 'mergeObjectsmergeObjects(payload,data)');
    };
    // const handleSubmitRadiculopathySensoryChart = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData(formRefRadiculopathySensoryChart.current);
    //     const data = Object.fromEntries(formData.entries());
    //     setFinalRadiculopathySensoryChartData(mergeObjects(radiculopathyMotorSensoryChart, data))
    //     console.log(data, 'datadata');
    // }
    const mergeObjects = (a, b) => {
        // Create a copy of 'a' to avoid mutating the original object
        const merged = { ...a };

        // Iterate over keys in 'b'
        for (const key in b) {
            if (b.hasOwnProperty(key)) {
                // Replace values in 'merged' with values from 'b' for matching keys
                merged[key] = isNaN(Number(b[key])) ? b[key] : Number(b[key]);
            }
        }

        return merged;
    };

    const columnsRadiculopathyChart = ['Level', 'G0', 'G1', 'G2', 'G3', 'G4', 'G5'];
    const columnsRadiculopathySensoryChart = ['Level', 'Hypo-esthesia', 'Hyper-esthesia'];

    // Function to generate table rows from the payload
    const rowIdentifiers = [...new Set(Object.keys(radiculopathyMotorChart).map(key => key.split('_').slice(0, 2).join('_')))];

    // Function to generate table rows from the payload
    const generateRows = () => {
        console.log('generate rows ssss')
        console.log(radiculopathyMotorChart, 'radiculopathyMotorChart')
        return rowIdentifiers.map(rowId => (
            <tr key={rowId}>
                <td>{rowId.toUpperCase().replace('_', '')}</td>
                {Array.from({ length: 6 }, (_, index) => (
                    <td key={`${rowId}_g_${index}`}>
                        <Input
                            type="radio"
                            id={`${rowId}_g_${index}`}
                            name={`motor-${rowId}`} // Ensure all radio buttons in a row share the same name
                            value={1} // Set a unique value for each radio button in the group
                            defaultChecked={radiculopathyMotorChart[`${rowId}_g_${index}`] === 1 || radiculopathyMotorChart[`${rowId}_g_${index}`] === true}
                        />
                    </td>
                ))}
            </tr>
        ));
    };

    const rowIdentifiersSensory = [...new Set(Object.keys(radiculopathyMotorSensoryChart).map(key => key.split('_').slice(0, 2).join('_')))];

    const generateRowsSensory = () => {
        return rowIdentifiersSensory.map(rowId => (
            <tr key={rowId}>
                <td>{rowId.toUpperCase()}</td>
                {['hypo_esthesia', 'hyper_esthesia'].map(field => (
                    <td key={`${rowId}_${field}`}>
                        <Input
                            type="radio"
                            id={`${rowId}_${field}`} // Set the id attribute
                            name={`sensory-${rowId}`} // Ensure all radio buttons in a row share the same name
                            value={1} // Set a unique value for each radio button in the group
                            defaultChecked={radiculopathyMotorSensoryChart[`${rowId}_${field}`] === 1 || radiculopathyMotorSensoryChart[`${rowId}_${field}`] === true}
                        />
                    </td>
                ))}
            </tr>
        ));
    };

    const generateRadiculopathyData = () => {
        document.getElementById('refRadiculopathyChartButton').click()
        document.getElementById('refRadiculopathySensoryChartButton').click()
    }
    const submitRadiculopathyChartData = async (data, dpepId) => {
        if (data) {
            if (radiculopathyMotorChart?.id) {
                data['id'] = radiculopathyMotorChart?.id
            }
            data['deep_form_id'] = dpepId
        }
        console.log(data, 'datadatadatadatadatadatadata')
        const updatedRadiculopathyData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === false ? 0 : value === true ? 1 : value])
        );
        setIsLoader(true)
        const response = await submitRadiculopathyChartDataAPI(centerId, patientId, updatedRadiculopathyData, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let data = response?.data
            const filteredObject = {};
            for (const key in radiculopathyMotorChart) {
                if (key in data) {
                    filteredObject[key] = data[key];
                } 
            }
            setRadiculopathyMotorChart(filteredObject)
            // setRadiculopathyMotorSensoryChart(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const submitRadiculopathyensoryChartData = async (data, dpepId) => {
        if (data) {
            if (radiculopathyMotorSensoryChart?.id) {
                data['id'] = radiculopathyMotorSensoryChart?.id
            }
            data['deep_form_id'] = dpepId
        }
        const updatedRadiculopathyData = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === false ? 0 : value === true ? 1 : value])
        );
        setIsLoader(true)
        const response = await submitRadiculopathySensoryChartDataAPI(centerId, patientId, updatedRadiculopathyData, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            let data = response?.data
            const filteredObject = {};
            for (const key in radiculopathyMotorSensoryChart) {
                if (key in data) {
                    filteredObject[key] = data[key];
                } 
            }

console.log(filteredObject,'filteredObjectfilteredObjectfilteredObjectfilteredObjectfilteredObject')
            setRadiculopathyMotorSensoryChart(filteredObject)
            // setRadiculopathyMotorSensoryChart(response?.data)
        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }

    return (
        <div>
            {isLoader && (<Loader />)}
            {console.log(selectedCoords, 'selectedCoords')}
            {console.log(selectedCategory, 'selectedCategory')}
            <Row className="justify-content-center">
                <Col md="12" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: "DPEP", url: `/patient/dpeps/list/${params?.patient_id}` },
                        { label: " Add DPEP" },
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.medical_history_id ? "View" : "Add"}{" "} DPEP History</CardTitle>
                            <Stepper
                                steps={dpepStepData}
                                activeStep={activeStep - 1}
                                styleConfig={{
                                    activeBgColor: '#47aaa8',
                                    inactiveBgColor: '#cfe8e7',
                                    completedBgColor: '#128886',
                                }}
                            />
                            <Row>
                                {activeStep == 1 && (
                                    <>
                                        <div className="d-flex dpep-responsive-mobile" style={{ justifyContent: 'space-between' }}>
                                            <div>
                                                {errorCategory && <span style={{ color: 'red' }}>Select Category To Continue</span>}
                                                {categories && categories.map((item, index) => (
                                                    <Card key={index}
                                                        // className="dpep-card"
                                                        className={selectedCategory == item?.key ? "selected-dpep-card" : "dpep-card"}
                                                        onClick={() => { handleClickCategory(item) }}
                                                    >
                                                        <div className="d-flex dpep-category">
                                                            <div><span className="circle-link">{alphabet[index]}</span>{item?.name}</div>
                                                            <div>
                                                                {finalObject[alphabet[index]]?.length > 0 ? <i className="fa fa-check"></i> : null}
                                                            </div>
                                                        </div>
                                                    </Card>
                                                ))}



                                            </div>
                                            <div style={{ zoom: '50%' }}>

                                                <div className="image-container" style={{ position: 'relative' }}>
                                                    <img src="/images/dpep-skeleton.png" usemap="#image-map" id='dpep-skeleton-image' />
                                                    <map name="image-map">
                                                        {coordsPoint && coordsPoint.map((item, index) => {
                                                            return (
                                                                <area key={index}
                                                                    // onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                                                                    onClick={(e) => { handleClickCoords(e, item) }} alt={item?.key} title={item?.name} href="javascript:void(0);" coords={item?.coords} shape="poly" />
                                                            )
                                                        })}
                                                    </map>
                                                    <canvas
                                                        ref={canvasRef}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            pointerEvents: 'none',
                                                        }}
                                                        width={2000}
                                                        height={2000}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                {activeStep == 2 && (
                                    <div>
                                        {(finalObject['K'] || finalObject['L']) ? (
                                            <>
                                                <Card className="main-card mb-3">
                                                    <CardTitle className="flex-space-between">Radiculopathy Motor Chart</CardTitle>
                                                    <CardBody>
                                                        <form ref={formRefRadiculopathyChart} onSubmit={handleSubmitRadiculopathyChart}>
                                                            <div class="table-responsive">
                                                                <Table className="mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            {columnsRadiculopathyChart.map(col => (
                                                                                <th key={col}>{col}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>{generateRows()}</tbody>
                                                                </Table>
                                                            </div>
                                                            <button type="submit" style={{ display: 'none' }} id="refRadiculopathyChartButton">Submiit</button>
                                                        </form>
                                                    </CardBody>
                                                </Card>

                                                <Card className="main-card mb-3">
                                                    <CardTitle className="flex-space-between">Radiculopathy Sensory Chart</CardTitle>
                                                    <CardBody>
                                                        <form ref={formRefRadiculopathySensoryChart} onSubmit={handleSubmitRadiculopathySensoryChart}>
                                                            <div class="table-responsive">
                                                                <Table className="mb-0">
                                                                    <thead>
                                                                        <tr>
                                                                            {columnsRadiculopathySensoryChart.map(col => (
                                                                                <th key={col}>{col}</th>
                                                                            ))}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>{generateRowsSensory()}</tbody>
                                                                </Table>
                                                            </div>
                                                            <button type="submit" style={{ display: 'none' }} id="refRadiculopathySensoryChartButton">Submiit</button>
                                                        </form>
                                                    </CardBody>
                                                </Card>
                                            </>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <h3 style={{ color: 'red' }}>No data found in Radiculopathy Sensory & Radiculopathy Motor </h3>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {activeStep == 3 && (
                                    <div>
                                        <Row>
                                            <Col md="6" >
                                                <FormGroup check style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                    <legend className="col-form-label">1. Facial Atonia</legend>
                                                    <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                        <Label check>
                                                            <Input name={"facial_atonia"} defaultChecked={finalObject['physical_findings']?.facial_atonia == 'None' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_atonia', 'None') }} />
                                                            {" "}None
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"facial_atonia"} defaultChecked={finalObject['physical_findings']?.facial_atonia == 'Right' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_atonia', 'Right') }} />
                                                            {" "}Right
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"facial_atonia"} defaultChecked={finalObject['physical_findings']?.facial_atonia == 'Left' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_atonia', 'Left') }} />
                                                            {" "}Left
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"facial_atonia"} defaultChecked={finalObject['physical_findings']?.facial_atonia == 'Both' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_atonia', 'Both') }} />
                                                            {" "}Both
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" >
                                                <FormGroup check style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                    <legend className="col-form-label">2. Facial Tics</legend>
                                                    <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                        <Label check>
                                                            <Input name={"facial_tics"} defaultChecked={finalObject['physical_findings']?.facial_tics == 'None' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_tics', 'None') }} />
                                                            {" "}None
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"facial_tics"} defaultChecked={finalObject['physical_findings']?.facial_tics == 'Right' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_tics', 'Right') }} />
                                                            {" "}Right
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"facial_tics"} defaultChecked={finalObject['physical_findings']?.facial_tics == 'Left' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_tics', 'Left') }} />
                                                            {" "}Left
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"facial_tics"} defaultChecked={finalObject['physical_findings']?.facial_tics == 'Both' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'facial_tics', 'Both') }} />
                                                            {" "}Both
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" >
                                                <FormGroup check style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                    <legend className="col-form-label">3. Hand Tremors (Intentional)</legend>
                                                    <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                        <Label check>
                                                            <Input name={"hand_tremors_intentional"} defaultChecked={finalObject['physical_findings']?.hand_tremors_intentional == 'None' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_intentional', 'None') }} />
                                                            {" "}None
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_intentional"} defaultChecked={finalObject['physical_findings']?.hand_tremors_intentional == 'Right' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_intentional', 'Right') }} />
                                                            {" "}Right
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_intentional"} defaultChecked={finalObject['physical_findings']?.hand_tremors_intentional == 'Left' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_intentional', 'Left') }} />
                                                            {" "}Left
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_intentional"} defaultChecked={finalObject['physical_findings']?.hand_tremors_intentional == 'Both' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_intentional', 'Both') }} />
                                                            {" "}Both
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" >
                                                <FormGroup check style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                    <legend className="col-form-label">4. Hand Tremors (Constant)</legend>
                                                    <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                        <Label check>
                                                            <Input name={"hand_tremors_constant"} defaultChecked={finalObject['physical_findings']?.hand_tremors_constant == 'None' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_constant', 'None') }} />
                                                            {" "}None
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_constant"} defaultChecked={finalObject['physical_findings']?.hand_tremors_constant == 'Right' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_constant', 'Right') }} />
                                                            {" "}Right
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_constant"} defaultChecked={finalObject['physical_findings']?.hand_tremors_constant == 'Left' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_constant', 'Left') }} />
                                                            {" "}Left
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_constant"} defaultChecked={finalObject['physical_findings']?.hand_tremors_constant == 'Both' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_constant', 'Both') }} />
                                                            {" "}Both
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" >
                                                <FormGroup check style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                    <legend className="col-form-label">5. Hand Tremors (Strong Grip)</legend>
                                                    <div style={{ marginLeft: '30px', display: 'flex', flexDirection: 'column' }}>
                                                        <Label check>
                                                            <Input name={"hand_tremors_strong_grip"} defaultChecked={finalObject['physical_findings']?.hand_tremors_strong_grip == 'None' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_strong_grip', 'None') }} />
                                                            {" "}None
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_strong_grip"} defaultChecked={finalObject['physical_findings']?.hand_tremors_strong_grip == 'Right' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_strong_grip', 'Right') }} />
                                                            {" "}Right
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_strong_grip"} defaultChecked={finalObject['physical_findings']?.hand_tremors_strong_grip == 'Left' ? true : false} type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_strong_grip', 'Left') }} />
                                                            {" "}Left
                                                        </Label>
                                                        <Label check>
                                                            <Input name={"hand_tremors_strong_grip"} defaultChecked={finalObject['physical_findings']?.hand_tremors_strong_grip == 'Both' ? true : false} v type={"radio"} onChange={(e) => { handleClickPhysicalFindings(e, 'hand_tremors_strong_grip', 'Both') }} />
                                                            {" "}Both
                                                        </Label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" >
                                                <FormGroup>
                                                    <Label for="cus_created_at">Custom Created At</Label>
                                                    <Input type="date" name="cus_created_at" id="cus_created_at" defaultValue={customCreatedAt} onChange={(e) => setCustomCreatedAt(e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                {activeStep == 4 && (
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <p>Patient Name:{' '}{dPEDDetailData?.patient_info?.name}</p>
                                                <p>MRN (Medical Registered Number):{' '}{dPEDDetailData?.patient_id}</p>
                                                <p>Visit Number:{' '}{dPEDDetailData?.visit_number}</p>
                                            </div>
                                            <div>
                                                <p>Date:{' '}{dPEDDetailData?.created_at ? dateTimeFormate(dPEDDetailData?.created_at) : dateTimeFormate(dPEDDetailData?.created_at)}</p>
                                                <p>Consultant Name:{' '}{dPEDDetailData?.user_created?.name}</p>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md="6" >
                                                <hr className="my-2" />
                                                <h5>Listing of Categories with the selected areas</h5>
                                                <hr className="my-2" />
                                                {console.log(selectedCoords, 'selectedCoordsselectedCoords')}
                                                {selectedCoords && Object.keys(selectedCoords).map((item, index) => {
                                                    if (item != 'physical_findings' && item != 'id') {
                                                        return (
                                                            <div key={index}>
                                                                <p><span style={{ backgroundColor: getCategoryColor(item), padding: '5px', borderRadius: '5px' }}>{getCategoryName(item)}:</span>{' '}{selectedCoords.hasOwnProperty(item) ? selectedCoords[item] ? getNameOfPoints(selectedCoords[item]) : '' : ''} </p>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                            </Col>
                                            <Col md="6">
                                                <hr className="my-2" />
                                                <h5>Associated Physical Findings:</h5>
                                                <hr className="my-2" />
                                                <div>
                                                    <p><span style={{ backgroundColor: '#47aaa8', padding: '5px', borderRadius: '5px' }}>Facial Atonia:</span>{' '}{finalObject['physical_findings']?.facial_atonia}</p>
                                                    <p><span style={{ backgroundColor: '#47aaa8', padding: '5px', borderRadius: '5px' }}>Facial Tics:</span>{' '}{finalObject['physical_findings']?.facial_tics}</p>
                                                    <p><span style={{ backgroundColor: '#47aaa8', padding: '5px', borderRadius: '5px' }}>Hand Tremors (Intentional):</span> {' '}{finalObject['physical_findings']?.hand_tremors_intentional}</p>
                                                    <p><span style={{ backgroundColor: '#47aaa8', padding: '5px', borderRadius: '5px' }}>Hand Tremors (Constant):</span>{' '}{finalObject['physical_findings']?.hand_tremors_constant}</p>
                                                    <p><span style={{ backgroundColor: '#47aaa8', padding: '5px', borderRadius: '5px' }}>Hand Tremors (Strong grip):</span> {' '}{finalObject['physical_findings']?.hand_tremors_strong_grip}</p>


                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                                <hr className="my-5" />
                                <div className="row align-items-center">
                                    <Col xs="auto">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                handleBackButtonClick();
                                            }}
                                        >
                                            {activeStep == 1 ? "Cancel" : "Back"}
                                        </button>
                                    </Col>
                                    <Col className="text-center">
                                    </Col>
                                    <Col xs="auto">
                                        <button className="btn btn-primary" onClick={(e) => handleNextButtonClick()}>
                                            {activeStep == totalStep ? "Finish" : "Next"}
                                        </button>
                                    </Col>
                                </div>
                            </Row>

                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DpepAdd;
