import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { loginAPI, getUserDetailAPI } from './../../api/network/CommonAPI';
import axios, { CancelTokenSource } from "axios";
import { useHistory } from "react-router-dom";
import Loader from "../../api/helperComponents/Loader";
import userUtil from "../../utils/UserUtils";
import { toast, Bounce } from 'react-toastify';
import { Link } from 'react-router-dom';
function LoginScreen() {
    const cancelTokenSource = axios.CancelToken.source();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoader, setIsLoader] = useState("");
    const [helperTextLoader, setHelperTextLoader] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleLogin = async () => {
        setHelperTextLoader('Logging In')
        setIsLoader(true)
        let dataToSend = {
            email: email,
            password: password
        }
        const response = await loginAPI(dataToSend);
        if (response?.success) {
            // history.push()
            getUserDetail()
        } else {
            toast("Invalid Credentials", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            setIsLoader(false)
        }
    }
    const getUserDetail = async () => {
        setHelperTextLoader('Fetching User Detail')
        const response = await getUserDetailAPI(cancelTokenSource.token);
        setIsLoader(false)
        if (response.success) {
            localStorage.setItem('userDetail', JSON.stringify(response?.data))
            localStorage.setItem('userPermissions', JSON.stringify(response?.data?.permissions))
            userUtil.setUserData(response?.data)
            userUtil.setUserDataPermission(response?.data?.permissions)
            history.push('/dashboard')
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    return (
        <div className="login-parent-container">
            {isLoader && (<Loader helperText={helperTextLoader} />)}
            <h3 style={{ color: '#0681ee' }}>OrthoSuite</h3>
            <div className="login-container">
                <h2 className="mb-4 text-center">Login</h2>

                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Email</label>
                    <input type="text" className="form-control" id="username" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <div style={{ position: 'relative' }}>
                        <input  type={showPassword ? "text" : "password"} className="form-control" id="password" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} />
                        <div
                            onClick={togglePasswordVisibility}
                            style={{ position: 'absolute', right: '5px', top: '8px' }}
                        >
                            {showPassword ?
                                <i
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                    className="fa fa-eye"
                                /> : <i
                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                    className="fa fa-eye-slash"
                                ></i>}

                        </div>
                    </div>
                    <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                        <Link to='/forgot-password'>Forgot Password?</Link>
                    </div>
                </div>
                <button onClick={() => handleLogin()} className="btn btn-primary w-100">{isLoader ? <i className='fa fa-spinner'></i> : 'Login'}</button>

            </div>
        </div>
    );
}


export default LoginScreen;
