import React, { useState, useEffect } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Table,
    Tooltip,
    Button
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getTripleLineListAPI } from './../../../api/network/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import Pagination from '../../../api/helperComponents/Pagination';
import { useHistory, useParams } from 'react-router-dom';
import userUtil from '../../../utils/UserUtils';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import { dateTimeFormate } from './../../../api/helperComponents/dateTimeFormat';
import { isToday } from '../../../utils/isToday';
const TripleLine = (props) => {
    const history = useHistory()
    const cancelTokenSource = axios.CancelToken.source();
    const params = useParams()
    const [diagnosisList, setDiagnosisList] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('userDetail'))?.role?.name);

    useEffect(() => {
        if (centerId) {
            getTripleLineList(centerId, page, rowsPerPage)
        }
    }, [centerId]);
    useEffect(() => {
        getTripleLineList(centerId, page, rowsPerPage)
    }, [page]);
    const getTripleLineList = async (centerId, page, rowsPerPage) => {
        setIsLoader(true)
        const response = await getTripleLineListAPI(centerId, params?.patient_id, page, rowsPerPage, cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setDiagnosisList(response?.data?.rows)
            setCount(response?.data?.count)

        } else {
            toast(response?.system_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
        }
    }
    const handlePageChange = (newPage) => {
        setPage(newPage - 1);

    };
    const handleAddTipleLineClick = () => {
        history.push(`/patient/triple-line/add/${params?.patient_id}`)
    }
    const handleClickEdit = (e, id) => {
        history.push(`/patient/triple-line/edit/${params?.patient_id}/${id}`)
    }

    return (
        <div>
            {isLoader && (<Loader />)}
            {/* <TransitionGroup>
                <CSSTransition
                    component="div"
                    className="TabsAnimation"
                    appear={true}
                    timeout={0}
                    enter={false}
                    exit={false}>
                    <div>
                        <PageTitle
                            heading="Center List"
                            subheading="This is an example dashboard created using build-in elements and components."
                            icon="pe-7s-car icon-gradient bg-mean-fruit"
                        />
                    </div>
                </CSSTransition>
            </TransitionGroup> */}
            <BreadCrumb items={[
                { label: "Dashboard", url: "/" },
                { label: "Patients", url: "/patients" },
                { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                { label: "Triple Line" }
            ]} />
            <Card className="main-card mb-3">
                <CardBody>
                    <CardTitle className="flex-space-between">Triple Line List
                        {(permissions.includes("PATIENT_TRIPLE_SIGN_CREATE") || role == "SUPER_ADMIN") && (
                            <button className="btn btn-primary" onClick={() => handleAddTipleLineClick()}>
                                Add Triple Line
                            </button>
                        )}
                    </CardTitle>
                    <div class="table-responsive">
                        <Table striped className="mb-0">
                            <thead>
                                <tr>
                                    <th colSpan={5} style={{ border: '1px solid silver' }}>Sclerosis - Lateral View</th>
                                    <th colSpan={5} style={{ border: '1px solid silver' }}>TLS - Lateral View</th>
                                    <th colSpan={5} style={{ border: '1px solid silver' }}>TLS - Extension View</th>
                                    <th colSpan={5} style={{ border: '1px solid silver' }}>TLS - Flexion View</th>
                                    <th rowSpan={2} style={{ border: '1px solid silver' }}>Created At</th>
                                    {(permissions.includes("PATIENT_TRIPLE_SIGN_UPDATE") || role == "SUPER_ADMIN") && (
                                        <th rowSpan={2} style={{ border: '1px solid silver' }}>Action</th>
                                    )}

                                </tr>
                                <tr>
                                    <th>C2-C3</th>
                                    <th>C3-C4</th>
                                    <th>C4-C5</th>
                                    <th>C5-C6</th>
                                    <th>C6-C7</th>

                                    <th>C2-C3</th>
                                    <th>C3-C4</th>
                                    <th>C4-C5</th>
                                    <th>C5-C6</th>
                                    <th>C6-C7</th>

                                    <th>C2-C3</th>
                                    <th>C3-C4</th>
                                    <th>C4-C5</th>
                                    <th>C5-C6</th>
                                    <th>C6-C7</th>

                                    <th>C2-C3</th>
                                    <th>C3-C4</th>
                                    <th>C4-C5</th>
                                    <th>C5-C6</th>
                                    <th>C6-C7</th>

                                </tr>
                            </thead>
                            <tbody>
                                {diagnosisList && diagnosisList.map((item, index) => {
                                    // console.log(getNameofSpinal(item?.spinal),'getNameofSpinal(item?.spinal)')
                                    // console.log(getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', '),'getNameofSpinal(item?.spinal)dfd')
                                    // let spinalName = getNameofSpinal(item?.spinal).map(item => Object.values(item)[0].name).join(', ')
                                    return (
                                        <tr key={index}>
                                            <td>{item?.c2_c3_sclerosis_lateral_view}</td>
                                            <td>{item?.c3_c4_sclerosis_lateral_view}</td>
                                            <td>{item?.c4_c5_sclerosis_lateral_view}</td>
                                            <td>{item?.c5_c6_sclerosis_lateral_view}</td>
                                            <td>{item?.c6_c7_sclerosis_lateral_view}</td>

                                            <td>{item?.c2_c3_triple_line_sign_lateral_view}</td>
                                            <td>{item?.c3_c4_triple_line_sign_lateral_view}</td>
                                            <td>{item?.c4_c5_triple_line_sign_lateral_view}</td>
                                            <td>{item?.c5_c6_triple_line_sign_lateral_view}</td>
                                            <td>{item?.c6_c7_triple_line_sign_lateral_view}</td>

                                            <td>{item?.c2_c3_triple_line_sign_extension_view}</td>
                                            <td>{item?.c3_c4_triple_line_sign_extension_view}</td>
                                            <td>{item?.c4_c5_triple_line_sign_extension_view}</td>
                                            <td>{item?.c5_c6_triple_line_sign_extension_view}</td>
                                            <td>{item?.c6_c7_triple_line_sign_extension_view}</td>

                                            <td>{item?.c2_c3_triple_line_sign_flexion_view}</td>
                                            <td>{item?.c3_c4_triple_line_sign_flexion_view}</td>
                                            <td>{item?.c4_c5_triple_line_sign_flexion_view}</td>
                                            <td>{item?.c5_c6_triple_line_sign_flexion_view}</td>
                                            <td>{item?.c6_c7_triple_line_sign_flexion_view}</td>

                                            <td>{dateTimeFormate(item?.created_at)}</td>
                                            {((permissions.includes("PATIENT_CSREF_FORM_6_UPDATE") || role == "SUPER_ADMIN") && isToday(item?.created_at)) && (
                                                <td>
                                                    <i style={{ cursor: 'pointer' }} className="fa fa-edit" onClick={(e) => { handleClickEdit(e, item.id) }}></i>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </Table>
                    </div>
                    {count > 10 && (
                        <Pagination
                            currentPage={page + 1}
                            totalPages={Math.ceil(count / rowsPerPage)}
                            onPageChange={handlePageChange}
                        />
                    )}
                </CardBody>

            </Card>
        </div>
    );
};

export default TripleLine;
