import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { forgotPasswordAPI } from './../../api/network/CommonAPI';
import axios, { CancelTokenSource } from "axios";
import { useHistory } from "react-router-dom";
import Loader from "../../api/helperComponents/Loader";
import userUtil from "../../utils/UserUtils";
import { toast, Bounce } from 'react-toastify';
import { Link } from 'react-router-dom';
function LoginScreen() {
    const cancelTokenSource = axios.CancelToken.source();
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [isLoader, setIsLoader] = useState("");
    const [helperTextLoader, setHelperTextLoader] = useState("");
    const handleForgotPassword = async () => {
        setIsLoader(true)
        let dataToSend = {
            email: email,
        }
        const response = await forgotPasswordAPI(dataToSend);
        if (response?.success) {
            setIsLoader(false)
            toast('Reset Email sent', {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'success'
            });
        } else {
            toast(response?.user_message, {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            setIsLoader(false)
        }
    }
    
    return (
        <div className="login-parent-container">
            {isLoader && (<Loader helperText={helperTextLoader} />)}
            <h3 style={{ color: '#0681ee' }}>OrthoSuite</h3>
            <div className="login-container">
                <h2 className="mb-4 text-center">Forgot Password</h2>

                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Email</label>
                    <input type="text" className="form-control" id="username" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div style={{display: 'flex',justifyContent: 'flex-end',marginBottom:'10px'}}>
                        <Link to='/login'>Login</Link>
                    </div>
                <button onClick={() => handleForgotPassword()} className="btn btn-primary w-100">{isLoader ? <i className='fa fa-spinner'></i> : 'Submit'}</button>

            </div>
        </div>
    );
}


export default LoginScreen;
